import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';

import InputField from '../components/UI/InputField';

import useStore from '../store/UserStore';

const Login = (props) => {
  const setUser = useStore((state) => state.setUser);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [responseMessage, setResponseMessage] = useState('');
  const history = useHistory();

  const onSubmit = async (data) => {
    setResponseMessage('');

    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/users/login', {
        username: data.email,
        password: data.password,
      });

      if (response.status === 200) {
        const refreshToken = response.headers['refreshtoken'];
        localStorage.setItem('refreshToken', refreshToken);
        setUser(response.data.token);
        history.push('/start');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setResponseMessage('Please fill all the fields correctly!');
        } else if (error.response.status === 401) {
          setResponseMessage('Invalid credentials');
        } else {
          setResponseMessage('Something went wrong!');
        }
      } else if (error.request) {
        setResponseMessage('No response from server');
      } else {
        setResponseMessage('An error occurred while trying to log in.');
      }
    }
  };

  return (
    <div className='flex items-center justify-center h-screen bg-bgColor'>
      <form
        className='flex flex-col p-8 bg-white rounded shadow-md w-80 relative'
        onSubmit={handleSubmit(onSubmit)}
      >
        <legend className='absolute left-0 py-1 px-2 font-bold text-white rounded-t-lg bg-secondary -top-8'>
          Login
        </legend>

        <InputField
          register={register}
          errors={errors}
          label='Email'
          id='email'
          type='email'
          placeholder='Email'
          validation={{ required: 'Email Address is required' }}
        />

        <InputField
          register={register}
          errors={errors}
          label='Password'
          id='password'
          type='password'
          placeholder='******************'
          validation={{ required: 'Password is required' }}
        />

        <div className='mb-4'>
          {responseMessage && <p className={'text-red-900 text-sm font-bold'}>{responseMessage}</p>}
        </div>

        <div className='flex items-center justify-between'>
          <button
            className='w-full px-4 py-2 font-bold transition-all bg-white border-2 rounded hover:bg-secondary hover:text-white text-secondary border-secondary'
            type='submit'
          >
            Sign In
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
