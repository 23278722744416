import React, { useEffect } from 'react';

import Start from '../components/Start';
import ContactForm from '../components/start/ContactForm';

import LogoBg from '../assets/logo-bg.png';

import Header from '../components/start/Header';
import FlowHeader from '../components/flow/FlowHeader';
import Flow from '../components/flow/Flow';

// context
import { useAppContext, useFlowContext } from '../contexts';

const FlowtomatorApp = (props) => {
  const { contactFormOpen, setContactFormOpen } = useAppContext();
  const { setFlowId } = useFlowContext();

  useEffect(() => {
    setFlowId(props.match.params.id);
  }, [props]);

  return (
    <>
      <div className='relative h-screen overflow-hidden'>
        {/* Gradient Background */}
        <div className='absolute inset-0 bg-linear-gradient'></div>

        {/* Image Overlay */}
        <img src={LogoBg} className='absolute inset-0 w-full h-full object-cover' alt='bg' />

        {/* Header */}
        <div className='relative z-30 '>
          <Header />
        </div>

        {/* Content */}
        <div className='relative z-10 w-9/12 mx-auto mt-4 bg-white p-2 rounded-md shadow-xl'>
          <FlowHeader />
        </div>

        <Flow />

        {contactFormOpen && <ContactForm setContactFormOpen={setContactFormOpen} />}
      </div>
    </>
  );
};

export default FlowtomatorApp;
