import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Tooltip } from 'react-tooltip';

const AddCustomFieldForm = ({ addCustomField, setNewFieldActive, element }) => {
  const uniqueId = uuidv4();

  const [fieldName, setFieldName] = useState('');
  const [fieldSize, setFieldSize] = useState(1);
  const [fieldType, setFieldType] = useState('text');

  const handleSubmit = (e) => {
    e.preventDefault();

    addCustomField(fieldName, fieldType, fieldType === 'text' ? +fieldSize : 2, element);
    setFieldSize(1);
    setFieldName('');
    setFieldType('text');
    setNewFieldActive((prev) => !prev);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='flex items-center mt-2'>
        <div className='flex flex-col mr-2'>
          <label htmlFor='name' className='font-bold text-gray-800'>
            Name
          </label>
          <input
            required
            value={fieldName}
            onChange={(e) => setFieldName(e.target.value)}
            id='name'
            name='name'
            type='text'
            className={`p-2 my-2 border-2 border-gray-200 outline-none hover:shadow-lg focus:border-bgColor`}
            placeholder='Name for Custom Field'
          />
        </div>

        <div className='flex flex-col mr-2'>
          <label htmlFor='size' className='font-bold text-gray-800'>
            Type
          </label>
          <select
            name='type'
            id='type'
            className={`p-2 my-2 border-2 border-gray-200 outline-none hover:shadow-lg focus:border-bgColor`}
            placeholder='text, checkbox or dropdown'
            value={fieldType}
            onChange={(e) => setFieldType(e.target.value)}
          >
            <option value={'text'}>Text</option>
            <option value={'checkbox'}>Checkbox</option>
            <option value={'select'}>Dropdown</option>
          </select>
        </div>

        {fieldType === 'text' && (
          <div className='flex flex-col'>
            <label htmlFor='size' className='font-bold text-gray-800'>
              Size
            </label>
            <select
              name='size'
              id='size'
              className={`p-2 my-2 border-2 border-gray-200 outline-none hover:shadow-lg focus:border-bgColor`}
              placeholder='1, 2 or 3'
              value={fieldSize}
              onChange={(e) => setFieldSize(e.target.value)}
            >
              <option value={1}>Small</option>
              <option value={2}>Medium</option>
              <option value={3}>Textarea</option>
            </select>
          </div>
        )}

        <>
          <button
            type='submit'
            className='w-10 h-10 p-2 mt-8 mb-2 ml-4 font-bold text-white bg-gray-800 rounded-full'
            data-tooltip-id={uniqueId}
            data-tooltip-content='Add Field'
          >
            +
          </button>
          <Tooltip id={uniqueId} />
        </>
      </div>
    </form>
  );
};

export default AddCustomFieldForm;
