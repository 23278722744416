import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import fireDb from '../../firebase';
import { BsTrash } from 'react-icons/bs';
import { Modal } from '../Modal';

// context
import { useFlowContext, useActionContext } from '../../contexts';

const MultiSelectMenu = ({ expanded, setExpanded }) => {
  const { userWorkspace, flow, setFlow, flowId } = useFlowContext();
  const { setActiveActionId, selectedActions, setSelectedActions } = useActionContext();

  const [deleteCheckVisible, setDeleteCheckVisible] = useState(false);

  // Selected Actions without combos
  const filteredSelectedActions = selectedActions.filter((id) => flow.byId[id]?.type !== 'combo');

  const multiSelectMenuRef = useRef(null);

  useEffect(() => {
    positionMultiSelectMenu();

    window.addEventListener('resize', positionMultiSelectMenu);

    return () => {
      window.removeEventListener('resize', positionMultiSelectMenu);
    };
  });

  const positionMultiSelectMenu = () => {
    // const checkedActions = document.querySelectorAll('.action-selected');
    // const multiSelectMenuRect = multiSelectMenuRef.current.getBoundingClientRect();
    // const firstActionRect = checkedActions[0].getBoundingClientRect();
    // const lastActionRect = checkedActions[checkedActions.length - 1].getBoundingClientRect();
    // const topDistanceForMultiSelectMenu =
    //   (lastActionRect.y - firstActionRect.y) / 2 - multiSelectMenuRect.height / 2;
    // multiSelectMenuRef.current.style.top = `${firstActionRect.y + topDistanceForMultiSelectMenu}px`;
    // multiSelectMenuRef.current.style.left = `${
    //   firstActionRect.x - multiSelectMenuRect.width - 32
    // }px`;
  };

  const makeCombo = (e) => {
    e.preventDefault();
    const newId = uuidv4();
    const updatedFlow = { ...flow };
    const updatedRenderOrder = flow.byOrder.filter((id) => !filteredSelectedActions.includes(id));
    updatedFlow.byOrder = updatedRenderOrder;

    updatedFlow.byId[newId] = {
      name: 'New Combo',
      type: 'combo',
      duration: 0,
      id: newId,
      children: [...filteredSelectedActions],
    };

    updatedFlow.byOrder = [
      ...updatedRenderOrder.slice(
        0,
        flow.byOrder.indexOf(selectedActions[selectedActions.length - 1]) -
          selectedActions.length +
          1,
      ),
      newId,
      ...updatedRenderOrder.slice(
        flow.byOrder.indexOf(selectedActions[selectedActions.length - 1]) -
          selectedActions.length +
          1,
      ),
    ];

    setFlow(updatedFlow);
    setSelectedActions([]);
    setActiveActionId(newId);
    if (flowId) {
      let updates = {};
      updates[userWorkspace + '/flows/' + flowId] = updatedFlow;
      fireDb.update(updates);
    }
    // setExpanded([...expanded, newId]);
  };

  const handleDeleteSelectedItems = (e) => {
    const updatedFlow = { ...flow };
    updatedFlow.byOrder = flow.byOrder.filter((actionId) => !selectedActions.includes(actionId));
    selectedActions.forEach((actionId) => {
      if (flow.byId[actionId].type === 'combo') {
        flow.byId[actionId]?.children?.forEach((actionId) => {
          delete updatedFlow.byId[actionId];
        });
      }
      delete updatedFlow.byId[actionId];
    });
    setFlow(updatedFlow);
    setSelectedActions([]);
    if (flowId) {
      let updates = {};
      updates[userWorkspace + '/flows/' + flowId] = updatedFlow;
      fireDb.update(updates);
    }
  };

  return (
    <div
      ref={multiSelectMenuRef}
      id='multi-select-menu'
      className='absolute top-1/2 left-10 flex flex-col rounded-lg'
    >
      {filteredSelectedActions.length >= 2 && (
        <button className='px-4 py-2 font-bold bg-combo rounded-t-lg' onClick={makeCombo}>
          Make Combo
        </button>
      )}
      <div className='p-2 text-sm text-center text-black bg-white'>{`${selectedActions.length} items selected`}</div>
      <button
        className='w-full px-4 py-2 mx-auto font-bold bg-plum text-white rounded-b-lg hover:bg-plum hover:opacity-80'
        onClick={(e) => setDeleteCheckVisible((prev) => !prev)}
      >
        <div className='flex'>
          <BsTrash color='white' size={24} className='mr-2' />
          <span className='white'>Delete</span>
        </div>
      </button>
      {deleteCheckVisible && (
        <Modal>
          <div className='fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50'>
            <div className='flex flex-col h-auto p-6 bg-white rounded-lg shadow-md w-96'>
              <h1 className='font-bold text-center'>
                Do you want to delete {selectedActions.length} items?
              </h1>
              <div className='my-5 text-center'>
                <button
                  className='p-2 mr-4 font-extrabold border-2 rounded-lg w-14 hover:bg-bgColor hover:text-white text-bgColor border-bgColor'
                  onClick={handleDeleteSelectedItems}
                >
                  Yes
                </button>
                <button
                  className='p-2 font-extrabold border-2 border-white rounded-lg w-14 hover:border-2 hover:border-black'
                  onClick={(e) => setDeleteCheckVisible((prev) => !prev)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MultiSelectMenu;
