import React, { useState } from 'react';

const AreaForm = ({ onSubmit }) => {
  const [newArea, setNewArea] = useState('');

  const handleAddArea = (e) => {
    e.preventDefault();
    if (!newArea) return;
    onSubmit(newArea);
    setNewArea('');
  };

  return (
    <form className='flex justify-between p-2'>
      <input
        type='text'
        value={newArea}
        onChange={(e) => setNewArea(e.target.value)}
        placeholder='Add new area'
        className='border rounded px-2 py-1 flex-grow'
      />
      <button
        onClick={handleAddArea}
        className='border rounded px-2 py-1 bg-plum hover:bg-opacity-70 text-white cursor-pointer ml-2'
      >
        Add
      </button>
    </form>
  );
};

export default AreaForm;
