const handleDragEnd = (dropResult, flow, setFlow, selectedActions) => {
  if (dropResult.destination === null) {
    console.log('Drop destination is null.')
    return;
  }

  const { draggableId, source, destination } = dropResult;
  const draggedItemType = draggableId.split('-')[0];
  const draggedItemId = draggableId.split(`${draggedItemType}-`)[1];

  // Expand Combo if it was already expanded when drag started (it folds onMouseDown but doesn't expand onMouseUp)
  if (source.droppableId.includes('combo')) {
    const comboItemsEl = document.getElementById(source.droppableId)?.querySelector('.combo-items');
    if (comboItemsEl?.classList.contains('combo-expanded')) {
      comboItemsEl.classList.remove('hidden');
    }
  }

  if (selectedActions.length > 1) {
    // More than one action is being dragged

  } else {
    // If action is drag and dropped within the SAME droppable
    if (source.droppableId === destination.droppableId) {
        
      if (source.droppableId.includes('combo')) {
        // Source and destination are the same combo, update the children order of this combo
        const sourceComboId = source.droppableId.split('combo-')[1];
        const children = flow.byId[sourceComboId].children;
        children.splice(destination.index, 0, children.splice(source.index, 1)[0])

        setFlow({
          ...flow,
          byId: {
            ...flow.byId,
            [sourceComboId]: {
              ...flow.byId[sourceComboId],
              children: children,
            }
          }
        })

      } else if (source.droppableId.includes('track')) {
        // Source and destination are the same track, update the children order of this track

      } else { 
        // Update the flow.byOrder
        const byOrder = flow.byOrder;
        byOrder.splice(destination.index, 0, byOrder.splice(source.index, 1)[0]);

        setFlow({
          ...flow,
          byOrder: byOrder,
        })
      }

    // Else drag and dropped between DIFFERENT droppables
    } else {
      console.log('Different droppable')

      if (
        source.droppableId.includes('flow') && 
        destination.droppableId.includes('combo')
      ) { 
        // Handle drag from flow to combo
        console.log('Drag form flow to combo')
        
        const action = flow.byId[draggedItemId];

        // Remove from flow.byOrder
        const byOrder = flow.byOrder;
        byOrder.splice(source.index, 1);

        const comboId = destination.droppableId.split('combo-')[1];
        const children = flow.byId[comboId].children || [];
        children.splice(destination.index, 0, action.id); // Add to the children

        
        setFlow({
            ...flow,
            byId: {
              ...flow.byId,
              [comboId]: {
                ...flow.byId[comboId],
                children: children,
              }
            },
            byOrder: byOrder,
        })

      } else if (
        source.droppableId.includes('combo') && 
        (destination.droppableId.includes('flow') || destination.droppableId.includes('empty'))
      ) { 
        // Handle drag from combo to flow
        console.log('Drag from combo to flow', source, destination)

        // If dragged item type is combo
        if (draggedItemType === 'combo') {
          const byOrder = flow.byOrder;
          byOrder.splice(destination.index, 0, byOrder.splice(source.index, 1)[0]);
          
          setFlow({
            ...flow,
            byOrder: byOrder,
          })

          return;
        }

        if (destination.droppableId.includes('empty')) {
          const byOrder = flow.byOrder;

          const sourceComboId = source.droppableId.split('combo-')[1];
          const sourceCombo = flow.byId[sourceComboId];
          // Remove it from the children of the source combo
          const actionId = sourceCombo.children.splice(source.index, 1)[0];

          const destinationIndex = destination.droppableId.split('empty-')[1];
          byOrder.splice(destinationIndex, 0, actionId);
          
          setFlow({
            ...flow,
            byOrder: byOrder,
          })

          return;
        }

        const sourceComboId = source.droppableId.split('combo-')[1];
        const sourceCombo = flow.byId[sourceComboId];
        // Remove it from the children of the source combo
        const actionId = sourceCombo.children.splice(source.index, 1)[0];

        const sourceDroppableIndex = source.droppableId.split('-')[1];
        const destinationDroppableIndex = destination.droppableId.split('-')[1];
        const byOrder = flow.byOrder;

        if (sourceDroppableIndex < destinationDroppableIndex) {
          const destinationIndex = destination.droppableId.split('inbetween-empty-flow-')[1];
          byOrder.splice(destinationIndex, 0, actionId)
        } else {
          const byOrder = flow.byOrder;
          byOrder.splice(destination.index, 0, actionId)
        }

        setFlow(prevFlow => {
          return {
            ...prevFlow,
            byId: {
              ...prevFlow.byId,
              [sourceComboId]: {
                ...prevFlow.byId[sourceComboId],
                children: sourceCombo.children,
              },
            },
            byOrder: byOrder
          }
        })

      } else if (
        source.droppableId.includes('combo') && 
        destination.droppableId.includes('combo')
      ) { 
        // Handle drag form combo to combo
        console.log('Drag form combo to combo', source, destination, draggableId)

        // Prevent dragging combo into combo as it causes error
        if (draggableId.includes('combo')) return;

        const sourceComboId = source.droppableId.split('combo-')[1];
        const sourceCombo = flow.byId[sourceComboId];
        // Remove it from the children of the source combo
        const actionId = sourceCombo.children.splice(source.index, 1)[0];

        const destinationComboId = destination.droppableId.split('combo-')[1];
        let children = flow.byId[destinationComboId].children;

        if (children) {
          children.splice(destination.index, 0, actionId);
        } else {     
          children = [actionId];     
        }

        setFlow({
          ...flow,
          byId: {
            ...flow.byId,
            [destinationComboId]: {
              ...flow.byId[destinationComboId],
              children: children,
            }
          }
        })


      } else if (
        source.droppableId.includes('breakoutSession') && 
        destination.droppableId.includes('combo')
      ) { 
        return;
      } else {
        console.log('Drag from flow to flow', source, destination)
        const byOrder = flow.byOrder;

        if (destination.droppableId.includes('empty')) {
          const destinationIndex = destination.droppableId.split('empty-')[1];
          byOrder.splice(destinationIndex, 0, byOrder.splice(source.index, 1)[0]);
        } else {
          let sourceDroppableIndex = source.droppableId.split('-')[1];
          const destinationDroppableIndex = destination.droppableId.split('-')[1];
          console.log(sourceDroppableIndex, destinationDroppableIndex)

          if (source.droppableId.split('-')[0] === 'breakoutSession') {
            sourceDroppableIndex = source.index;
          } 

          if (sourceDroppableIndex < destinationDroppableIndex) {
            byOrder.splice(destination.index - 1, 0, byOrder.splice(source.index, 1)[0]);
          } else {
            console.log('NORMAL')
            byOrder.splice(destination.index, 0, byOrder.splice(source.index, 1)[0]);
          }
        }
        
        setFlow({
          ...flow,
          byOrder: byOrder,
        })
      }
    }
  }
}

export default handleDragEnd;