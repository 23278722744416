import React, { useState } from 'react';
import { convertToSeconds } from '../../helper/helperFunctions';
import axios from 'axios';
import Notification from './Notification';

import { useFlowContext } from '../../contexts';

const AddToLibraryButton = ({ setNotification, notification }) => {
  const { userWorkspace, inputFieldText, inputFieldDuration } = useFlowContext();

  const [submitting, setSubmitting] = useState(false);

  const handleAddToLibrary = () => {
    setSubmitting(true);
    axios
      .post(process.env.REACT_APP_API_ENDPOINT + '/api/actions', {
        name: inputFieldText,
        workspace: userWorkspace,
        time: convertToSeconds(inputFieldDuration),
        type: 'action',
      })
      .then((res) => {
        if (res.data.err) {
          setNotification({ type: 'error', message: res.data.err.message });
        } else {
          setNotification({ type: 'success', message: 'Saved to library' });
        }
        setSubmitting(false);
        setTimeout(() => {
          setNotification('');
        }, 3000);
      });
  };

  return (
    <div>
      {notification && <Notification notification={notification} />}
      <button
        tabIndex={4}
        className='absolute right-7 bottom-[5rem] z-10 justify-center w-32 px-2 py-1 text-sm text-white rounded-full bg-secondary'
        onClick={handleAddToLibrary}
      >
        <b>{submitting ? 'Submitting...' : 'Add to Library'}</b>
      </button>
    </div>
  );
};

export default AddToLibraryButton;
