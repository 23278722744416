import React, { useContext, useState } from 'react';
import io from 'socket.io-client';

const AppContext = React.createContext();

const AppContextProvider = ({ children }) => {
  const [notesVisible, setNotesVisible] = useState(false);
  const [contactFormOpen, setContactFormOpen] = useState(false);
  const [playerVisible, setPlayerVisible] = useState(false);

  const [isAreaModalOpen, setIsAreaModalOpen] = useState(false);

  const socket = io(process.env.REACT_APP_API_ENDPOINT);

  return (
    <AppContext.Provider
      value={{
        notesVisible,
        setNotesVisible,

        contactFormOpen,
        setContactFormOpen,

        playerVisible,
        setPlayerVisible,

        isAreaModalOpen,
        setIsAreaModalOpen,

        socket
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};

export { AppContextProvider };
