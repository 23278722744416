import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import handleDragEnd from './handleDragAndDrop';
import { AnimatePresence } from 'framer-motion';

import { FaPlay } from 'react-icons/fa';

import Action from '../action/Action';
import ActionForm from '../actionForm/ActionForm';
import MultiSelectMenu from '../multiSelectMenu/MultiSelectMenu';
import Combo from '../combo/Combo';
import BreakoutSession from '../breakoutSession/BreakoutSession';
import Notes from '../start/Notes/Notes';
import Player from '../start/Player';
import AreaModal from '../area/AreaModal';

//context
import {
  useAppContext,
  useFlowContext,
  useActionContext,
  useTrackContext,
  useComboContext,
} from '../../contexts';
import DraggableItem from '../dnd/DraggableItem';

const Flow = () => {
  const {
    isAreaModalOpen,
    setIsAreaModalOpen,
    notesVisible,
    setNotesVisible,
    playerVisible,
    setPlayerVisible,
  } = useAppContext();
  const { flow, setFlow, flowId, userWorkspace, dragging, setDragging } = useFlowContext();
  const { selectedActions, activeActionId } = useActionContext();

  const [byIdDbPath, setByIdDbPath] = useState(null);

  useEffect(() => {
    setByIdDbPath(`${userWorkspace}/flows/${flowId}/byId/${activeActionId}`);
  }, [activeActionId, userWorkspace, flowId]);

  const containerRef = useRef(null);

  const getDroppableType = (droppable, index, totalDroppables, byOrderLastIndex) => {
    if (droppable[0]?.type === 'combo') {
      return `combo-${droppable[0].id}`;
    } else if (droppable[0]?.type === 'breakoutSession') {
      return `breakoutSession-${droppable[0].id}`;
    } else if (droppable.length === 0) {
      return `empty-${byOrderLastIndex}`;
    } else {
      return 'flow-' + index;
    }
  };

  const calculateDroppables = () => {
    const newDroppables = [];
    let flowActionsDroppable = [];

    flow?.byOrder?.forEach((actionId, index) => {
      let prevActionId;
      if (flow.byOrder[index - 1]) {
        prevActionId = flow.byOrder[index - 1];
      }

      if (
        (index === 0 && flow.byId[actionId]?.type === 'combo') ||
        (prevActionId &&
          flow.byId[prevActionId]?.type === 'combo' &&
          flow.byId[actionId]?.type === 'combo')
      ) {
        newDroppables.push([]);
      }

      if (
        flow.byId[actionId]?.type !== 'combo' &&
        flow.byId[actionId]?.type !== 'breakoutSession'
      ) {
        flowActionsDroppable.push(flow.byId[actionId]);
        if (index === flow.byOrder.length - 1) {
          newDroppables.push(flowActionsDroppable);
        }
      } else {
        if (flowActionsDroppable.length > 0) {
          newDroppables.push(flowActionsDroppable);
        }

        flowActionsDroppable = [];

        if (
          newDroppables.length > 0 &&
          newDroppables[newDroppables.length - 1]?.type !== 'action' &&
          index === flow.byOrder.length - 1
        ) {
          newDroppables.push([flow.byId[actionId]], []);
        } else {
          newDroppables.push([flow.byId[actionId]]);
        }
      }
    });

    return newDroppables;
  };

  const renderDroppables = useMemo(() => {
    return calculateDroppables();
  }, [flow]);

  useEffect(() => {
    if (selectedActions.length > 1) {
      setNotesVisible(false);
    }
  }, [selectedActions]);

  // Ref for the last item in the list
  const listRef = useRef(null);

  // Scroll to the last item when the component mounts or the list changes
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [flow.byOrder.length]);

  const handleDragStart = (start) => {
    setDragging(true);
  };

  const handleDragUpdate = (update) => {
    // If dragging a combo, allow transform on the element
    if (update.source && update.source.droppableId.includes('combo')) {
      const comboEl = document.getElementById(update.source.droppableId);
      comboEl?.classList.remove('!transform-none');
    }

    // If the destination is combo, set transform-none on the element
    if (update.destination && update.destination.droppableId.includes('combo')) {
      const comboEl = document.getElementById(update.destination.droppableId);
      comboEl?.classList.add('!transform-none');
    }
  };

  return (
    <>
      <div
        ref={containerRef}
        className={`flex gap-1 relative z-10 w-9/12 mx-auto mt-4 bg-white p-2 rounded-t-md h-full max-h-[calc(100%-386px)] shadow-xl`}
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      >
        <div className={`${notesVisible ? 'w-1/2' : 'w-full'} overflow-auto`}>
          <DragDropContext
            onDragStart={handleDragStart}
            onDragUpdate={handleDragUpdate}
            onDragEnd={(dropResult) => {
              setDragging(false);
              //   setTimeout(() => {
              //     console.log('SET DRAGGING TO FALSE')
              //     setDragging(false);
              //   }, 2000);
              handleDragEnd(dropResult, flow, setFlow, selectedActions);
            }}
          >
            {renderDroppables.map((droppable, index) => {
              let byOrderLastIndex = 0;
              if (droppable.length === 0 && index > 0 && index < renderDroppables.length) {
                const prevDroppable = renderDroppables[index - 1];
                const lastItemInPrevDroppable = prevDroppable[prevDroppable.length - 1];
                byOrderLastIndex = flow.byOrder.indexOf(lastItemInPrevDroppable.id) + 1;
              }
              // console.log('Droppable:', droppable)
              return (
                <Droppable
                  key={'flow-' + index}
                  droppableId={getDroppableType(
                    droppable,
                    index,
                    renderDroppables.length,
                    byOrderLastIndex,
                  )}
                >
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`relative bg-diagonal-plum-stripes`}
                      //   className={`bg-diagonal-stripes ${droppable.length === 0 && !dragging ? 'min-h-[50px]' : 'min-h-[50px]'}`}
                      //   style={true ? {
                      //     userSelect: 'none',
                      //     margin: '0 0 8px 0',
                      //     // minHeight: '73px',
                      //     paddingBottom: '5rem'
                      //   } : {}}
                      //   style={{
                      //     userSelect: 'none',
                      //     margin: '0 0 8px 0',
                      // minHeight: '74px',
                      // backgroundColor: '#399',
                      // color: '#333',
                      //   }}
                    >
                      {droppable.map((draggable, draggableIndex) => {
                        // console.log('THE DROPPABLE ID:', draggable);

                        const ComponentMap = {
                          combo: Combo,
                          breakoutSession: BreakoutSession,
                          action: Action,
                        };
                        const Component = ComponentMap[draggable?.type];

                        return (
                          <DraggableItem
                            key={draggable.id}
                            item={Component}
                            draggable={draggable}
                            index={flow.byOrder.indexOf(draggable.id)}
                            droppablePlaceholder={provided.placeholder}
                          />
                        );
                      })}
                      {(droppable[0]?.type === 'action' || droppable.length === 0) && (
                        // <div className={dragging ? 'min-h-[74px]' : '!hidden'}>
                        <div>
                          <h1></h1>
                          {/* {droppable.length === 0 && dragging &&
                            <div className={'flex flex-row items-center justify-center text-center text-white text-xs h-[74px] bg-diagonal-stripes'}>
                              <div className={'bg-white rounded text-plum p-2 text-sm font-bold'}>Drop {index === 0 ? 'above' : 'below'}</div>
                            </div>
                          } */}
                          {/* <div>Placeholder</div> */}
                          {provided.placeholder}
                        </div>
                      )}
                      <div ref={listRef} />
                    </div>
                  )}
                </Droppable>
              );
            })}
          </DragDropContext>
        </div>

        {/* NOTES CONTAINER */}
        {notesVisible && (
          <div className={`${notesVisible ? 'w-1/2' : ''} overflow-auto`}>
            <Notes byIdDbPath={byIdDbPath} />
          </div>
        )}
      </div>

      <div className='relative z-10 w-9/12 mx-auto bg-white px-2 py-0 rounded-b-md h-full max-h-[80px] shadow-xl'>
        <ActionForm />
      </div>

      {selectedActions.length > 1 && <MultiSelectMenu />}

      {flowId && !playerVisible && flow.byOrder.length > 0 && (
        <div
          className={
            'z-10 flex items-center justify-center w-16 h-16 bg-white rounded-full cursor-pointer hover:bg-gray-300 absolute right-4 bottom-4 text-bgColor'
          }
          onClick={(e) => setPlayerVisible((prev) => !prev)}
        >
          <FaPlay size={25} className={playerVisible ? 'ml-1' : 'ml-1'} />
        </div>
      )}

      <AnimatePresence>
        {flowId && playerVisible && flow.byOrder.length > 0 && <Player />}
      </AnimatePresence>

      {/* AREAS CONTAINER */}
      {isAreaModalOpen && <AreaModal />}
    </>
  );
};

export default Flow;
