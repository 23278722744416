import React, { useState } from 'react';
import emailjs, { init } from '@emailjs/browser';

// context
// context
import { useFlowContext } from '../../contexts';

const ContactForm = ({ setContactFormOpen }) => {
  const { user, flow } = useFlowContext();

  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState('');

  const handleClick = (e) => {
    setContactFormOpen(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { message } = e.target;
    if (message.value.length < 10) {
      setMessage('message needs at least 10 characters');
      setTimeout(() => {
        setMessage('');
      }, 3000);
      return;
    }

    setSending(true);
    init('1gZ0vQnN-pY3_1imy');
    emailjs
      .sendForm('gmail', 'template_9xm97ko', e.target)
      .then((result) => {
        setSending(false);
        setMessage('email sent : )');
        document.getElementById('feedback-form').reset();
        setTimeout(() => {
          setMessage('');
        }, 3000);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <form
        id='feedback-form'
        onSubmit={handleSubmit}
        className='absolute z-30 flex flex-col gap-2 p-6 bg-white rounded-lg shadow-inner w-128 bottom-7 right-7'
      >
        <button
          className='absolute text-lg font-bold text-gray-700 bg-gray-200 rounded-full w-7 top-1 right-2'
          onClick={handleClick}
        >
          X
        </button>
        <legend className='mb-2 text-xl font-bold'>How can we help you?</legend>
        <div className='flex items-center gap-4'>
          <div>
            <input type='radio' name='subject-topic' value='Question' className='text-red-800' />
            <span className='ml-2 '>Question</span>
          </div>
          <div>
            <input type='radio' name='subject-topic' value='Bug_Report' />
            <span className='ml-2 '>Bug Report</span>
          </div>
          <div>
            <input type='radio' name='subject-topic' value='FeatureRequest' />
            <span className='ml-2 '>Feature Request</span>
          </div>
        </div>
        <div className='hidden'>
          <input type='text' id='name' name='name' value={user.details.username} readOnly />
        </div>
        {flow.name && (
          <div className='hidden'>
            <input type='text' id='flowName' name='flowName' value={flow.name} readOnly />
          </div>
        )}
        <div className='flex flex-col'>
          <label htmlFor='subject' className='mb-2 '>
            Subject
          </label>
          <input
            type='text'
            id='subject'
            name='subject'
            className='px-4 py-1 border-2 rounded-lg outline-none'
          />
        </div>
        <div className='flex flex-col'>
          <label htmlFor='message' className='mb-2'>
            Message
          </label>
          <textarea
            required
            rows={3}
            type='textarea'
            id='message'
            name='message'
            className='p-2 px-4 border-2 rounded-lg'
          />
        </div>
        {message && (
          <h1 className='py-2 text-lg font-bold text-center bg-launchgreen'>{message}</h1>
        )}
        {!sending ? (
          <button
            type='submit'
            className='px-4 py-2 mt-4 font-bold text-white rounded-lg bg-secondary'
          >
            Send
          </button>
        ) : (
          <button
            disabled
            className='px-4 py-2 mt-4 font-bold text-black bg-gray-200 rounded-lg animate-pulse'
          >
            Sending...
          </button>
        )}
      </form>
    </>
  );
};

export default ContactForm;
