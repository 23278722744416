import React, { useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { useActionContext, useComboContext, useTrackContext } from '../../contexts';

const DraggableItem = ({
  item: Item,
  draggable,
  index,
  droppablePlaceholder,
  comboParent = false,
}) => {
  const { setActiveActionId, selectedActions, setSelectedActions } = useActionContext();
  const { setSelectedCombo } = useComboContext();
  // const { setSelectedTrack, setSelectedTrackActionId } = useTrackContext();

  const timerRef = useRef(null);

  const handleClick = (event) => {
    const isSelected = selectedActions.includes(draggable.id);

    if (event.shiftKey) {
      if (!isSelected) {
        setSelectedActions([...selectedActions, draggable.id]);
      }
    } else {
      setSelectedActions([draggable.id]);
      setActiveActionId(draggable.id);
    }

    //setSelectedCombo(null);

    // Does not allow the track to be selected
    // setSelectedTrack(null);
    // setSelectedTrackActionId(null);
  };

  const handleMouseDown = (e) => {
    if (e.target.classList.contains('combo')) {
      // console.log('MOUSE DOWN', e.target);
      timerRef.current = setTimeout(() => {
        document
          .getElementById(draggable.type + '-' + draggable.id)
          .querySelector('.combo-items')
          .classList.add('hidden');
      }, 50);
    }
  };

  const handleMouseUp = (e) => {
    if (e.target.classList.contains('combo')) {
      document
        .getElementById(draggable.type + '-' + draggable.id)
        .querySelector('.combo-items')
        .classList.remove('hidden');
      clearTimeout(timerRef.current);
    }
  };

  return (
    <Draggable key={draggable.id} draggableId={draggable.type + '-' + draggable.id} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          id={draggable?.type + '-' + draggable.id}
          className={`draggable ${
            draggable?.type === 'combo' || comboParent ? 'bg-lemon' : 'bg-white'
          } ${selectedActions.includes(draggable.id) ? 'bg-[#f5e8ec]' : ''}`}
          onClick={handleClick}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <Item action={draggable} index={index} droppablePlaceholder={droppablePlaceholder} />
        </div>
      )}
    </Draggable>
  );
};

export default DraggableItem;
