import React from 'react';
import { Link } from 'react-router-dom';

const notFound = () => {
  return (
    <div className='flex flex-col flex-wrap content-center justify-center w-screen h-screen bg-bgColor'>
      <p className='font-sans text-center text-white transition-all transform error-text hover:scale-110'>404</p>

      <p className='inline mt-5 text-xl text-center'>
        Got Lost? head back to{' '}
        <Link to='/'>
          <strong>Homepage.</strong>
        </Link>
      </p>
    </div>
  );
};

export default notFound;
