import moment from 'moment';

// Returns the time in 24-hour format as a string in the "HH:mm" format.
const formatStartTime = (date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

// Calculates and returns the start time of a specific action within a flow,
// considering the durations of all preceding actions and potential combo actions
const getActionStartTime = (flow, action, byOrder) => {
  const index = byOrder.findIndex((item) => item.id === action.id);
  const startDate = new Date(flow.startingDate);

  const timeTotal = byOrder.slice(0, index).reduce((total, item) => {
    if (item.type === 'combo' && item?.children?.length > 0) {
      return (
        total +
        item.children.reduce(
          (comboTotal, comboActionId) => comboTotal + (flow.byId[comboActionId]?.duration || 0),
          0,
        )
      );
    }
    return total + (item.duration || 0);
  }, 0);

  startDate.setSeconds(startDate.getSeconds() + timeTotal);
  return formatStartTime(startDate);
};

// calculates and returns the start time of a specific action within a combo,
// considering the start time of the combo and the durations of all preceding actions within the combo.
const getComboActionStartTime = (flow, byOrder, combo, comboAction) => {
  const comboStartTime = getActionStartTime(flow, combo, byOrder);

  const index = combo?.children?.findIndex((id) => id === comboAction.id);

  const [hours, minutes] = comboStartTime.split(':');
  const startDate = new Date();
  startDate.setHours(parseInt(hours, 10));
  startDate.setMinutes(parseInt(minutes, 10));

  const timeTotal = (combo?.children || [])
    .slice(0, index)
    .reduce((total, childId) => total + (flow.byId[childId]?.duration || 0), 0);

  startDate.setSeconds(startDate.getSeconds() + timeTotal);

  return formatStartTime(startDate);
};

// Calculates the end time of a flow by summing the durations of all items in the flow
// and adding it to the flow's start time.
const getFlowEndTime = (flow) => {
  let totalDuration = Object.values(flow.byId).reduce((acc, obj) => {
    return (obj.type !== 'combo') ? acc + obj.duration : acc;
  }, 0);

  return moment(flow.startingDate).add(totalDuration, 's').format('HH:mm');
};

const getFlowEndTimeFromNow = (flow) => {
  // Get the 'order' index of the current live item
  const liveItemOrderIndex = flow.byOrder.indexOf(flow.live.activeAction.id);

  // Get the remaining flow items to play after the current live item
  const remainingFlowItems = flow.byOrder.slice(liveItemOrderIndex + 1);

  // Sum up the durations of the remaining items in the flow
  let flowDurationLeft = remainingFlowItems.reduce((acc, id) => {
    const item = flow.byId[id]; 

    if (item) {
      if (item.type === 'combo') {
        // Sum up the durations of the combo item's children
        const comboDuration = item.children.reduce((acc, childId) => {
          const childItem = flow.byId[childId];
          return childItem ? acc + childItem.duration : acc;
        }, 0)
        return acc + comboDuration;
      } else {
        return acc + item.duration;
      }
    } else {
      return acc;
    }
  }, 0);

  // Add the seconds left from now until the start of the next job
  flowDurationLeft += (new Date(flow.live.dateOfNextJob) - new Date()) / 1000;

  // Return the calcualated end time from 'now' by adding the flowDurationLeft
  return moment().add(flowDurationLeft, 's').format('HH:mm');
} 

const convertToFormat = (seconds) => {
  if (!seconds || seconds === 'NaN') return '';
  if (seconds < 3600) {
    return new Date(seconds * 1000).toISOString().substring(14, 16);
  } else {
    return new Date(seconds * 1000).toISOString().substring(11, 16);
  }
};

const convertToMMSSFormat = (seconds) => {
  // Calculate total minutes and remaining seconds
  const totalMinutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format the time as MM:SS
  const formattedMinutes = totalMinutes < 10 ? `0${totalMinutes}` : `${totalMinutes}`;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

  return `${formattedMinutes}:${formattedSeconds}`;
};

const convertToSeconds = (timeString) => {
  if (!timeString) return;
  const arr = timeString.split(':');
  if (arr?.length === 2) {
    return arr[0] * 60 + +arr[1];
  } else {
    return arr[0] * 3600 + arr[1] * 60 + +arr[2];
  }
};

const formatActionDurationInput = (tempTime, duration) => {
  if (duration.length > 6 || (duration.length === 1 && duration === ':')) return;

  // Filter out non-numeric characters and ":"
  let filteredInput = duration.replace(/[^0-9:]/g, '');
  // Count the number of ":" characters
  const colonCount = filteredInput.split(':').length - 1;

  // Only update state if input contains numbers or one ":" character
  if (colonCount <= 1 && /^[0-9:]*$/.test(filteredInput)) {
    // If there is a colon at all
    if (colonCount > 0) {
      const [minutesStr, secondsStr] = filteredInput.split(':');

      if (secondsStr.length > 2) return;

      // Ensure seconds don't exceed two digits
      if (secondsStr && secondsStr.length <= 2) {
        // Limit the user input to 59 seconds
        if (parseInt(secondsStr) >= 60) filteredInput = minutesStr + ':59';
      }

      // setTempTime(filteredInput);
    }

    // Append ":" if input has reached the third character/digit
    if (tempTime.length === 3 && duration.length === 4 && !duration.includes(':')) {
      filteredInput = filteredInput.slice(0, 3) + ':' + filteredInput.slice(3, 4);
    }

    return filteredInput;
    // setTempTime(filteredInput);
  }
};

const calcTempTrackActionDuration = (tracks, activeTrackId) => {
  let sum = 0;

  tracks?.map((trackItem) => {
    if (trackItem.id === activeTrackId) {
      trackItem.children?.forEach((trackAction) => {
        sum += trackAction.duration;
      });
    }
  });
  return sum;
};

const calcTempTrackActionDurationNoActiveAction = (tracks, activeTrackId, actionId) => {
  let sum = 0;

  tracks?.map((trackItem) => {
    if (trackItem.id === activeTrackId) {
      trackItem.children?.forEach((trackAction) => {
        console.log(trackAction);
        if (trackAction.id !== actionId) {
          sum += trackAction.duration;
        }
      });
    }
  });
  return sum;
};

const findTrackById = (tracks, id) => {
  let copyTrack;
  let copyTrackIndex;

  for (let i = 0; i < tracks?.length; i++) {
    const track = tracks[i];
    if (track.id === id) {
      copyTrack = { ...track };
      copyTrackIndex = i;
      break;
    }
  }

  return { copyTrack, copyTrackIndex };
};

export {
  formatStartTime,
  getActionStartTime,
  getComboActionStartTime,
  getFlowEndTime,
  getFlowEndTimeFromNow,
  ///
  convertToFormat,
  convertToMMSSFormat,
  convertToSeconds,
  calcTempTrackActionDuration,
  calcTempTrackActionDurationNoActiveAction,
  findTrackById,
};
