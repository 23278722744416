import React from 'react';
import { convertToFormat } from '../../helper/helperFunctions';
import ActionItemLibrary from './ActionItemLibrary';

const ActionContainerLibrary = ({ actionData, setActionData, searchQuery }) => {
  return (
    <div className='flex-1 overflow-scroll border-l-2'>
      {[...actionData]
        .sort(function (a, b) {
          if (a.name.toLowerCase() === b.name.toLowerCase() && a.time > b.time) {
            return 0;
          }
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        })
        .filter((action) => {
          return (
            action.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            `${convertToFormat(action.time)}`.includes(searchQuery)
          );
        })
        .map((action) => (
          <ActionItemLibrary
            action={action}
            key={action._id}
            setActionData={setActionData}
            actionData={actionData}
          />
        ))}
    </div>
  );
};

export default ActionContainerLibrary;
