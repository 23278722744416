import React from 'react';
import { useFlowContext } from '../../contexts';

const DurationInput = ({ combo, durationInputRef, handleKeyDown }) => {
  const { flow } = useFlowContext();

  const secondsToMMMSS = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const calculateComboDuration = () => {
    let duration = 0;
    combo.children.forEach((childId) => {
      if (typeof duration === 'number') {
        if (flow.byId[childId]) duration += flow.byId[childId]?.duration;
      }
    });
    return secondsToMMMSS(duration);
  };

  return (
    <input
      ref={durationInputRef}
      className='w-[100px] p-4 text-center border border-[#c4c4c4] rounded-2xl outline-plum'
      type='text'
      name='duration'
      placeholder='MM:SS'
      disabled={true}
      value={calculateComboDuration()}
      handleKeyDown={(e) => handleKeyDown(e, 'duration')}
    />
  );
};

export { DurationInput };
