import React from 'react';

// context
import { useActionContext } from '../../contexts';

const RedirectToFlowCheck = ({
  resultItem,
  handleRedirectToFlow,
  setOpenNewFlow,
  addFlowToCurrentFlow,
  textInputRef,
  setCursor,
}) => {
  const { setSelectedActions } = useActionContext();
  return (
    <dialog className='fixed inset-0 z-40 flex items-center justify-center w-full h-screen text-black bg-black bg-opacity-50'>
      <div className='flex flex-col bg-white rounded-lg shadow-md w-96'>
        <p className='mx-auto mt-6 text-lg font-bold text-center'>What do you want to do?</p>
        <div className='flex flex-col mt-5 text-center'>
          <button
            autoFocus
            className='p-2 m-4 font-extrabold border-2 rounded-lg outline-none hover:bg-bgColor hover:text-white text-bgColor border-bgColor'
            onClick={(e) => {
              handleRedirectToFlow();
              setOpenNewFlow(false);
              textInputRef.current.focus();
              setCursor(-1);
              setSelectedActions([]);
            }}
          >
            Open "{resultItem.name}"
          </button>
          <button
            autoFocus
            className='p-2 m-4 font-extrabold border-2 rounded-lg outline-none hover:bg-bgColor hover:text-white text-bgColor border-bgColor'
            onClick={(e) => {
              addFlowToCurrentFlow();
              setOpenNewFlow(false);
              textInputRef.current.focus();
              setCursor(-1);
            }}
          >
            Add "{resultItem.name}" to current Flow
          </button>
          <button
            className='p-2 m-4 font-extrabold border-2 border-white rounded-lg hover:border-2 hover:border-black'
            onClick={(e) => setOpenNewFlow(false)}
          >
            X cancel
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default RedirectToFlowCheck;
