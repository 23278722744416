import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'react-tooltip';

// icons
import { TiDelete } from 'react-icons/ti';

const DeleteIcon = ({ setDeleteCheck }) => {
  const uniqueId = uuidv4();

  return (
    <>
      <TiDelete
        className='text-red-700 text-2xl cursor-pointer hover:text-red-500 hidden group-hover:block'
        onClick={() => setDeleteCheck(true)}
        data-tooltip-id={uniqueId}
        data-tooltip-content='Delete Field'
      />

      <Tooltip id={uniqueId} />
    </>
  );
};

export default DeleteIcon;
