const setMiroLink = (miroLink) => {
  let output = '';
  if (!miroLink.includes('live-embed')) {
    try {
      const urlParams = new URL(miroLink);
      let pathname = urlParams?.pathname?.slice(1, -1);
      let urlId = pathname.split('/').pop();
      output = `https://miro.com/app/live-embed/${urlId}/?embedAutoplay=true`;
    } catch (e) {
      {
        console.log('Error', e);
      }
      return miroLink;
    }
  }
  return output;
};

export default setMiroLink;
