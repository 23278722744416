import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'react-tooltip';

const IconButton = ({ icon: Icon, tooltipText, onClick, color }) => {
  const uniqueId = uuidv4();

  let iconBtnStyle;

  if (color) {
    iconBtnStyle = `flex items-center gap-2 border border-${color} bg-white text-${color} rounded-md p-1 cursor-pointer`;
  } else {
    iconBtnStyle = `flex items-center gap-2 text-white rounded-md p-1 cursor-pointer`;
  }

  return (
    <div className={iconBtnStyle} onClick={onClick}>
      <Icon size={12} data-tooltip-id={`tooltip-${uniqueId}`} data-tooltip-content={tooltipText} />

      <Tooltip id={`tooltip-${uniqueId}`} />
    </div>
  );
};

export default IconButton;
