import React, { useContext, useState } from 'react';

const AppContext = React.createContext();

const TrackContextProvider = ({ children }) => {
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [selectedTrackActionId, setSelectedTrackActionId] = useState(null);
  const [isTrackExpanded, setIsTrackExpanded] = useState(false);

  return (
    <AppContext.Provider
      value={{
        selectedTrack,
        setSelectedTrack,
        isTrackExpanded,
        setIsTrackExpanded,

        selectedTrackActionId,
        setSelectedTrackActionId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useTrackContext = () => {
  return useContext(AppContext);
};

export { TrackContextProvider };
