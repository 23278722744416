import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import StartNewFlowButton from './StartNewFlowButton';
import { HiMenu } from 'react-icons/hi';
import { BsFillPersonFill } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import string_to_slug from '../../helper/stringToSlug';

// context
import { useAppContext, useFlowContext, useActionContext } from '../../contexts';

const Menu = ({ props, setTimeDate }) => {
  const { setIsAreaModalOpen, setContactFormOpen } = useAppContext();
  const { user, userWorkspace, flow, flowId, setInputFieldText } = useFlowContext();
  const { setSelectedActions } = useActionContext();

  const [menuActive, setMenuActive] = useState(false);
  const [profileMenuActive, setProfileMenuActive] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const DropdownItem = ({ href, children }) => (
    <a
      href={href}
      target='_blank'
      rel='noreferrer'
      className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
      role='menuitem'
    >
      {children}
    </a>
  );

  return (
    <div className='z-30 flex flex-col self-center'>
      <div className='relative flex items-center gap-5'>
        {/* GREET USER + NAME */}
        <span className='text-lg font-light tracking-wider'>
          Hello {user.details.firstName && user.details.firstName}
        </span>

        {/* PROFILE ICON */}
        <div
          className='cursor-pointer'
          onMouseEnter={(e) => setProfileMenuActive(true)}
          onMouseLeave={(e) => setProfileMenuActive(false)}
        >
          <BsFillPersonFill size='40' />
          {profileMenuActive && (
            <div className='absolute flex flex-col w-32 gap-1 -ml-20'>
              {(user.details.role === 'superadmin' || user.details.role === 'admin') && (
                <button
                  className='btn-dropdown'
                  onClick={(e) => {
                    setProfileMenuActive(false);
                    history.push('/signup');
                  }}
                >
                  User admin
                </button>
              )}
              <LogoutButton props={props} />
            </div>
          )}
        </div>

        {/* HAMBURGER MENU ICON */}
        <div
          className='cursor-pointer'
          onMouseEnter={(e) => setMenuActive(true)}
          onMouseLeave={(e) => setMenuActive(false)}
        >
          <HiMenu size={40} />
          {menuActive && (
            <div className='absolute flex flex-col w-36 gap-1 -ml-20'>
              <button
                className='btn-dropdown'
                onClick={(e) => {
                  setProfileMenuActive(false);
                  history.push('/library');
                }}
              >
                Library
              </button>

              {flowId && (
                <>
                  {/* <StartNewFlowButton setTimeDate={setTimeDate} /> */}
                  <button className='btn-dropdown'>
                    <a href='/'>New Flow</a>
                  </button>

                  <button
                    className='btn-dropdown'
                    // DISABLED UNTIL EXPORT VIEW IS FIXED
                    disabled={false}
                    onClick={(e) => history.push(`${location.pathname}/export`)}
                  >
                    Export
                  </button>

                  <div className='group relative btn-dropdown text-center'>
                    <a 
                        className='text-center w-full'
                        href={`/${userWorkspace}/${flowId}/${string_to_slug(flow.name)}/share`}
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        Share Link
                    </a>
                    <div
                      className='absolute left-0 mt-2 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 hidden group-hover:block'
                      role='menu'
                      aria-orientation='vertical'
                      aria-labelledby='options-menu'
                    >
                      <DropdownItem
                        href={`/${userWorkspace}/${flowId}/${string_to_slug(flow.name)}/share`}
                      >
                        Global
                      </DropdownItem>
                      {flow?.areas?.map((area, index) => (
                        <DropdownItem
                          key={index}
                          href={`/${userWorkspace}/${flowId}/${string_to_slug(
                            flow.name,
                          )}/share/${area}`}
                        >
                          {area}
                        </DropdownItem>
                      ))}
                    </div>
                  </div>

                  <button
                    className='btn-dropdown'
                    onClick={(e) => {
                      setIsAreaModalOpen(true);
                    }}
                  >
                    Areas
                  </button>
                </>
              )}
              <button
                className='btn-dropdown'
                onClick={(e) => {
                  setContactFormOpen(true);
                }}
              >
                Contact
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
