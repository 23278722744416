import React from 'react';
import FlowItemLibrary from './FlowItemLibrary';

const FlowContainerLibrary = ({ flowData, setFlowData, searchQuery }) => {
  return (
    <div className='flex-1 overflow-scroll'>
      {flowData &&
        [...flowData]
          .filter((el) => el.name)
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((flow) => flow.name.toLowerCase().includes(searchQuery.toLowerCase()))
          .map((flow) => (
            <FlowItemLibrary
              flow={flow}
              key={flow.key}
              flowData={flowData}
              setFlowData={setFlowData}
            />
          ))}
    </div>
  );
};

export default FlowContainerLibrary;
