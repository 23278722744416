import React, { useState } from 'react';
import fireDb from '../../firebase';
import { useHistory } from 'react-router-dom';
import string_to_slug from '../../helper/stringToSlug';
import { BiEditAlt } from 'react-icons/bi';

// context
import { useFlowContext } from '../../contexts';

const FlowName = () => {
  const { flow, setFlow, flowId, userWorkspace } = useFlowContext();

  const [editModeFlowName, setEditModeFlowName] = useState(false);

  let history = useHistory();

  const pushNewFlowToDatabase = (e) => {
    if (e.target.value.length > 0) {
      const updatedFlow = { ...flow };
      updatedFlow.name = e.target.value;
      setFlow({ ...updatedFlow });
      const ref = fireDb.child(userWorkspace + '/flows').push(updatedFlow);
      setEditModeFlowName(false);
      history.push(`/flow/${ref.key}/${string_to_slug(e.target.value)}`);
    }
  };

  const updateFlowInDatabase = (e) => {
    if (e.target.value.length > 0) {
      fireDb.child(userWorkspace + '/flows/' + flowId + '/name').set(e.target.value);
      setEditModeFlowName(false);
      history.push(`/flow/${flowId}/${string_to_slug(e.target.value)}`);
    }
  };

  const handleKeyDown = (e) => {
    if (!flowId) {
      if (e.key === 'Enter') {
        console.log('PUSH FLOW TO DB', flowId);
        pushNewFlowToDatabase(e);
      }
    }
    if (flowId) {
      if (e.key === 'Enter') {
        updateFlowInDatabase(e);
      }
    }
  };

  const handleBlur = (e) => {
    if (!flowId && e.target.value.length > 0) {
      pushNewFlowToDatabase(e);
    }
    if (flowId && e.target.value.length > 0) {
      updateFlowInDatabase(e);
    }
  };

  return (
    <div
      className={`rounded ${!flowId ? 'animate-pulse' : ''} ${
        editModeFlowName ? 'outline outline-plum outline-2' : ''
      }`}
    >
      <div className='relative px-4 py-2 pr-10 bg-white rounded border border-[#c4c4c4]'>
        {!editModeFlowName ? (
          <div
            className='text-black cursor-pointer'
            onClick={(e) => setEditModeFlowName((prev) => !prev)}
          >
            <p className='break-words'>{flow.name ? flow.name : 'Unnamed Flow'}</p>
            <BiEditAlt size={25} className='absolute bottom-2 right-2 text-gray-500' />
          </div>
        ) : (
          <input
            autoFocus
            className='text-black bg-transparent w-[650px] p-2 outline-none'
            defaultValue={flow.name || 'Unnamed Flow'}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        )}

        {/* Change info text depending on if flow is already named */}
        {!editModeFlowName && (
          <p className='text-xs italic text-gray-400 bg-white'>
            {flowId
              ? 'click here to edit your flow name'
              : 'give your flow a name in order to save it'}
          </p>
        )}
      </div>
    </div>
  );
};

export default FlowName;
