import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'react-tooltip';

const ToolButton = ({ icon: Icon, iconStyle, iconSize, tooltipText, onClickAction }) => {
  const uniqueId = uuidv4();

  const iconGray =
    'relative p-2 text-gray-700 rounded-full hover:bg-plum hover:bg-opacity-10 cursor-pointer';
  const iconPlum =
    'relative p-2 text-plum rounded-full hover:bg-plum hover:bg-opacity-10 cursor-pointer';

  return (
    <div className={`group ${iconStyle === 'plum' ? iconPlum : iconGray}`}>
      <Icon
        size={iconSize}
        onClick={onClickAction}
        data-tooltip-id={uniqueId}
        data-tooltip-content={tooltipText}
      />

      <Tooltip id={uniqueId} />
    </div>
  );
};

export default ToolButton;
