import React, { useEffect } from 'react';
import ResultItem from './ResultItem';

// context
import { useFlowContext } from '../../contexts';

const SearchResults = ({ cursor, setCursor, textInputRef, actionResultsData, flowResultsData }) => {
  const { flowId, setSearchbarOpen, inputFieldText } = useFlowContext();

  let filteredFlowArray = [];
  let filteredActionArray = [];

  filteredActionArray = [...actionResultsData]
    .sort(function (a, b) {
      if (a.name === b.name && a.time > b.time) {
        return 0;
      }
      return a.name > b.name ? 1 : -1;
    })
    .map(({ _id, name, time, children, type }) => {
      return {
        id: _id,
        name,
        time,
        children,
        type,
      };
    });

  filteredFlowArray = [...flowResultsData]
    .filter((item) => item.key !== flowId && item.name)
    .filter((item) => {
      return item.name.toLowerCase().includes(`${inputFieldText}`.toLocaleLowerCase());
    })

    .sort(function (a, b) {
      if (a.name === b.name) {
        return 0;
      }
      return a.name > b.name ? 1 : -1;
    })
    .map((flow) => {
      return {
        id: flow.key,
        name: flow.name,
        type: 'flow',
      };
    });
  // }

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') setSearchbarOpen(false);
  };

  let filteredResultsArray = [...filteredActionArray, ...filteredFlowArray];

  useEffect(() => {
    if (cursor === -1) {
      textInputRef.current.focus();
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [cursor, textInputRef]);

  return (
    <>
      <div
        id='search-results'
        className='absolute z-20 left-1/2 transform -translate-x-1/2 bottom-[5rem] w-[500px]'
      >
        <div className='absolute h-8 px-2 overflow-visible font-bold leading-8 border border-black rounded-full bg-bgColorLight -top-7 -left-10'>
          Press ESC to close
        </div>
        <div className='overflow-auto bg-white border border-[#c4c4c4] shadow max-h-60 flex flex-col'>
          {filteredResultsArray.length > 0 ? (
            filteredResultsArray.map((item, idx) => (
              <ResultItem
                textInputRef={textInputRef}
                flowResultsData={flowResultsData}
                key={item.id}
                resultItem={item}
                index={idx}
                cursor={cursor}
                setCursor={setCursor}
                filteredArray={filteredResultsArray}
              />
            ))
          ) : (
            <h1 className='p-2 px-3 font-bold text-black'>no items found</h1>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchResults;
