import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useStore from '../store/UserStore';

export default function AdminRoute(props) {
  const user = useStore((state) => state.user);
  const { component: Component, ...rest } = props;

  if (user.details.role === 'superadmin' || user.details.role === 'admin') {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return <Redirect to='/' />;
}
