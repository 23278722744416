import create from 'zustand';

const useStore = create((set) => ({
  user: {},
  setUser: (token) => {
    set((state) => ({ user: { ...state.user, token } }));
  },
  setUserDetails: (details) => {
    set((state) => ({ user: { ...state.user, details } }));
  },
}));
export default useStore;
