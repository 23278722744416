import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { FaCheckCircle } from 'react-icons/fa';
import { ImRadioUnchecked } from 'react-icons/im';

import {
  getActionStartTime,
  getComboActionStartTime,
  getFlowEndTime,
} from '../helper/helperFunctions';
import { secondsToMMMSS } from '../helper/timeFormaters';
import { useAppContext, useFlowContext } from '../contexts';
import Area from '../components/area/Area';

const AreaPill = ({ area }) => (
  <p className='inline-block mx-2 px-2 py-1 text-sm text-white rounded-md bg-peach accent_color'>
    {`in ${area}`}
  </p>
);

const ToggleControl = ({ isActive, onToggle, label }) => {
  const iconClasses = 'text-3xl cursor-pointer text-plum';

  return (
    <div className='flex flex-row gap-2 items-center'>
      {isActive ? (
        <FaCheckCircle className={iconClasses} onClick={onToggle} />
      ) : (
        <ImRadioUnchecked className={iconClasses} onClick={onToggle} />
      )}
      <label>{label}</label>
    </div>
  );
};

const ActionCard = ({ action, startTime, duration, showAreas, showDetails }) => {
  const colorMap = {
    breakoutSession: 'bg-gray-400',
    combo: 'bg-gray-400',
    action: 'bg-gray-100',
  };

  const bgColor = colorMap[action.type] || 'bg-white';

  return (
    <div className={`flex w-full border-b border-gray-400 ${bgColor}`}>
      {/* START TIME & DURATION */}
      <div className='p-2 w-1/6'>
        <h3 className='text-gray-600 p-2'>
          {/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(startTime) ? startTime : ''}
        </h3>

        <h3 className='inline-block p-2 font-bold text-white rounded-md bg-bgColor accent_color'>
          {duration || '0:00'}
        </h3>
      </div>

      {/* AREA */}
      {showAreas && <h3 className='w-1/6 px-2 text-gray-600 p-2'>{action?.area}</h3>}

      {/* NAME */}
      <div className='w-1/6 px-2 text-gray-600 p-2'>
        <h3 className='font-bold'>{action?.name}</h3>
      </div>

      {/* DETAILS */}
      <div className='w-1/2 px-2 text-gray-600 p-2'>
        {
          showDetails &&
            Object.entries(action?.fields || {}).map(([key, value], index) => {
              return (
                <div key={index} className='mb-3'>
                  <h2 className={'font-bold capitalize'}>{key}:</h2>
                  <div>{value.toString()}</div>
                </div>
              );
            })
          // ))}
        }
      </div>
    </div>
  );
};

const ExportActionItem = ({
  flow,
  action,
  byOrder,
  showAreas,
  extendCombos,
  extendBreakoutSessions,
  showDetails,
}) => {
  if (action.type === 'breakoutSession') {
    console.log(action);
    return (
      <div className='border-l-4 border-bgColor'>
        <ActionCard
          action={action}
          startTime={getActionStartTime(flow, action, byOrder)}
          showAreas={showAreas}
          duration={secondsToMMMSS(action.duration)}
          showDetails={showDetails}
        />

        {/* BREAKOUT SESSION TRACKS */}
        {extendBreakoutSessions && (
          <div className='flex w-full border-b border-gray-400 bg-gray-200'>
            {action?.children.map((track, index) => (
              <div key={index} className='flex-1 border-r border-gray-600'>
                <div>
                  <p className='font-bold underline text-gray-600 p-2'>{track.name}</p>
                  <AreaPill area={track.area} />
                </div>

                {track?.fields?.description && (
                  <div>
                    <p className='text-sm text-gray-600 p-2'>
                      <span className='font-bold'>Description: </span>
                      {track.fields.description}
                    </p>
                  </div>
                )}

                {track?.fields?.custom_fields &&
                  track.fields.custom_fields.map((field, index) => (
                    <div key={index}>
                      <p className='text-sm text-gray-600 p-2'>
                        <span className='font-bold'>
                          {field.name.charAt(0).toUpperCase() + field.name.slice(1)}:{' '}
                        </span>
                        {field.type === 'text' && <span>{field.value}</span>}
                        {field.type === 'select' && <span>{field.selectedOption}</span>}
                        {field.type === 'checkbox' && (
                          <span>{field.value ? 'Checked' : 'Unchecked'}</span>
                        )}
                      </p>
                    </div>
                  ))}

                {/* TRACK ACTIONS */}
                {/* <div>
                  {track?.children.map((trackAction, index) => (
                    <p key={index} className='text-gray-600 p-2'>
                      {trackAction.name}
                    </p>
                  ))}
                </div> */}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  if (action.type === 'combo') {
    const comboActions = action?.children
      .filter((comboActionId) => {
        const actionExists = flow.byId[comboActionId] !== undefined;
        return actionExists;
      })
      .map((comboActionId) => flow.byId[comboActionId]);

    const comboDuration = comboActions.reduce((acc, comboAction) => acc + comboAction.duration, 0);

    return (
      <div className='border-l-4 border-lemon'>
        <ActionCard
          action={action}
          startTime={getActionStartTime(flow, action, byOrder)}
          duration={secondsToMMMSS(comboDuration)}
          showAreas={showAreas}
          showDetails={showDetails}
        />
        {/* COMBO ACTIONS */}
        {extendCombos &&
          comboActions.map((comboAction, index) => (
            <ActionCard
              key={index}
              action={comboAction}
              startTime={getComboActionStartTime(flow, byOrder, action, comboAction)}
              duration={secondsToMMMSS(comboAction.duration)}
              showAreas={showAreas}
              showDetails={showDetails}
            />
          ))}
      </div>
    );
  }

  return (
    <div className=' border-l-4 border-white'>
      <ActionCard
        action={action}
        startTime={getActionStartTime(flow, action, byOrder)}
        duration={secondsToMMMSS(action.duration)}
        showAreas={showAreas}
        showDetails={showDetails}
      />
    </div>
  );
};

const ExportPage = (props) => {
  const history = useHistory();

  const { flow, setFlowId } = useFlowContext();
  const { setNotesVisible } = useAppContext();

  const [byOrder, setByOrder] = useState([]);
  const [flowStartDate, setFlowStartDate] = useState(
    new Date(flow?.startingDate).toLocaleDateString() || '',
  );
  const [flowEndTime, setFlowEndTime] = useState('');
  const [flowStatus, setFlowStatus] = useState('');

  const [showAreas, setShowAreas] = useState(true);
  const [showDetails, setShowDetails] = useState(true);
  const [extendCombos, setExtendCombos] = useState(true);
  const [extendBreakoutSessions, setExtendBreakoutSessions] = useState(true);

  const toggleShowAreas = () => setShowAreas((prevShowAreas) => !prevShowAreas);
  const toggleShowDetails = () => setShowDetails((prevShowDetails) => !prevShowDetails);
  const toggleExtendCombos = () => setExtendCombos((prevExtendCombos) => !prevExtendCombos);
  const toggleExtendBreakoutSessions = () =>
    setExtendBreakoutSessions((prevExtendBreakoutSessions) => !prevExtendBreakoutSessions);

  useEffect(() => {
    setFlowId(props.match.params.id);
  }, [props]);

  useEffect(() => {
    const { live } = flow;

    setFlowEndTime(getFlowEndTime(flow));

    if (Object.keys(live).length === 0) {
      setFlowStatus("Flow hasn't started yet");
    } else {
      setFlowStatus(live.status);
    }

    setFlowStartDate(new Date(flow.startingDate).toLocaleDateString());
  }, [flow]);

  // Get all actions sorted by order
  useEffect(() => {
    let renderOrder = [];

    renderOrder = [...flow.byOrder].map((actionId) => {
      return flow.byId[actionId];
    });

    setByOrder(renderOrder);
  }, [flow.byOrder, flow.byId]);

  return (
    <div className='min-h-screen bg-white'>
      <BiArrowBack
        onClick={(e) => {
          history.goBack();
          setNotesVisible(false);
        }}
        size={28}
        className='absolute cursor-pointer left-2 top-5 not-in-pdf'
      />

      <div className='w-full px-24 py-5 pdf-content'>
        <div className='flex flex-row justify-between w-full'>
          {/* FLOW NAME AND START DATE */}
          <div className='flex flex-col'>
            <h3 className='inline-block text-3xl text-gray-800'>
              <b>{flow.name}</b>
            </h3>

            {flow.startingDate && (
              <h5 className='inline-block text-lg text-gray-500'>
                <b>{flowStartDate}</b>
              </h5>
            )}
          </div>

          {/* CONTROLS */}
          <div className='flex gap-8 items-center justify-end'>
            <ToggleControl isActive={showAreas} onToggle={toggleShowAreas} label='Show Areas' />
            <ToggleControl
              isActive={extendBreakoutSessions}
              onToggle={toggleExtendBreakoutSessions}
              label='Extend Breakout Sessions'
            />
            <ToggleControl
              isActive={extendCombos}
              onToggle={toggleExtendCombos}
              label='Extend Combos'
            />
            <ToggleControl
              isActive={showDetails}
              onToggle={toggleShowDetails}
              label='Show Details'
            />

            <button
              className='px-4 py-2 font-semibold text-gray-600 bg-white border border-gray-400 rounded shadow right-5 top-5 hover:bg-gray-100'
              onClick={(e) => {
                document.title = flow.name;
                window.print();
              }}
            >
              PDF | print
            </button>
          </div>
        </div>

        {/* TABLE HEADER */}
        <div className='flex w-full bg-gray-100 border-t border-b border-gray-400 mt-5'>
          <h3 className='w-1/6 px-2 font-bold text-gray-600 p-2'>Start Time & Duration</h3>
          {showAreas && (
            <h3 className='w-1/6 px-2 font-bold text-gray-600 border-gray-400 p-2'>Area</h3>
          )}
          <h3 className='w-1/6 px-2 font-bold text-gray-600 p-2'>Action</h3>
          <h3 className='w-1/2 px-2 font-bold text-gray-600 p-2'>Details</h3>
        </div>

        {/* LIST OF ACTIONS */}
        {byOrder.map((action, index) => {
          return (
            <ExportActionItem
              key={index}
              props={props}
              flow={flow}
              action={action}
              byOrder={byOrder}
              showAreas={showAreas}
              extendCombos={extendCombos}
              extendBreakoutSessions={extendBreakoutSessions}
              showDetails={showDetails}
            />
          );
        })}

        {/* FLOW END TIME AND STATUS */}

        {flow.startingDate && (
          <>
            <div className='mt-5 text-right'>
              <span className='font-bold'>End: </span>
              {flowEndTime}
            </div>

            <div className='text-right'>
              <span className='font-bold'>Status: </span>
              {flowStatus}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ExportPage;
