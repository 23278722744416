const secondsToMMMSS = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  if (minutes > 0 || seconds > 0) return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  return '';
};

const formatDurationInput = (input) => {
  // Remove non-numeric characters and leading zeros
  let formattedValue = input.replace(/[^\d:]/g, '');

  // Insert colon after the third character if not already inserted
  if (formattedValue.length > 3 && formattedValue.indexOf(':') === -1) {
    formattedValue = formattedValue.slice(0, 3) + ':' + formattedValue.slice(3);
  }

  // Limit input length to 5 characters (MMM:SS)
  formattedValue = formattedValue.slice(0, 6);

  return formattedValue;
};

const timeToSeconds = (input) => {
  // Check if colon is present
  const colonIndex = input.indexOf(':');

  let minutes = 0;
  let seconds = 0;

  if (colonIndex === -1) {
    // No colon, only minutes provided
    minutes = parseInt(input) || 0;
  } else if (colonIndex === 0) {
    // Colon at the beginning, only seconds provided
    seconds = parseInt(input.slice(1)) || 0;
  } else {
    // Colon present, both minutes and seconds provided
    minutes = parseInt(input.slice(0, colonIndex)) || 0;
    seconds = parseInt(input.slice(colonIndex + 1)) || 0;
  }

  // Calculate total seconds
  return minutes * 60 + seconds;
};

export { secondsToMMMSS, formatDurationInput, timeToSeconds };
