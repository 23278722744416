import React from 'react';
import { Link } from 'react-router-dom';

import LogoBg from '../assets/logo-bg.png';

const textShadow = '[text-shadow:_0_2px_0_rgb(0_0_0_/_50%)]';

const Home = () => {
  return (
    <>
      <div className='relative h-screen overflow-hidden'>
        {/* Gradient Background */}
        <div className='absolute inset-0 bg-linear-gradient'></div>

        {/* Image Overlay */}
        <img src={LogoBg} className='absolute inset-0 w-full h-full object-cover' alt='bg' />

        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-12'>
          <div>
            <p className={`text-8xl text-bgColor font-light tracking-widest mb-2 ${textShadow}`}>
              flowtomator
            </p>
            <p className='text-2xl font-light tracking-wider text-center'>
              where great sessions unfold
            </p>
          </div>

          {/* CONTROLS */}
          <div className='flex gap-5'>
            <Link
              className='bg-bgColor text-white text-2xl p-5 rounded-sm cursor-pointer hover:bg-bgColorLight transition'
              to='/login'
            >
              Login
            </Link>
            <Link
              className='bg-bgColor text-white text-2xl p-5 rounded-sm cursor-pointer hover:bg-bgColorLight transition'
              to='/register'
            >
              Register
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
