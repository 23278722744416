import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Modal } from './../Modal';
import RedirectToFlowCheck from './RedirectToFlowCheck';
import string_to_slug from '../../helper/stringToSlug';
import { convertToFormat } from '../../helper/helperFunctions';
import { secondsToMMMSS } from '../../helper/timeFormaters';
import fireDb from '../../firebase';
import { v4 as uuidv4 } from 'uuid';

import addToFlowFromLibrary from '../../flowFunctions/addItemFromLibrary';

// context
import { useFlowContext, useActionContext } from '../../contexts';

const ResultItem = ({
  cursor,
  setCursor,
  resultItem,
  index,
  filteredArray,
  flowResultsData,
  textInputRef,
}) => {
  const {
    userWorkspace,
    flow,
    setFlow,
    flowId,
    setSearchbarOpen,
    setInputFieldText,
    setInputFieldDuration,
  } = useFlowContext();
  const { activeActionId, setActiveActionId } = useActionContext();

  const [openNewFlowCheck, setOpenNewFlow] = useState(false);
  const resultRef = useRef(null);
  const history = useHistory();

  const handleOnClick = (e) => {
    if (resultItem.type === 'flow') {
      setOpenNewFlow(true);
      return;
    }
    if (resultItem.type === 'action') {
      const { time, children, ...rest } = resultItem;
      const newAction = { ...rest, id: uuidv4(), duration: resultItem.time };

      setFlow({
        ...flow,
        byId: {
          ...flow.byId,
          [newAction.id]: {
            ...newAction,
          },
        },
        byOrder: [...flow.byOrder, newAction.id],
      });
    } else if (resultItem.type === 'combo') {
      const { time, ...rest } = resultItem;

      const newChildren = resultItem.children.reduce((acc, child) => {
        console.log(child);
        const newId = uuidv4();
        let newChild = { ...child, id: newId, parentId: resultItem.id };

        if (child.type === 'breakoutSession' && child.children) {
          newChild.children = child.children.reduce((childAcc, grandChild) => {
            const newGrandChildId = uuidv4();
            const newGrandChild = { ...grandChild, id: newGrandChildId };
            childAcc.push(newGrandChild);
            return childAcc;
          }, []);
        }

        acc[newId] = newChild;
        return acc;
      }, {});

      const newCombo = {
        ...rest,
        id: uuidv4(),
        duration: resultItem.time,
        children: Object.keys(newChildren),
      };

      console.log('NEW COMBO', newCombo);

      setFlow({
        ...flow,
        byId: {
          ...flow.byId,
          [newCombo.id]: {
            ...newCombo,
          },
          ...newChildren,
        },
        byOrder: [...flow.byOrder, newCombo.id],
      });
    }

    setSearchbarOpen(false);
    setInputFieldText('');
    setInputFieldDuration('');
  };

  const handleKeyDown = (e) => {
    e.preventDefault();

    if (e.key === 'ArrowUp' && cursor < filteredArray.length - 1) {
      setCursor((prev) => prev + 1);
    }

    if (e.key === 'ArrowDown' && cursor > -1) {
      if (cursor === 0) {
        setCursor(-1);
      } else {
        setCursor((prev) => prev - 1);
      }
    }

    if (e.key === 'Enter' && resultItem.type === 'flow') {
      setOpenNewFlow(true);
      return;
      // textInputRef.current.focus();
    }

    if (e.key === 'Enter' && /combo|action/.test(resultItem.type)) {
      addToFlowFromLibrary(
        flow,
        flowId,
        setFlow,
        activeActionId,
        resultItem,
        userWorkspace,
        setCursor,
        setInputFieldText,
      );
      setSearchbarOpen(false);
      textInputRef.current.focus();
      setCursor(-1);
    }
  };

  const calculateTime = () => {
    if (resultItem.type === 'action') {
      return resultItem.time;
    }
    if (resultItem.type === 'combo') {
      let time = 0;
      resultItem.children.forEach((action) => {
        time += action.time;
      });
      return time;
    }
    return;
  };

  const handleRedirectToFlow = () => {
    const slugifyedFlowName = string_to_slug(resultItem.name);
    history.push(`/flow/${resultItem.id}/${slugifyedFlowName}`);
    setInputFieldText('');
    setSearchbarOpen(false);
  };

  const addFlowToCurrentFlow = () => {
    const idxOfActiveAction = flow.byOrder.indexOf(activeActionId);
    const selectedFlow = flowResultsData.find((flow) => flow.key === resultItem.id);
    const updatedFlow = { ...flow };
    updatedFlow.byId = { ...flow.byId, ...selectedFlow.byId };
    if (['up', 'down'].includes(activeActionId)) {
      updatedFlow.byOrder = [...(flow.byOrder || []), ...selectedFlow.byOrder];
    } else {
      delete updatedFlow.byId[activeActionId];
      updatedFlow.byOrder = [
        ...(flow.byOrder.slice(0, idxOfActiveAction) || []),
        ...selectedFlow.byOrder,
        ...(flow.byOrder.slice(idxOfActiveAction + 1) || []),
      ];
    }
    if (flowId) {
      const updates = {};
      updates[userWorkspace + '/flows/' + flowId] = updatedFlow;
      fireDb.update(updates);
    } else {
      setFlow(updatedFlow);
    }

    setActiveActionId(selectedFlow.byOrder[0] || 'up');
    setSearchbarOpen(false);
    setInputFieldText('');
  };

  useEffect(() => {
    if (cursor === index) {
      resultRef.current.focus();
    }
  }, [cursor, index, resultRef]);

  const style = {
    combo: 'bg-combo px-2 rounded-sm',
  };

  return (
    <>
      <div className='text-black outline-none'>
        <div
          onClick={handleOnClick}
          ref={resultRef}
          tabIndex={cursor === index ? 0 : -1}
          className={
            cursor === index
              ? 'm-2 font-semibold list-none border-b-2 cursor-pointer bg-gray-300 p-1 grid grid-cols-3 items-center outline-none'
              : 'm-2 font-semibold list-none border-b-2 cursor-pointer hover:bg-gray-300 p-1 grid grid-cols-3 items-center outline-none'
          }
          onKeyDown={handleKeyDown}
        >
          <h1 className='w-40'>{resultItem.name}</h1>
          <p className={`text-sm justify-self-center ${style[resultItem.type]}`}>
            {resultItem.type}
          </p>
          {/* <h1 className='justify-self-end'>{convertToFormat(calculateTime())}</h1> */}
          <h1 className='justify-self-end'>{secondsToMMMSS(resultItem.time)}</h1>
        </div>
      </div>
      {openNewFlowCheck && (
        <Modal>
          <RedirectToFlowCheck
            addFlowToCurrentFlow={addFlowToCurrentFlow}
            resultItem={resultItem}
            handleRedirectToFlow={handleRedirectToFlow}
            setOpenNewFlow={setOpenNewFlow}
            textInputRef={textInputRef}
            setCursor={setCursor}
          />
        </Modal>
      )}
    </>
  );
};
export default ResultItem;
