import React, { useState, useEffect } from 'react';
import { secondsToMMMSS, formatDurationInput, timeToSeconds } from '../../helper/timeFormaters';
import { useFlowContext } from '../../contexts';

const DurationInputField = ({
  breakoutSession,
  track,
  trackAction,
  maxAllowedDuration,
  setMaxAllowedDuration,
  durationInputRef,
}) => {
  const { setFlow } = useFlowContext();
  const [duration, setDuration] = useState(secondsToMMMSS(trackAction.duration));

  const handleChange = (event) => {
    const formattedDuration = formatDurationInput(event.target.value);

    if (maxAllowedDuration - timeToSeconds(formattedDuration) >= 0) {
      setDuration(formattedDuration);
    }
  };

  const handleBlur = (e) => {
    const seconds = timeToSeconds(duration);

    if (maxAllowedDuration - seconds >= 0) {
      setDuration(secondsToMMMSS(seconds));

      setFlow((prevFlow) => {
        const updatedFlow = { ...prevFlow };
        const updatedBreakoutSession = { ...updatedFlow.byId[breakoutSession.id] };
        const updatedTrackIndex = updatedBreakoutSession.children.findIndex(
          (child) => child.id === track.id,
        );
        const updatedTrack = { ...updatedBreakoutSession.children[updatedTrackIndex] };
        const updatedTrackActionIndex = updatedTrack.children.findIndex(
          (child) => child.id === trackAction.id,
        );
        const updatedTrackAction = {
          ...updatedTrack.children[updatedTrackActionIndex],
          duration: seconds,
        };

        // Update the trackAction in the track
        updatedTrack.children[updatedTrackActionIndex] = updatedTrackAction;

        // Update the track in the breakoutSession
        updatedBreakoutSession.children[updatedTrackIndex] = updatedTrack;

        // Update the breakoutSession in the flow
        updatedFlow.byId[breakoutSession.id] = updatedBreakoutSession;

        return updatedFlow;
      });

      setMaxAllowedDuration((prev) => prev - seconds);
    }
  };

  return (
    <input
      className='p-2 rounded-xl text-sm text-center placeholder:text-xs bg-white border border-[#c4c4c4] outline-plum'
      type='text'
      name='duration'
      placeholder='MM:SS'
      value={duration}
      onChange={handleChange}
      onBlur={handleBlur}
      ref={durationInputRef}
    />
  );
};

export default DurationInputField;
