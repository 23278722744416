import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import InputField from '../components/UI/InputField';
import Spinner from '../components/Spinner';
import { Modal } from '../components/Modal';

import { useFlowContext } from '../contexts';

const Register = () => {
  const { user, userWorkspace } = useFlowContext();

  console.log('USER ROLE: ', user.details.role);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const password = watch('password');

  const [addWorkspaceMode, setAddWorkspaceMode] = useState(false);
  const [workspaceNameToDB, setWorkspacenameToDb] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);

  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (data) => {
    setResponseMessage('');
    setIsSubmitted(true);

    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/users/signup', {
        username: data.email,
        password: data.password,
        firstName: data.firstName,
        workspace: data.workspace,
        role: data.isAdmin ? 'admin' : 'user',
      });

      if (response.status === 200) {
        setResponseMessage({ message: 'User created successfully', type: 'success' });
      }
    } catch (error) {
      setResponseMessage({ message: error.response.data.error, type: 'error' });
      setIsSubmitted(false);
    }
  };

  const addWorkspaceToDatabase = useCallback(
    (e) => {
      e.preventDefault();
      axios
        .post(
          process.env.REACT_APP_API_ENDPOINT + '/api/workspaces',
          {
            name: workspaceNameToDB,
          },
          { new: true },
        )
        .then((result) => {
          setWorkspacenameToDb('');
        })
        .catch((err) => console.log(err));
      fetchWorkspaceData();
    },
    [workspaceNameToDB],
  );

  const handleOpenAddWorkspaceModal = (e) => {
    e.preventDefault();

    setAddWorkspaceMode(true);
  };

  const fetchWorkspaceData = () => {
    if (user.details.role === 'superadmin') {
      axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/workspaces').then((result) => {
        setWorkspaceData([...result.data]);
      });
    } else {
      axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/workspaces').then((result) => {
        const filteredUserWorkspace = result.data.filter(
          (workspace) => workspace.name === userWorkspace,
        );
        setWorkspaceData([...filteredUserWorkspace]);
      });
    }
  };

  useEffect(() => {
    fetchWorkspaceData();
  }, [addWorkspaceToDatabase]);

  if (!user.details) return <Spinner />;

  return (
    <div className='flex items-center justify-center h-screen bg-bgColor'>
      <div className='absolute top-10 left-10 text-white'>
        <div>
          <span className='font-bold'>User Role: </span>
          {user.details.role}
        </div>
        <div>
          <span className='font-bold'>Workspace: </span>
          {userWorkspace}
        </div>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col p-8 bg-white rounded shadow-md w-80 relative'
      >
        <legend className='absolute left-0 py-1 px-2 font-bold text-white rounded-t-lg bg-secondary -top-8'>
          Register
        </legend>

        <div className='flex flex-col'>
          <InputField
            register={register}
            errors={errors}
            label='Email'
            id='email'
            type='email'
            placeholder='Email'
            validation={{ required: 'Email Address is required' }}
          />

          <InputField
            register={register}
            errors={errors}
            label='Password'
            id='password'
            type='password'
            placeholder='******************'
            validation={{ required: 'Password is required' }}
          />

          <InputField
            register={register}
            errors={errors}
            label='Repeat Password'
            id='repeatPassword'
            type='password'
            placeholder='******************'
            validation={{
              required: 'Repeat Password is required',
              validate: (value) => value === password || 'The passwords do not match',
            }}
          />

          <InputField
            register={register}
            errors={errors}
            label='Name'
            id='firstName'
            type='text'
            placeholder='Name'
            validation={{ required: 'Name is required' }}
          />

          <label className='block mb-2 text-sm font-bold text-grey-darker' htmlFor='workspace'>
            Workspace:
          </label>
          <select
            className='w-full px-3 py-2 mb-4 border rounded shadow appearance-none text-grey-darker'
            {...register('workspace', {
              required: 'Workspace is required',
              defaultValue: 'DEFAULT',
            })}
          >
            <option disabled>-- select a workspace --</option>
            {workspaceData &&
              workspaceData.map((workspace) => (
                <option key={workspace._id} value={workspace.name}>
                  {workspace.name}
                </option>
              ))}
          </select>

          <div className='flex items-center justify-between mb-4'>
            <div className='flex items-center gap-2'>
              <label className='block text-sm font-bold text-grey-darker' htmlFor='isAdmin'>
                Admin:
              </label>
              <input
                {...register('isAdmin', {
                  defaultValue: false,
                })}
                type='checkbox'
              />
            </div>

            {user.details.role === 'superadmin' && (
              <button
                className='w-32 py-2 font-bold transition-all border-2 rounded-lg text-bgColor border-bgColor hover:text-white hover:bg-bgColor'
                onClick={handleOpenAddWorkspaceModal}
              >
                Add workspace
              </button>
            )}
          </div>

          {addWorkspaceMode && (
            <Modal>
              <dialog className='fixed inset-0 flex items-center justify-center w-full h-screen bg-black bg-opacity-30'>
                <div className='relative flex flex-col p-10 bg-white rounded-lg w-96'>
                  <label className='block mb-2 text-sm font-bold text-grey-darker' htmlFor='name'>
                    Workspace:
                  </label>
                  <div
                    onClick={(e) => setAddWorkspaceMode(false)}
                    className='absolute w-10 p-2 text-center transition-all bg-gray-100 rounded-full top-2 right-2 hover:bg-gray-200'
                  >
                    ✕
                  </div>
                  <input
                    type='text'
                    id='name'
                    placeholder='name'
                    value={workspaceNameToDB}
                    onChange={(e) => setWorkspacenameToDb(e.target.value)}
                    autoComplete='false'
                    className='w-full px-3 py-2 mb-2 border rounded shadow appearance-none text-grey-darker'
                  />
                  <div
                    className='px-4 py-2 mt-5 font-bold transition-all bg-white border-2 rounded hover:bg-secondary hover:text-white text-secondary border-secondary'
                    onClick={(e) => addWorkspaceToDatabase(e)}
                  >
                    Add workspace
                  </div>
                </div>
              </dialog>
            </Modal>
          )}

          <div className={'mb-4'}>
            {responseMessage && (
              <p
                className={`text-sm font-bold ${
                  responseMessage.type === 'error' ? ' text-red-900' : 'text-green-800'
                }`}
              >
                {responseMessage.message}
              </p>
            )}
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <button
            className='w-full px-4 py-2 font-bold transition-all bg-white border-2 rounded hover:bg-secondary hover:text-white text-secondary border-secondary'
            type='submit'
            disabled={isSubmitted}
          >
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
};

export default Register;
