import React, { useState, useEffect, useCallback } from 'react';
import { set, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { v4 as uuid } from 'uuid';

import InputField from '../components/UI/InputField';

const PrivateRegistraion = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const password = watch('password');

  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const history = useHistory();

  const onSubmit = async (data) => {
    setResponseMessage('');
    setIsSubmitted(true);

    try {
      const workspaceName = `workspace-${uuid()}`;

      const userSignup = axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/users/signup', {
        username: data.email,
        password: data.password,
        firstName: data.firstName,
        workspace: workspaceName,
        role: 'admin',
      });

      const workspaceCreation = axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/workspaces',
        { name: workspaceName },
        { new: true },
      );

      const [userResponse, workspaceResponse] = await Promise.all([userSignup, workspaceCreation]);

      if (userResponse.status === 200) {
        setResponseMessage({
          message: 'User created successfully. You will be redirected to login page shortly.',
          type: 'success',
        });

        setTimeout(() => {
          history.push('/login');
        }, 5000);
      }
    } catch (error) {
      setResponseMessage({ message: error.response.data.error, type: 'error' });
      setIsSubmitted(false);
    }
  };

  return (
    <div className='flex items-center justify-center h-screen bg-bgColor'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col p-8 bg-white rounded shadow-md w-80 relative'
      >
        <legend className='absolute left-0 py-1 px-2 font-bold text-white rounded-t-lg bg-secondary -top-8'>
          Private Registration
        </legend>

        <div className='flex flex-col'>
          <InputField
            register={register}
            errors={errors}
            label='Email'
            id='email'
            type='email'
            placeholder='Email'
            validation={{ required: 'Email Address is required' }}
          />

          <InputField
            register={register}
            errors={errors}
            label='Password'
            id='password'
            type='password'
            placeholder='******************'
            validation={{ required: 'Password is required' }}
          />

          <InputField
            register={register}
            errors={errors}
            label='Repeat Password'
            id='repeatPassword'
            type='password'
            placeholder='******************'
            validation={{
              required: 'Repeat Password is required',
              validate: (value) => value === password || 'The passwords do not match',
            }}
          />

          <InputField
            register={register}
            errors={errors}
            label='Name'
            id='firstName'
            type='text'
            placeholder='Name'
            validation={{ required: 'Name is required' }}
          />

          <div className={'mb-4'}>
            {responseMessage && (
              <p
                className={`text-sm font-bold ${
                  responseMessage.type === 'error' ? ' text-red-900' : 'text-green-800'
                }`}
              >
                {responseMessage.message}
              </p>
            )}
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <button
            className='w-full px-4 py-2 font-bold transition-all bg-white border-2 rounded hover:bg-secondary hover:text-white text-secondary border-secondary'
            type='submit'
            disabled={isSubmitted}
          >
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
};

export default PrivateRegistraion;
