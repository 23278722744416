import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// icons
import { HiX } from 'react-icons/hi';
import { IoDuplicateOutline } from 'react-icons/io5';
import { FaPen } from 'react-icons/fa';

import { IconButton } from '../UI';

// context
import { useFlowContext, useComboContext, useActionContext, useTrackContext } from '../../contexts';

const Track = ({ track, isBreakoutSessionSelected, breakoutSession }) => {
  const { flow, setFlow } = useFlowContext();
  const { setSelectedCombo, selectedCombo } = useComboContext();
  const { setSelectedActions, setActiveActionId, activeActionId, selectedActions } =
    useActionContext();
  const { selectedTrack, setSelectedTrack, setIsTrackExpanded, setSelectedTrackActionId } =
    useTrackContext();

  const isSelected = selectedTrack && selectedTrack.id === track.id;

  const borderColor = isBreakoutSessionSelected ? 'plum' : 'dark';
  const textColor = isSelected ? 'white' : isBreakoutSessionSelected ? 'plum' : 'dark';
  const bgColor = isSelected ? 'plum' : 'white';

  const [isEditing, setIsEditing] = useState(false);

  const handleSelectTrack = (t) => {
    setSelectedActions([breakoutSession.id]);
    setActiveActionId(breakoutSession.id);

    setSelectedCombo(null);

    setSelectedTrack(t);
    setIsTrackExpanded(true);
    setSelectedTrackActionId(null);
  };

  const handleDuplicateTrack = () => {
    if (flow.byId[breakoutSession.id].children.length < 6) {
      const trackCopy = { ...track };
      trackCopy.id = uuidv4();

      // Update the IDs of the children
      trackCopy.children = trackCopy.children.map((child) => {
        const childCopy = { ...child };
        childCopy.id = uuidv4();
        return childCopy;
      });

      setFlow((prevFlow) => {
        const updatedFlow = { ...prevFlow };
        const updatedBreakoutSession = { ...updatedFlow.byId[breakoutSession.id] };

        updatedBreakoutSession.children = [...updatedBreakoutSession.children, trackCopy];

        updatedFlow.byId[breakoutSession.id] = updatedBreakoutSession;

        return updatedFlow;
      });
    } else {
      window.alert('Maximum number of tracks reached.');
    }
  };

  const handleDeleteTrack = (e) => {
    e.stopPropagation();

    setFlow((prevFlow) => {
      const updatedFlow = { ...prevFlow };
      const updatedChildren = updatedFlow.byId[breakoutSession.id].children.filter(
        (t) => t.id !== track.id,
      );

      let confirmDelete;
      if (updatedChildren.length === 0) {
        confirmDelete = window.confirm(
          `Are you sure you want to delete ${track.name}? This is the last track.`,
        );
      } else {
        confirmDelete = window.confirm(`Are you sure you want to delete ${track.name}?`);
      }

      if (confirmDelete) {
        updatedFlow.byId[breakoutSession.id].children = updatedChildren;

        if (updatedChildren.length === 0) {
          updatedFlow.byId[breakoutSession.id].type = 'action';
        }

        setSelectedTrack(null);
        setIsTrackExpanded(false);
        setSelectedTrackActionId(null);

        return updatedFlow;
      }

      return prevFlow;
    });
  };

  const handleNameChange = (newName) => {
    setFlow((prevFlow) => {
      const updatedFlow = { ...prevFlow };
      const updatedBreakoutSession = { ...updatedFlow.byId[breakoutSession.id] };

      updatedBreakoutSession.children = updatedBreakoutSession.children.map((t) => {
        if (t.id === track.id) {
          return { ...t, name: newName };
        } else {
          return t;
        }
      });

      updatedFlow.byId[breakoutSession.id] = updatedBreakoutSession;

      return updatedFlow;
    });
  };

  return (
    <div
      className={`flex gap-1 border border-${borderColor} bg-${bgColor} text-sm text-${textColor} rounded-md px-1 cursor-pointer`}
      onClick={() => handleSelectTrack(track)}
    >
      {/* UPDATE TRACK NAME */}
      {isEditing && isSelected ? (
        <input
          type='text'
          value={track.name}
          onChange={(e) => handleNameChange(e.target.value)}
          className={`flex-1 bg-transparent outline-none`}
          onBlur={() => setIsEditing(false)}
        />
      ) : (
        <span className={`flex-1 bg-transparent outline-none`}>{track.name}</span>
      )}

      {/* CONTROLS */}
      {isSelected && (
        <>
          <IconButton icon={FaPen} tooltipText='Rename' onClick={() => setIsEditing(true)} />

          <IconButton
            icon={IoDuplicateOutline}
            tooltipText='Duplicate'
            onClick={handleDuplicateTrack}
          />

          <IconButton icon={HiX} tooltipText='Delete' onClick={(e) => handleDeleteTrack(e)} />
        </>
      )}
    </div>
  );
};

export default Track;
