import React, { useState, useEffect, useRef } from 'react';

import Menu from './Menu';
import { Menu as ComboMenu } from '../combo/Menu.js';
import StartTime from '../shared/StartTime';
import NameInput from './NameInput';
import DurationInput from './DurationInput';
import { DurationInput as ComboDurationInput } from '../combo/DurationInput';
import Area from '../area/Area';

import { useActionContext, useComboContext, useFlowContext, useTrackContext } from '../../contexts';

import { BiDotsVerticalRounded } from 'react-icons/bi';

const Action = ({ flowRef, action, index, parent, asDraggable = true }) => {
  const { flow, inputRefs } = useFlowContext();
  const { activeActionId, setActiveActionId, setSelectedActions, actionFormInputRefs } = useActionContext();
  const { setSelectedCombo } = useComboContext();

  const { setSelectedTrack, setSelectedTrackActionId } = useTrackContext();

  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

  const nameInputRef = useRef();
  const durationInputRef = useRef();

  const handleKeyDown = (e, field) => {
    const navigationKeys = ['ArrowDown', 'ArrowUp', 'Enter'];
    if (!navigationKeys.includes(e.key)) return;

    e.preventDefault();
    const flatFlow = getAllIdsInOrder().filter(id => id in inputRefs.current);
    const currentIndex = flatFlow.indexOf(action.id);
    let newIndex = getNewIndex(e.key, currentIndex);

    while (true) {
      if (newIndex < 0 || newIndex >= flatFlow.length) {
        actionFormInputRefs.current[field].current.focus();
      }

      // Skip non-editable input fields (e.g. combo duration)
      if (flow.byId[flatFlow[newIndex]]?.type === 'combo' && field !== 'name') {
        if (e.key === 'ArrowDown') {
          newIndex++;
        } else if (e.key === 'ArrowUp') {
          newIndex--;
        }
      } else {
        break;
      }
    }

    if (e.key === 'Enter') field = 'name';

    focusField(flatFlow, newIndex, field);
  };

  const getNewIndex = (key, currentIndex) => {
    if (key === 'ArrowDown' || key === 'Enter') return currentIndex + 1;
    if (key === 'ArrowUp') return currentIndex - 1;
  };

  const focusField = (flatFlow, newIndex, field) => {
    if (newIndex >= 0 && newIndex < flatFlow.length) {
      inputRefs.current[flatFlow[newIndex]][field].current.focus();

      if (flow.byId[flatFlow[newIndex]].type !== 'combo') {
        setSelectedCombo(null);
      } else {
        setSelectedCombo(flow.byId[flatFlow[newIndex]]);
      }

      setActiveActionId(flatFlow[newIndex]);
      setSelectedActions([flatFlow[newIndex]]);
    }
  };

  const getAllIdsInOrder = () => {
    let ids = [];

    flow.byOrder.forEach((id) => {
      const item = flow.byId[id];
      ids.push(item.id);

      // if (item.type === 'breakoutSession') {
      //   return item.children[0]?.children?.map(child => child.id);
      // }

      if (item?.type === 'combo') ids.push(item.children?.map((id) => id));
    });

    return ids.flat();
  };

  const handleActionClick = (action) => {
    if (action?.type !== 'combo') {
    //   console.log('ACTION CLICKED', action);

      setSelectedActions([action.id]);
      setActiveActionId(action.id);

      setSelectedCombo(null);
      setSelectedTrack(null);
      setSelectedTrackActionId(null);
    }
  };

  useEffect(() => {
    inputRefs.current[action.id] = {
      name: nameInputRef,
      duration: durationInputRef,
    };

    if (action.id !== activeActionId) {
      nameInputRef.current?.blur();
      durationInputRef.current?.blur();
    }

    return () => {
        delete inputRefs.current[action.id];
    }
  }, [action, activeActionId]);

  return (
    <div
      className={`${action?.type} grid grid-cols-[100px_minmax(200px,_1fr)_100px_20px] gap-4 p-2`}
      onClick={() => handleActionClick(action)}
    >
      {action?.type === 'action' ? (
        <div className='flex flex-col justify-between'>
          <StartTime id={action.id} duration={action.duration} />
          <Area element={action} type={'action'} />
        </div>
      ) : (
        <StartTime id={action.id} duration={action.duration} />
      )}
      <NameInput action={action} nameInputRef={nameInputRef} handleKeyDown={handleKeyDown} />

      {action?.type === 'combo' ? (
        <ComboDurationInput
          combo={action}
          durationInputRef={durationInputRef}
          handleKeyDown={handleKeyDown}
        />
      ) : (
        <DurationInput
          action={action}
          durationInputRef={durationInputRef}
          handleKeyDown={handleKeyDown}
        />
      )}
      <div
        className='flex items-center relative'
        onMouseEnter={() => setIsActionMenuOpen(true)}
        onMouseLeave={() => setIsActionMenuOpen(false)}
      >
        {isActionMenuOpen ? (
          action?.type === 'combo' ? 
            <ComboMenu action={action} parent={parent} setIsActionMenuOpen={setIsActionMenuOpen} />
            :
            <Menu action={action} parent={parent} setIsActionMenuOpen={setIsActionMenuOpen} />
        ) : (
          <BiDotsVerticalRounded className='cursor-pointer' size={25} />
        )}
      </div>
    </div>
  );
};

export default Action;
