import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { AiFillCaretRight } from 'react-icons/ai';

import Action from '../action/Action';
import { useFlowContext, useComboContext, useActionContext, useTrackContext } from '../../contexts';
import BreakoutSession from '../breakoutSession/BreakoutSession';
import DraggableItem from '../dnd/DraggableItem';

const Combo = ({ action, index, droppablePlaceholder }) => {
  const { flow, inputRefs } = useFlowContext();
  const { selectedCombo, setSelectedCombo, draggingCombo } = useComboContext();
  const { setSelectedActions, setActiveActionId, activeActionId, selectedActions } =
    useActionContext();
  const { setSelectedTrack, setSelectedTrackActionId } = useTrackContext();

  const [comboExpanded, setComboExpanded] = useState(true);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

  const toggleComboExpanded = () => {
    if (comboExpanded) {
      // Remove input refs
      action.children.forEach((child) => {
        delete inputRefs.current[child];
      });
    }

    setComboExpanded(!comboExpanded);
  };

  const handleComboClick = (e, combo) => {
    // console.log('handle combo click', combo.id);

    // e.stopPropagation();

    setSelectedCombo(combo);

    setSelectedTrack(null);
    setSelectedActions([]);
    setActiveActionId(null);
    setSelectedTrackActionId(null);
  };

  const handleComboChildrenClick = (e, action) => {
    // console.log('handle combo children click', action.id);

    e.stopPropagation();

    // setSelectedActions([action.id]);
    // setActiveActionId(action.id);

    // setSelectedCombo(null);
    // setSelectedTrack(null);
    // setSelectedTrackActionId(null);
  };

  // console.log('ACTIVE ACTION ID: ', activeActionId);
  // console.log('SELECTED ACTIONS: ', selectedActions);
  // console.log('SELECTED COMBO: ', selectedCombo);

  return (
    <>
      <div className='relative top-0 bg-lemon' onClick={(e) => handleComboClick(e, action)}>
        <div
          id='expand-combo-icon'
          className='absolute top-[9px] left-2 z-10 flex flex-col justify-center border border-l-[#c4c4c4] rounded-2xl bg-white h-[56px] text-plum'
          onClick={toggleComboExpanded}
        >
          <AiFillCaretRight
            id='expand-combo-icon'
            size={25}
            className={
              comboExpanded
                ? 'transform rotate-90 cursor-pointer z-10 ml-0 top-4'
                : 'cursor-pointer z-10 ml-0'
            }
          />
        </div>

        <Action action={action} />
      </div>
      <div
        className={`combo-items bg-diagonal-lemon-stripes !transform-none ${
          comboExpanded ? 'bg-lemon combo-expanded' : 'hidden'
        }`}
      >
        {action.children &&
          action.children.map((id, index) => {
            const draggable = flow.byId[id];
            if (!draggable) return;
            const ComponentMap = {
              combo: Combo,
              breakoutSession: BreakoutSession,
              action: Action,
            };
            const Component = ComponentMap[draggable.type];

            if (comboExpanded)
              return (
                <div onClick={(e) => handleComboChildrenClick(e, draggable)}>
                  <DraggableItem
                    key={draggable.id}
                    item={Component}
                    draggable={draggable}
                    index={index}
                    comboParent={true}
                  />
                </div>
              );
          })}
        {!draggingCombo && <div>{droppablePlaceholder}</div>}
      </div>
    </>
  );
};

export default Combo;
