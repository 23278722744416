import React from 'react';

//compontens
import Menu from './Menu';

// context
import { useFlowContext } from '../../contexts';

const Header = ({ setTimeDate }) => {
  const { user } = useFlowContext();

  return (
    // MAIN NAVIGATION
    <div className='flex justify-between h-20 px-16 text-white header-linear-gradient'>
      <div className='flex items-center gap-10 self-center'>
        <p className='text-3xl font-light tracking-widest drop-shadow-2xl'>
          <a href='/'>flowtomator</a>
        </p>
        <p className='text-lg font-light tracking-wider'>where great sessions unfold</p>
      </div>

      {user.details && <Menu setTimeDate={setTimeDate} />}
    </div>
  );
};

export default Header;
