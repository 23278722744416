import React, { useEffect, useRef, useState } from 'react';
import { BiStopwatch } from 'react-icons/bi';

import AddToLibraryButton from '../start/AddToLibraryButton';
import { secondsToMMMSS, formatDurationInput, timeToSeconds } from '../../helper/timeFormaters';
import { useActionContext } from '../../contexts';

const DurationInput = ({
  action,
  setAction,
  addAction,
  nameInputRef,
  durationInputRef,
  inputFieldDuration,
  setInputFieldDuration,
  handleKeyDown,
}) => {
  const { setActiveActionId, setSelectedActions } = useActionContext();
  const [timeIconVisible, setTimeiconVisible] = useState(true);
  // const [duration, setDuration] = useState(action.duration);

  let enterKeyPressed = false;

  const handleFocus = (event) => {
    setTimeiconVisible(false);
    event.target.select();
    event.target.placeholder = 'MM:SS';
    setActiveActionId(action.id);
    setSelectedActions([action.id]);
  };

  const handleChange = (event) => {
    const formattedDuration = formatDurationInput(event.target.value);
    setInputFieldDuration(formattedDuration);

    setAction({
      ...action,
      duration: timeToSeconds(formattedDuration),
    });
  };

  const handleBlur = (event) => {
    if (inputFieldDuration === '') setTimeiconVisible(true);
    event.target.placeholder = '';

    if (!enterKeyPressed) {
      const seconds = timeToSeconds(inputFieldDuration);
      setInputFieldDuration(secondsToMMMSS(seconds));
    } else {
      enterKeyPressed = !enterKeyPressed;
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      enterKeyPressed = true;
      addAction();
      setTimeiconVisible(true);
      nameInputRef.current.focus();
    }
  };


  return (
    <div className='relative'>
      {timeIconVisible && !action.duration && (
        <BiStopwatch
          onClick={(e) => {
            setTimeiconVisible(false);
            durationInputRef.current.focus();
          }}
          size='30'
          className='absolute text-gray-500 top-4 right-9'
        />
      )}
      <input
        ref={durationInputRef}
        type='text'
        name='duration'
        className='p-4 text-center text-black rounded-2xl border border-[#c4c4c4] shadow-lg w-[100px] outline-plum'
        value={inputFieldDuration}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onKeyDown={(e) => handleKeyDown(e, 'duration')}
        autoComplete='off'
      />
    </div>
  );
};

export default DurationInput;
