import React, { useState, useEffect, useCallback } from 'react';
import FlowContainerLibrary from '../components/library/FlowContainerLibrary';
import ActionContainerLibrary from '../components/library/ActionContainerLibrary';
import fireDb from '../firebase';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { BiArrowBack, BiSearch } from 'react-icons/bi';
import useStore from '../store/UserStore';

const Library = () => {
  const [flowData, setFlowData] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchInputInFocus, setSearchInputInFocus] = useState(false);
  const history = useHistory();
  const user = useStore((state) => state.user);

  useEffect(() => {
    document.title = `flowtomator - library`;
  }, []);

  const fetchFlowData = useCallback(() => {
    fireDb.child(user.details.workspace + '/flows').on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        const firebaseData = Object.keys(snapshot.val()).map(function (key) {
          return { ...snapshot.val()[key], key };
        });
        setFlowData([...firebaseData]);
      } else {
        setFlowData([]);
      }
    });
  }, [user.details.workspace]);

  const fetchActionData = useCallback(() => {
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + `/api/actions`, {
        params: { workspace: user.details.workspace },
      })
      .then((response) => {
        setActionData(response.data);
      });
  }, [user.details.workspace]);

  useEffect(() => {
    fetchFlowData();
    fetchActionData();
    const i = setInterval(() => {
      fetchActionData();
    }, [1000]);
    setTimeout(function () {
      clearInterval(i);
    }, 2000);

    return () => {
      setFlowData([]);
      setActionData([]);
    };
  }, [fetchFlowData, fetchActionData]);

  return (
    <div className='flex flex-col h-screen'>
      <header className='flex items-center justify-center h-20 bg-bgColor'>
        <div
          className='absolute flex items-center cursor-pointer left-3'
          onClick={(e) => history.goBack()}
        >
          <BiArrowBack size={28} color='white' />
        </div>
        <h1 className='text-lg font-bold text-center text-white'>
          {user.details?.workspace} – Library
        </h1>
      </header>
      <div className='relative self-center'>
        <input
          type='text'
          className='px-3 py-2 m-1 mt-5 border-2 rounded-lg outline-none w-96 hover:shadow-md focus:border-gray-300'
          placeholder={!searchInputInFocus ? 'search flows, actions and combos...' : ' '}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setSearchInputInFocus(true)}
          onBlur={() => setSearchInputInFocus(false)}
        />
        <BiSearch size={22} className='absolute text-gray-400 right-5 top-8' />
      </div>

      <div className='flex flex-1 mx-10 overflow-scroll'>
        <div className='flex flex-col flex-1'>
          <h4 className='p-2 text-2xl font-bold border-b-2'>Flows</h4>
          <FlowContainerLibrary
            flowData={flowData}
            setFlowData={setFlowData}
            searchQuery={searchQuery}
          />
        </div>
        <div className='flex flex-col flex-1 '>
          <h4 className='p-2 text-2xl font-bold border-b-2'>
            Actions {'&'} <span className='border-b-4 border-combo'>Combos</span>
          </h4>
          <ActionContainerLibrary
            actionData={actionData}
            setActionData={setActionData}
            searchQuery={searchQuery}
          />
        </div>
      </div>
    </div>
  );
};

export default Library;
