import React, { useState } from 'react';
import { useFlowContext } from '../../contexts';

const NameInput = ({ action, nameInputRef, handleKeyDown }) => {
  const { flow, setFlow } = useFlowContext();
  const [name, setName] = useState(action.name);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleBlur = (event) => {
    setFlow({
      ...flow,
      byId: {
        ...flow.byId,
        [action.id]: {
          ...action,
          name: name,
        },
      },
    });
  };

  return (
    <input
      ref={nameInputRef}
      type='text'
      name='name'
      className={`px-4 py-1 bg-gray-200 rounded-2xl outline-plum focus:bg-white`}
      value={name}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={(e) => handleKeyDown(e, 'name')}
    />
  );
};

export default NameInput;
