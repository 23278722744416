import React from 'react';

const ErrorMessage = ({ message }) => {
  return (
    <p role='alert' className='text-red-900 text-sm mt-1'>
      {message}
    </p>
  );
};

export default ErrorMessage;
