import React from "react";

import FlowDatePicker from "./FlowDatePicker";
import FlowName from "./FlowName";


const FlowHeader = () => {

  return (
    <div className='grid grid-cols-[235px_1fr] gap-4 p-2'>
      <FlowDatePicker />
      <FlowName />
    </div>
  )
}

export default FlowHeader;