import React, { useState } from 'react';
import { secondsToMMMSS, formatDurationInput, timeToSeconds } from '../../helper/timeFormaters';
import { useFlowContext } from '../../contexts';

const DurationInput = ({ action, durationInputRef, handleKeyDown }) => {
  const { flow, setFlow } = useFlowContext();

  const [duration, setDuration] = useState(secondsToMMMSS(action.duration));

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleChange = (event) => {
    const formattedDuration = formatDurationInput(event.target.value);
    setDuration(formattedDuration);
  };

  const handleBlur = () => {
    const seconds = timeToSeconds(duration);

    setDuration(secondsToMMMSS(seconds));

    setFlow({
      ...flow,
      byId: {
        ...flow.byId,
        [action.id]: {
          ...action,
          duration: seconds,
        },
      },
    });
  };

  return (
    <input
      ref={durationInputRef}
      className='w-[100px] p-4 text-center border border-[#c4c4c4] rounded-2xl outline-plum'
      type='text'
      name='duration'
      placeholder='MM:SS'
      // disabled={action?.type === 'combo'}
      value={duration}
      onFocus={handleFocus}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={(e) => handleKeyDown(e, 'duration')}
      autoComplete='off'
    />
  );
};

export default DurationInput;
