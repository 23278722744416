import React, { useContext, useState } from 'react';

const AppContext = React.createContext();

const ComboContextProvider = ({ children }) => {
  const [selectedCombo, setSelectedCombo] = useState(null);
  const [draggingCombo, setDraggingCombo] = useState(false);


  return (
    <AppContext.Provider
      value={{
        selectedCombo,
        setSelectedCombo,
        draggingCombo,
        setDraggingCombo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useComboContext = () => {
  return useContext(AppContext);
};

export { ComboContextProvider };
