import React, { useState, useEffect, memo, useRef } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const PdfViewer = memo(({ data, handlePdfPageTurn, largeNotes }) => {
  const [flexHeight, setFlexHeight] = useState(500);

  const [flexWidth, setFlexWidth] = useState(500);
  const pdfWrapper = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', setPdfSize);
    setPdfSize();
    return () => {
      window.removeEventListener('resize', setPdfSize);
    };
  }, []);

  useEffect(() => {
    setPdfSize();
  });

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      const width = pdfWrapper.current.getBoundingClientRect().width;
      const height = pdfWrapper.current.getBoundingClientRect().height;
      setFlexWidth(width);
      setFlexHeight(height);
      if (handlePdfPageTurn && height >= 350) {
        const ratio = height / 350;
        const newWidth = flexWidth / ratio;
        setFlexWidth(newWidth);
      }
    }
  };

  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll('.react-pdf__Page__annotations');
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.height = '0';
    });
  }

  const onLoadSuccess = (event) => {
    setPdfSize();
    removeTextLayerOffset();
  };

  const onKeyPress = (event) => {
    if (event.keyCode === 37) return handlePdfPageTurn(-1);
    if (event.keyCode === 39) return handlePdfPageTurn(1);
  };

  useEffect(() => {
    if (handlePdfPageTurn) {
      document.addEventListener('keydown', onKeyPress);
      return () => {
        document.removeEventListener('keydown', onKeyPress);
      };
    }
  }, []);

  return (
    <div className='z-50 flex flex-col justify-center w-full h-full'>
      <>
        <div ref={pdfWrapper} className='flex justify-center w-full h-full'>
          {' '}
          <Document file={data.url}>
            {handlePdfPageTurn ? (
              <Page
                // className='max-h-96'
                onLoadSuccess={onLoadSuccess}
                width={flexWidth}
                pageNumber={data.pageCurrent}
              />
            ) : (
              <Page height={flexHeight} pageNumber={data.pageCurrent} />
            )}
          </Document>
        </div>
      </>
      {handlePdfPageTurn && (
        <div className='flex flex-row items-center mt-2'>
          <button
            onClick={() => handlePdfPageTurn(-1)}
            className='px-4 py-2 font-bold text-white bg-gray-500 rounded-l-lg hover:bg-gray-400'
          >
            Prev
          </button>
          <button
            onClick={() => handlePdfPageTurn(1)}
            className='px-4 py-2 font-bold text-white bg-gray-500 rounded-r-lg hover:bg-gray-400'
          >
            Next
          </button>
          <p className='ml-2'>
            Page {data.pageCurrent} of {data.pageCount}
          </p>
        </div>
      )}
    </div>
  );
});
export default PdfViewer;
