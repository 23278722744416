import React from 'react';
import { useFlowContext } from '../../contexts';

const StartTime = ({ id, duration }) => {
  const { flow, timeDate } = useFlowContext();

  const calculateStartTime = () => {
    let startTime = Math.floor(timeDate.getTime() / 1000);

    // Go through flow.byOrder and add up the time until the ID is found
    outer: for (let i = 0; i < flow.byOrder.length; i++) {
      const item = flow.byId[flow.byOrder[i]];

      if (item.id === id) break;

      // if (item?.type === 'breakoutSession') {
      // for (let j = 0; j < item.children.length; j++) {
      //   const child = item.children[j];
      //   if (child.id === id) break outer;
      //   if (typeof child.duration === 'number') startTime += child.duration;
      // }
      // } else
      if (item?.type === 'combo' && item.children) {
        for (let j = 0; j < item.children.length; j++) {
          const child = flow.byId[item.children[j]];
          if (child?.id === id) break outer;
          if (child && typeof child.duration === 'number') startTime += child.duration;
        }
      } else if (typeof item.duration === 'number') {
        startTime += item.duration;
      }
    }

    const date = new Date(Math.floor(startTime * 1000));
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return (
    <div className='flex flex-row items-center justify-center border border-[#c4c4c4] rounded-2xl bg-white'>
      {calculateStartTime(duration)}
    </div>
  );
};

export default StartTime;
