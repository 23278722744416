import React, { useContext, useEffect, useState, useCallback, useMemo, useRef } from 'react';
import fireDb from '../firebase';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const AppContext = React.createContext();

const FlowContextProvider = ({ children, user }) => {
  const [flow, setFlow] = useState({ byOrder: [], byId: {}, live: {} });
  const [flowId, setFlowId] = useState(null);

  const [renderOrder, setRenderOrder] = useState([]);
  const [timeDate, setTimeDate] = useState(new Date());

  const [searchbarOpen, setSearchbarOpen] = useState(false);
  const [inputFieldText, setInputFieldText] = useState('');
  const [inputFieldDuration, setInputFieldDuration] = useState('');

  const [dragging, setDragging] = useState(false);

  const userWorkspace = user?.details?.workspace;

  const history = useHistory();

  const inputRefs = useRef({});

  useEffect(() => {
    // if (!flowId || !userWorkspace) {
    if (!flowId) {
      return;
    }

    // Listen for real-time updates
    const flowRef = fireDb.child(userWorkspace + '/flows/' + flowId);
    flowRef.on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        const firebaseData = snapshot.val();

        if (firebaseData.byId) {
          Object.entries(firebaseData.byId).forEach(([key, value]) => {
            firebaseData.byId[key] = {
              ...value,
              id: key,
            };
          });
        }

        console.log('Firebase Data:', firebaseData)

        try {
          setFlow({
            ...firebaseData,
            areas: firebaseData.areas || [],
            byId: firebaseData.byId || {},
            byOrder: firebaseData.byOrder || [],
            live: firebaseData.live || {},
          });
        } catch (e) {
          console.log('ERROR', e);
        }

        firebaseData.startingDate && setTimeDate(new Date(firebaseData.startingDate));
      } else {
        history.push('/start');
      }
    });

    // Cleanup the listener on unmount or when dependencies change
    return () => {
      flowRef.off();
    };
  }, [flowId, userWorkspace, history]);

  const saveFlowInDb = useCallback(() => {
    if (flowId) {
      const updatedFlow = { ...flow };
      const updates = {};
      updates[userWorkspace + '/flows/' + flowId] = updatedFlow;
      fireDb.update(updates);
      fireDb
        .child(userWorkspace + '/flows/')
        .once('value')
        .then((snapshot) => {
          const data = snapshot.val();
        });
    }
  }, [flow, userWorkspace]);

  useEffect(() => {
    saveFlowInDb();
  }, [flow, userWorkspace, saveFlowInDb]);

  // update document title to flow name
  useEffect(() => {
    document.title = `${flow?.name ? flow.name : 'unnamed flow 🚀'}`;
  }, [flow.name]);

  useMemo(() => {
    if (flow.byOrder) {
      return flow.byOrder
        .map((id) => {
          const children = flow.byId[id]?.children;
          const obj = children?.map((child) => {
            return { id: child, parentId: id };
          });
          // return children && expanded.indexOf(id) !== -1 ? [{ id }].concat(obj) : { id };
        })
        .flat();
    }
    return [];
  }, [flow]);
  // }, [expanded, flow]);

  return (
    <AppContext.Provider
      value={{
        user,
        userWorkspace,

        flow,
        setFlow,
        flowId,
        setFlowId,
        saveFlowInDb,
        inputRefs,

        renderOrder,
        setRenderOrder,

        timeDate,
        setTimeDate,

        dragging,
        setDragging,

        searchbarOpen,
        setSearchbarOpen,

        inputFieldText,
        setInputFieldText,
        inputFieldDuration,
        setInputFieldDuration,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useFlowContext = () => {
  return useContext(AppContext);
};

export { FlowContextProvider };
