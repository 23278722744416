import React from 'react';

function LargeButton({ onClick, content, reference }) {
  return (
    <button
      onClick={onClick}
      htmlFor='file-upload'
      ref={reference}
      className='flex content-center justify-center w-full p-1 font-bold text-white transition-colors bg-gray-800 border-2 border-gray-800 rounded-lg hover:bg-white hover:text-gray-800 hover:border-gray-800 hover:shadow-lg'
    >
      <p>{content}</p>
    </button>
  );
}
export default LargeButton;
