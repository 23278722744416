import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'react-tooltip';

import { useAppContext, useComboContext, useFlowContext, useActionContext } from '../../contexts';

import NameInput from './NameInput';
import DurationInput from './DurationInput';
import AddToLibraryButton from '../start/AddToLibraryButton';
import StartTime from '../shared/StartTime';

const ActionForm = () => {
  const { notesVisible, setNotesVisible } = useAppContext();
  const {
    flow,
    setFlow,
    setSearchbarOpen,
    inputFieldText,
    inputFieldDuration,
    setInputFieldText,
    setInputFieldDuration,
    inputRefs,
  } = useFlowContext();
  const { selectedCombo } = useComboContext();
  const {
    inputCustomFields,
    selectedActions,
    setSelectedActions,
    activeActionId,
    setActiveActionId,
    actionFormInputRefs,
  } = useActionContext();

  const [notification, setNotification] = useState('');

  const nameInputRef = useRef();
  const durationInputRef = useRef();

  useEffect(() => {
    actionFormInputRefs.current = {
      name: nameInputRef,
      duration: durationInputRef,
    };
  });

  const [action, setAction] = useState({
    id: uuidv4(),
    name: '',
    duration: '',
    inputCustomFields: {},
    type: 'action',
  });

  const addAction = () => {
    setFlow({
      ...flow,
      byId: {
        ...flow.byId,
        [action.id]: {
          ...action,
          inputCustomFields: inputCustomFields,
        },
      },
      byOrder: [...flow.byOrder, action.id],
    });

    setAction({
      id: uuidv4(),
      name: '',
      duration: '',
      inputCustomFields: {},
      type: 'action',
    });

    setInputFieldText('');
    setInputFieldDuration('');
    setSearchbarOpen(false);
  };

  const handleKeyDown = (e, field) => {
    if (flow.byOrder.length === 0) return;

    const flatFlow = getAllIdsInOrder().filter((id) => id in inputRefs.current);
    let newIndex;

    if (e.key === 'ArrowDown') newIndex = 0;
    if (e.key === 'ArrowUp') newIndex = flatFlow.length - 1;

    while (true) {
      // Skip non-editable input fields (e.g. combo duration)
      if (flow.byId[flatFlow[newIndex]]?.type === 'combo' && field !== 'name') {
        if (e.key === 'ArrowDown') {
          newIndex++;
        } else if (e.key === 'ArrowUp') {
          newIndex--;
        }
      } else {
        break;
      }
    }

    focusField(flatFlow, newIndex, field);
  };

  const focusField = (flatFlow, newIndex, field) => {
    if (newIndex >= 0 && newIndex < flatFlow.length) {
      inputRefs.current[flatFlow[newIndex]][field].current.focus();
      setActiveActionId(flatFlow[newIndex]);
      setSelectedActions([flatFlow[newIndex]]);
    }
  };

  const getAllIdsInOrder = () => {
    let ids = [];

    flow.byOrder.forEach((id) => {
      const item = flow.byId[id];
      if (item.id) ids.push(item.id);

      // if (item.type === 'breakoutSession') {
      //   return item.children[0]?.children?.map(child => child.id);
      // }

      if (item?.type === 'combo') ids.push(item.children?.map((id) => id));
    });

    return ids.flat();
  };

  return (
    <div className='grid grid-cols-[100px_minmax(200px,_1fr)_100px_20px] gap-4 p-2 relative'>
      <StartTime />
      <NameInput
        action={action}
        setAction={setAction}
        addAction={addAction}
        nameInputRef={nameInputRef}
        inputFieldText={inputFieldText}
        setInputFieldText={setInputFieldText}
        handleKeyDown={handleKeyDown}
      />
      <DurationInput
        action={action}
        setAction={setAction}
        addAction={addAction}
        nameInputRef={nameInputRef}
        durationInputRef={durationInputRef}
        inputFieldDuration={inputFieldDuration}
        setInputFieldDuration={setInputFieldDuration}
        handleKeyDown={handleKeyDown}
      />

      {/* NOTES */}
      {((Object.keys(flow.byId).length > 0 && activeActionId && selectedActions.length === 1) ||
        selectedCombo) && (
        <>
          <div
            onClick={() => setNotesVisible(!notesVisible)}
            className={`flex items-center justify-center rounded-2xl border border-[#c4c4c4] cursor-pointer ${
              notesVisible
                ? 'bg-plum text-white hover:bg-white hover:text-plum'
                : 'bg-white text-plum hover:bg-plum hover:text-white'
            }`}
          >
            <div
              className={`[writing-mode:vertical-lr] text-sm`}
              data-tooltip-id='details-tooltip'
              data-tooltip-content={notesVisible ? 'Hide Details' : 'Show Details'}
            >
              Details
            </div>
            <Tooltip id='details-tooltip' />
          </div>
        </>
      )}

      {inputFieldText && inputFieldDuration && (
        <AddToLibraryButton notification={notification} setNotification={setNotification} />
      )}
    </div>
  );
};

export default ActionForm;
