import React from 'react';
import moment from 'moment';
import axios from 'axios';
import fireDb from '../../firebase';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/lab';
import { TextField } from '@mui/material';

// context
import { useAppContext } from '../../contexts';
import { useFlowContext } from '../../contexts';

const DatePicker = () => {
  const { socket } = useAppContext();
  const { user, flow, setFlow, flowId, timeDate, setTimeDate } = useFlowContext();

  const updateDatabase = (e) => {
    if (moment(e).isValid()) {
      const updatedFlow = { ...flow };
      updatedFlow.startingDate = new Date(e).toISOString();
      setFlow(updatedFlow);

      if (flowId) {
        const updates = {};
        updates[user.details.workspace + '/flows/' + flowId + '/startingDate'] = new Date(
          e,
        ).toISOString();

        let workspace = user.details.workspace;

        socket.emit('scheduleFlow', { 
          workspace, 
          flowId,
          updatedFlow,
        });

        fireDb.update(updates);
      }
    }
    return;
  };

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DateTimePicker
        label='Start Date & Time'
        renderInput={(props) => (
          <TextField {...props} onBlur={(e) => updateDatabase(new Date(e.target.value))} />
        )}
        showToolbar={true}
        ampm={false}
        value={timeDate}
        inputFormat='DD/MM/YYYY HH:mm'
        onChange={(e) => {
          setTimeDate(new Date(e));
          updateDatabase(e);
        }}
        onAccept={(e) => {
          setTimeDate(new Date(e));
          updateDatabase(e);
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
