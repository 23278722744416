import React, { useState } from "react";

import DatePicker from "../start/DateTimePicker";
import { AnimatePresence, motion } from "framer-motion";

const FlowDatePicker = () => {
  const [timeDatePickerVisible, setTimeDatePickerVisible] = useState(false);

  return (
    <div
      className='bg-white rounded-2xl'
      onMouseEnter={(e) => setTimeDatePickerVisible(true)}
      onMouseLeave={(e) => setTimeDatePickerVisible(false)}
    >
      <DatePicker/>
      <AnimatePresence>
        {timeDatePickerVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.1 }}
            exit={{ opacity: 0 }}
          ></motion.div>
        )}
      </AnimatePresence>
    </div>
  )
};

export default FlowDatePicker;