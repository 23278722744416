import React, { useState, useEffect } from 'react';

import fireDb from '../../../firebase';
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';

import AddCustomFieldForm from './AddCustomFieldForm';
import CustomField from './CustomField';
import TrackActionCustomField from './TrackActionCustomField';
import LargeButton from '../LargeButton';

import { findTrackById } from '../../../helper/helperFunctions';

import setMiroLink from '../../../helper/setMiroLink';

// context
import {
  useFlowContext,
  useComboContext,
  useActionContext,
  useTrackContext,
} from '../../../contexts';

const Notes = ({ byIdDbPath }) => {
  const { userWorkspace, flow, setFlow, flowId } = useFlowContext();

  const { selectedCombo, setSelectedCombo } = useComboContext();
  const { activeActionId, inputCustomFields, setInputCustomFields } = useActionContext();
  const { selectedTrack, setSelectedTrack, selectedTrackActionId, setSelectedTrackActionId } =
    useTrackContext();

  const isCombo = flow.byId[activeActionId]?.type === 'combo';
  const isComboAction = !flow.byOrder.includes(activeActionId);

  const [selectedTrackActionItem, setSelectedTrackActionItem] = useState(null);

  const [newFieldActive, setNewFieldActive] = useState(false);

  const [actionFields, setActionFields] = useState({});

  useEffect(() => {
    const fields = {};

    fields.description = flow.byId[activeActionId]?.fields?.description || '';

    flow.custom_fields?.forEach((field) => {
      fields[field.name] = flow.byId[activeActionId]?.fields?.[field.name] || '';
    });

    setActionFields(fields);
  }, [activeActionId]);

  const handleKeyUp = (e) => {
    if (!(activeActionId in flow.byId)) return;

    const copyFlow = { ...flow };
    copyFlow.byId[activeActionId].fields = {
      ...copyFlow.byId[activeActionId].fields,
      [e.target.name]: e.target.value,
    };
    setFlow(copyFlow);

    if (flowId && activeActionId !== '') {
      let updates = {};
      updates[byIdDbPath + '/fields/' + e.target.name] = e.target.value;
      fireDb.update(updates);
    }
  };

  const handleChange = (e) => {
    if (activeActionId !== '') {
      const { name, value } = e.target;

      setActionFields({
        ...actionFields,
        [name]: value,
      });
    } else {
      let fields = {};
      fields[e.target.name] = e.target.value;
      setInputCustomFields({ ...inputCustomFields, ...fields });
    }
  };

  const handleChangeDropdown = (e) => {
    const copyFlow = { ...flow };

    copyFlow.byId[activeActionId]['fields'] = {
      ...copyFlow.byId[activeActionId].fields,
      [e.target.name]: e.target.value,
    };

    setFlow(copyFlow);
  };

  const deleteCustomField = (fieldName) => {
    const copyFlow = { ...flow };
    const updatedCopyCustomFields = [...flow.custom_fields].filter(
      (field) => field.name !== fieldName,
    );
    copyFlow.custom_fields = updatedCopyCustomFields;

    Object.keys(copyFlow.byId).forEach((actionId) => {
      if (copyFlow.byId[actionId].fields?.hasOwnProperty(fieldName)) {
        delete copyFlow.byId[actionId].fields[fieldName];
      }
    });

    setFlow(copyFlow);

    if (flowId) {
      let updates = {};
      updates[`${userWorkspace}/flows/${flowId}`] = copyFlow;
      fireDb.update(updates);
    }
  };

  const addCustomField = (name, type, size) => {
    // Check if a field with the given name already exists
    if (flow.custom_fields && flow.custom_fields.some((field) => field.name === name)) {
      alert('A custom field with this name already exists.');
      return;
    }

    const updatedFlow = { ...flow };
    updatedFlow.custom_fields = [...[...(flow.custom_fields || [])], { name, type, size }];
    setFlow(updatedFlow);

    if (flowId) {
      let updates = {};
      updates[`${userWorkspace}/flows/${flowId}/custom_fields`] = [
        ...[...(flow.custom_fields || [])],
        { name, type, size },
      ];
      fireDb.update(updates);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const copyFlow = { ...flow };
    const [removed] = copyFlow.custom_fields.splice(result.source.index, 1);
    copyFlow.custom_fields.splice(result.destination.index, 0, removed);
    setFlow(copyFlow);
    if (flowId) {
      let updates = {};
      updates[`${userWorkspace}/flows/${flowId}/custom_fields`] = copyFlow.custom_fields;
      fireDb.update(updates);
    }
  };

  // Handle add description to Track and Track Action
  const handleAddDescription = (e, element) => {
    setFlow((prevFlow) => {
      const copyFlow = { ...prevFlow };
      const copyAction = { ...copyFlow.byId[selectedCombo ? selectedCombo.id : activeActionId] };

      const { copyTrack, copyTrackIndex } = findTrackById(copyAction.children, selectedTrack.id);

      if (element === 'track') {
        copyTrack.fields = { ...copyTrack.fields, [e.target.name]: e.target.value };
        setSelectedTrack(copyTrack);
      } else if (element === 'trackAction') {
        const updatedTrackActionIndex = copyTrack.children.findIndex(
          (child) => child.id === selectedTrackActionId,
        );

        const copyTrackAction = { ...copyTrack.children[updatedTrackActionIndex] };
        copyTrackAction.fields = {
          ...copyTrackAction.fields,
          [e.target.name]: e.target.value,
        };

        copyTrack.children[updatedTrackActionIndex] = copyTrackAction;
        setSelectedTrackActionItem(copyTrackAction);
      }

      copyAction.children[copyTrackIndex] = copyTrack;
      copyFlow.byId[activeActionId] = copyAction;

      return copyFlow;
    });
  };

  // Handle add custom field for Track and Track Action
  const handleAddCustomField = (name, type, size, element) => {
    setFlow((prevFlow) => {
      const copyFlow = { ...prevFlow };
      const copyAction = { ...copyFlow.byId[selectedCombo ? selectedCombo.id : activeActionId] };

      const customField = { name, type, size };

      const { copyTrack, copyTrackIndex } = findTrackById(copyAction.children, selectedTrack.id);

      if (element === 'track') {
        copyTrack.fields = {
          ...copyTrack?.fields,
          custom_fields: [...(copyTrack?.fields?.custom_fields || []), customField],
        };
        setSelectedTrack(copyTrack);
      } else if (element === 'trackAction') {
        const copyTrackActionIndex = copyTrack.children.findIndex(
          (child) => child.id === selectedTrackActionId,
        );

        const copyTrackAction = { ...copyTrack.children[copyTrackActionIndex] };
        copyTrackAction.fields = {
          ...copyTrackAction.fields,
          custom_fields: [...(copyTrackAction.fields?.custom_fields || []), customField],
        };

        copyTrack.children[copyTrackActionIndex] = copyTrackAction;
        setSelectedTrackActionItem(copyTrackAction);
      }

      copyAction.children[copyTrackIndex] = copyTrack;
      copyFlow.byId[activeActionId] = copyAction;

      return copyFlow;
    });
  };

  // Handle update custom field for Track and Track Action
  const handleCustomFieldChange = (name, value, element) => {
    setFlow((prevFlow) => {
      const copyFlow = { ...prevFlow };
      const copyAction = { ...copyFlow.byId[selectedCombo ? selectedCombo.id : activeActionId] };

      let updatedItem;
      let updatedItemIndex;

      const updateCustomFields = (item, elementType) => {
        const customFields = (item.fields?.custom_fields || []).map((field) => {
          if (field.name === name) {
            switch (field.type) {
              case 'checkbox':
                return { ...field, checked: value };
              case 'select':
                if (!field.options || !field.options.includes(value)) {
                  return {
                    ...field,
                    options: [...(field.options || []), value],
                    selectedOption: value,
                  };
                }
                return { ...field, selectedOption: value };
              default:
                return { ...field, value: value };
            }
          }
          return field;
        });
        item.fields = { ...(item.fields || {}), custom_fields: customFields };
      };

      const { copyTrack, copyTrackIndex } = findTrackById(copyAction.children, selectedTrack.id);

      if (element === 'track') {
        updatedItem = copyTrack;
        updateCustomFields(updatedItem, 'track');
        setSelectedTrack(updatedItem);
      } else if (element === 'trackAction') {
        const updatedTrackActionIndex = copyTrack.children.findIndex(
          (child) => child.id === selectedTrackActionId,
        );
        updatedItem = copyTrack.children[updatedTrackActionIndex];
        updatedItemIndex = updatedTrackActionIndex;
        updateCustomFields(updatedItem, 'trackAction');
        setSelectedTrackActionItem(updatedItem);
        copyTrack.children[updatedItemIndex] = updatedItem;
      }

      copyAction.children[copyTrackIndex] = copyTrack;
      copyFlow.byId[activeActionId] = copyAction;

      return copyFlow;
    });
  };

  // Handle delete custom field for Track and Track Action
  const handleDeleteCustomField = (fieldName, element) => {
    setFlow((prevFlow) => {
      const copyFlow = { ...prevFlow };

      const copyAction = { ...copyFlow.byId[selectedCombo ? selectedCombo.id : activeActionId] };

      const { copyTrack, copyTrackIndex } = findTrackById(copyAction.children, selectedTrack.id);
      let updatedCustomFields;

      if (element === 'track') {
        updatedCustomFields = (copyTrack.fields?.custom_fields || []).filter(
          (field) => field.name !== fieldName,
        );
        copyTrack.fields = {
          ...(copyTrack.fields || {}),
          custom_fields: updatedCustomFields,
        };
        setSelectedTrack(copyTrack);
      } else if (element === 'trackAction') {
        const copyTrackActionIndex = copyTrack.children.findIndex(
          (child) => child.id === selectedTrackActionId,
        );
        const copyTrackAction = { ...copyTrack.children[copyTrackActionIndex] };
        updatedCustomFields = (copyTrackAction.fields?.custom_fields || []).filter(
          (field) => field.name !== fieldName,
        );
        copyTrackAction.fields = {
          ...(copyTrackAction.fields || {}),
          custom_fields: updatedCustomFields,
        };
        copyTrack.children[copyTrackActionIndex] = copyTrackAction;
        setSelectedTrackActionItem(copyTrackAction);
      }

      copyAction.children[copyTrackIndex] = copyTrack;
      copyFlow.byId[activeActionId] = copyAction;

      return copyFlow;
    });
  };

  // Render description element
  function renderDescription(changeHandler, value) {
    return (
      <div className='flex flex-col w-full mb-2'>
        <label htmlFor='decription' className='font-bold text-white'>
          Description
        </label>
        <textarea
          value={value || ''}
          onChange={changeHandler}
          id='description'
          name='description'
          rows={3}
          className='p-2 my-2 min-h-[8vh] border-2 rounded-2xl border-gray-200 outline-none resize-none hover:shadow-lg focus:border-plum'
        ></textarea>
      </div>
    );
  }

  function handleOnDragEndCustomFieldTrack(result) {
    if (!result.destination) {
      return;
    }

    setFlow((prevFlow) => {
      const copyFlow = { ...prevFlow };
      const copyAction = { ...copyFlow.byId[activeActionId] };
      const { copyTrack, copyTrackIndex } = findTrackById(copyAction.children, selectedTrack.id);

      const customFields = Array.from(copyTrack.fields.custom_fields);
      const [draggedItem] = customFields.splice(result.source.index, 1);
      customFields.splice(result.destination.index, 0, draggedItem);
      copyTrack.fields.custom_fields = customFields;

      setSelectedTrack(copyTrack);

      return copyFlow;
    });
  }

  function handleOnDragEndCustomFieldTrackAction(result) {
    if (!result.destination) {
      return;
    }

    setFlow((prevFlow) => {
      const copyFlow = { ...prevFlow };
      const copyAction = { ...copyFlow.byId[activeActionId] };
      const { copyTrack, copyTrackIndex } = findTrackById(copyAction.children, selectedTrack.id);
      const updatedTrackActionIndex = copyTrack.children.findIndex(
        (child) => child.id === selectedTrackActionId,
      );
      const copyTrackAction = { ...copyTrack.children[updatedTrackActionIndex] };

      const customFields = Array.from(copyTrackAction.fields.custom_fields);
      const [draggedItem] = customFields.splice(result.source.index, 1);
      customFields.splice(result.destination.index, 0, draggedItem);
      copyTrackAction.fields.custom_fields = customFields;

      console.log(draggedItem);

      copyTrack.children[updatedTrackActionIndex] = copyTrackAction;
      setSelectedTrackActionItem(copyTrackAction);

      return copyFlow;
    });
  }

  // Render custom fields
  function renderCustomFields(fields, element, deleteCustomField, handleChange) {
    return (
      <DragDropContext
        onDragEnd={
          element === 'track'
            ? handleOnDragEndCustomFieldTrack
            : handleOnDragEndCustomFieldTrackAction
        }
      >
        <Droppable droppableId='custom_fields'>
          {(provided, snapshot) => (
            <div
              className={`flex flex-wrap gap-4 mt-4 mb-4 ${
                snapshot.isDraggingOver ? 'bg-gray-100' : ''
              }`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fields.map((field, index) => (
                <Draggable key={field.name} draggableId={field.name} index={index}>
                  {(provided, snapshot) => (
                    <TrackActionCustomField
                      field={field}
                      deleteCustomField={deleteCustomField}
                      handleChange={handleChange}
                      element={element}
                      provided={provided}
                      dragHandleProps={provided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  // Render custom element form
  function renderAddCustomFieldForm(addCustomField, setNewFieldActive, element) {
    return (
      <>
        {newFieldActive && (
          <AddCustomFieldForm
            addCustomField={addCustomField}
            setNewFieldActive={setNewFieldActive}
            element={element}
          />
        )}
        <div className='flex justify-between pb-1 space-x-1'>
          <LargeButton
            onClick={(e) => setNewFieldActive((prev) => !prev)}
            content={newFieldActive ? '–' : '+'}
          />
        </div>
      </>
    );
  }

  // Set currently selected track action item
  useEffect(() => {
    for (let i = 0; i < flow.byOrder.length; i++) {
      const currAction = flow.byId[flow.byOrder[i]];
      if (currAction.id === activeActionId) {
        for (let j = 0; j < currAction.children?.length; j++) {
          const currTrack = currAction.children[j];
          for (let y = 0; y < currTrack.children?.length; y++) {
            const currTrackAction = currTrack.children[y];
            if (currTrackAction.id === selectedTrackActionId) {
              setSelectedTrackActionItem(currTrackAction);
            }
          }
        }
      }
    }
  }, [selectedTrackActionId, activeActionId, flow]);

  // Handle case when TRACK is selected
  if (selectedTrack && !selectedTrackActionId) {
    return (
      <div id='notes' className='w-full p-4 rounded-sm bg-plum overflow-auto'>
        {renderDescription(
          (e) => handleAddDescription(e, 'track'),
          selectedTrack?.fields?.['description'],
        )}

        <div className='flex flex-col'>
          {selectedTrack?.fields?.custom_fields &&
            renderCustomFields(
              selectedTrack?.fields?.custom_fields,
              'track',
              handleDeleteCustomField,
              handleCustomFieldChange,
            )}

          {renderAddCustomFieldForm(handleAddCustomField, setNewFieldActive, 'track')}
        </div>
      </div>
    );
  }

  // Handle case when TRACK ACTION is selected
  if (selectedTrackActionId) {
    return (
      <div id='notes' className='w-full p-4 rounded-sm bg-red-300 overflow-auto'>
        {renderDescription(
          (e) => handleAddDescription(e, 'trackAction'),
          selectedTrackActionItem?.fields?.['description'],
        )}

        <div className='flex flex-col'>
          {selectedTrackActionItem?.fields?.custom_fields &&
            renderCustomFields(
              selectedTrackActionItem.fields.custom_fields,
              'trackAction',
              handleDeleteCustomField,
              handleCustomFieldChange,
            )}

          {renderAddCustomFieldForm(handleAddCustomField, setNewFieldActive, 'trackAction')}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        id='notes'
        className={`w-full p-4 rounded-sm overflow-auto ${
          isCombo || isComboAction ? 'bg-lemon' : 'bg-[#f5e8ec]'
        }`}
      >
        <div className='flex flex-col w-full mb-2'>
          <label htmlFor='decription' className='font-bold text-gray-800'>
            Description
          </label>
          <textarea
            value={actionFields.description || ''}
            onChange={handleChange}
            id='description'
            name='description'
            onKeyUp={handleKeyUp}
            rows={3}
            className='p-2 my-2 min-h-[8vh] border-2 rounded-2xl border-gray-200 outline-none resize-none hover:shadow-lg focus:border-plum'
          ></textarea>

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='notes'>
              {(provided, snapshot) => (
                <div
                  className={`flex flex-wrap gap-4 mt-4 mb-4 ${
                    snapshot.isDraggingOver ? 'bg-gray-100' : ''
                  }`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {flow.custom_fields?.map((field, index) => {
                    return (
                      <CustomField
                        byIdDbPath={byIdDbPath}
                        field={field}
                        key={`${field.name}-${index}`}
                        deleteCustomField={deleteCustomField}
                        handleChange={handleChange}
                        handleChangeDropdown={handleChangeDropdown}
                        actionFields={actionFields}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {newFieldActive && (
            <AddCustomFieldForm
              addCustomField={addCustomField}
              setNewFieldActive={setNewFieldActive}
            />
          )}

          <div className='flex justify-between pb-1 space-x-1'>
            <LargeButton
              onClick={(e) => setNewFieldActive((prev) => !prev)}
              content={newFieldActive ? '–' : '+'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notes;
