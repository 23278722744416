import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import string_to_slug from '../../helper/stringToSlug';
import fireDb from '../../firebase';
import { BiTrash } from 'react-icons/bi';
import DeleteCheckOverlay from './../DeleteCheckOverlay';
import useStore from '../../store/UserStore';
import { Modal } from './../Modal';

const FlowItemLibrary = ({ flow, flowData, setFlowData }) => {
  const [deleteCheck, setDeleteCheck] = useState(false);
  const history = useHistory();
  const user = useStore((state) => state.user);
  const userWorkspace = user.details.workspace;

  const deleteFlow = (e) => {
    e.preventDefault();
    fireDb.child(userWorkspace + '/flows/' + flow.key).remove();
    const updatedFlow = [...flowData].filter((el) => el.key !== flow.key);
    setFlowData(updatedFlow);
    history.push('/library');
  };
  return (
    <div className='flex justify-between px-3 py-1 border-b'>
      <Link to={`/flow/${flow.key}/${string_to_slug(flow.name)}`}>
        <h3>{flow.name}</h3>
      </Link>
      <button className='p-0.5 hover:bg-gray-200 rounded-full'>
        <BiTrash size={24} className='text-red-900' onClick={(e) => setDeleteCheck(true)} />
      </button>
      {deleteCheck && (
        <Modal>
          <DeleteCheckOverlay
            action={flow}
            setDeleteCheck={setDeleteCheck}
            setIsActionMenuOpen={null}
            deleteAction={deleteFlow}
          />
        </Modal>
      )}
    </div>
  );
};

export default FlowItemLibrary;
