import React, { useState } from 'react';

import fireDb from '../../firebase';
import { useDebouncedCallback } from 'use-debounce';
import axios from 'axios';

import SearchResults from '../../components/start/SearchResults';

import { useFlowContext, useAppContext, useActionContext } from '../../contexts';

const NameInput = ({
  nameInputRef,
  action,
  setAction,
  addAction,
  inputFieldText,
  setInputFieldText,
  handleKeyDown,
}) => {
  const { flow, setFlow, userWorkspace, renderOrder, searchbarOpen, setSearchbarOpen } =
    useFlowContext();
  const { setActiveActionId, setSelectedActions } = useActionContext();

  const [flowResultsData, setFlowResultsData] = useState([]);
  const [actionResultsData, setActionResultsData] = useState([]);

  const [cursor, setCursor] = useState(-1);

  const handleFocus = () => {
    setActiveActionId(action.id);
    setSelectedActions([action.id]);
  }

  const handleChange = (e) => {
    setInputFieldText(e.target.value);
    setAction({ ...action, name: e.target.value });

    if (e.target.value.length > 0) {
      setSearchbarOpen(true);
    } else {
      setSearchbarOpen(false);
    }

    if (searchbarOpen) {
      debounced();
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      addAction();
    }
  };

  // FETCH FLOW AND ACTION SUGGESSTIONS
  const debounced = useDebouncedCallback(() => {
    fireDb
      .child(userWorkspace + '/flows')
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const ResultData = Object.keys(snapshot.val())
            .map((key) => ({ ...snapshot.val()[key], key }))
            .filter((item) => item?.name?.includes(inputFieldText));
          setFlowResultsData(ResultData);
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(process.env.REACT_APP_API_ENDPOINT + `/api/actions/${inputFieldText}`, {
        params: {
          workspace: userWorkspace,
        },
      })
      .then((response) => {
        setActionResultsData(response.data);
      });
  }, 300);

  return (
    <>
      <input
        ref={nameInputRef}
        name='name'
        type='text'
        value={inputFieldText}
        placeholder={renderOrder.length ? 'What comes next?' : 'How do you want to begin?'}
        className='flex-1 p-4 bg-white text-black rounded-2xl border border-[#c4c4c4] shadow-lg outline-plum'
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onKeyDown={(e) => handleKeyDown(e, 'name')}
        autoFocus
        autoComplete='off'
      />
      {searchbarOpen && (
        <SearchResults
          flowResultsData={flowResultsData}
          actionResultsData={actionResultsData}
          setFlowResultsData={setFlowResultsData}
          setActionResultsData={setActionResultsData}
          cursor={cursor}
          setCursor={setCursor}
          textInputRef={nameInputRef}
        />
      )}
    </>
  );
};

export default NameInput;
