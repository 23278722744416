import React from 'react';

const ContentImage = ({ url }) => {
  return (
    <div className='flex justify-center w-full h-full '>
      <img className='object-scale-down max-w-full max-h-full' src={url}></img>
    </div>
  );
};

export default ContentImage;
