import React, { useState } from 'react';
import axios from 'axios';
import { convertToFormat } from '../../helper/helperFunctions';
import { BiTrash } from 'react-icons/bi';
import DeleteCheckOverlay from './../DeleteCheckOverlay';
import { Modal } from './../Modal';

import { secondsToMMMSS } from '../../helper/timeFormaters';

const ActionItemLibrary = ({ action, setActionData, actionData }) => {
  const [deleteCheck, setDeleteCheck] = useState(false);
  const deleteAction = () => {
    const updatedActions = [...actionData].filter((actionData) => actionData._id !== action._id);
    setActionData(updatedActions);
    axios
      .delete(`${process.env.REACT_APP_API_ENDPOINT}/api/actions/${action._id}`)
      .catch((err) => console.log(err));
  };

  const sumUpChildren = () => {
    let sum = 0;
    action?.children.forEach((action) => {
      sum += action.time;
    });
    return convertToFormat(sum);
  };

  return (
    <div className='relative flex items-center justify-between px-3 py-1 border-b'>
      {action.type === 'combo' && <div className='absolute left-0 w-2 h-9 bg-combo'></div>}
      <h3 className='flex-1'>{action.name}</h3>
      <h3 className='flex-1'>
        {action.type === 'action' ? secondsToMMMSS(action.time) : sumUpChildren()}
      </h3>
      <button className='p-0.5 hover:bg-gray-200 rounded-full'>
        <BiTrash
          size={24}
          className='text-red-900'
          onClick={(e) => setDeleteCheck((prev) => !prev)}
        />
      </button>
      {deleteCheck && (
        <Modal>
          <DeleteCheckOverlay
            deleteAction={deleteAction}
            action={action}
            setDeleteCheck={setDeleteCheck}
            setIsActionMenuOpen={setDeleteCheck}
          />
        </Modal>
      )}
    </div>
  );
};

export default ActionItemLibrary;
