import React, { useState, useRef, useEffect } from 'react';

import { useActionContext, useTrackContext } from '../../contexts';

import TrackAction from './TrackAction';

const TrackActionsList = ({
  breakoutSession,
  track,
  trackActions,
  maxAllowedDuration,
  setMaxAllowedDuration,
}) => {
  // const [selectedTrackAction, setSelectedTrackAction] = useState(null);
  const { activeActionId } = useActionContext();
  const { selectedTrackActionId, setSelectedTrackActionId } = useTrackContext();

  useEffect(() => {
    setSelectedTrackActionId(null);
  }, [activeActionId, track, trackActions, setSelectedTrackActionId]);

  return (
    <>
      {trackActions?.map((trackAction) => (
        <TrackAction
          key={trackAction.id}
          breakoutSession={breakoutSession}
          track={track}
          trackAction={trackAction}
          maxAllowedDuration={maxAllowedDuration}
          setMaxAllowedDuration={setMaxAllowedDuration}
          isSelected={trackAction.id === selectedTrackActionId}
          onSelect={() => setSelectedTrackActionId(trackAction.id)}
        />
      ))}
    </>
  );
};

export default TrackActionsList;
