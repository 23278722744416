import React, { useState } from 'react';

import AreaForm from './AreaForm';
import AreaItem from './AreaItem';

import { useFlowContext, useAppContext } from '../../contexts';

const AreaModal = () => {
  const { flow, setFlow } = useFlowContext();
  const { setIsAreaModalOpen } = useAppContext();

  const [areas, setAreas] = useState(flow.areas || []);

  const handleAddArea = (newArea) => {
    if (areas.includes(newArea)) return;
    setAreas([...areas, newArea]);
    setFlow((prevFlow) => ({ ...prevFlow, areas: [...areas, newArea] }));
  };

  return (
    <dialog className='fixed inset-0 z-40 flex items-center justify-center w-full h-screen bg-black bg-opacity-50'>
      <div className='relative flex flex-col h-auto bg-white rounded-lg shadow-md w-96'>
        <button onClick={() => setIsAreaModalOpen(false)} className='absolute top-2 right-2'>
          X
        </button>

        <div className='w-64 mx-auto mt-6 mb-2 text-center text-xl font-bold'>Areas</div>
        <div className='flex flex-wrap gap-2 p-2'>
          {areas?.map((area, index) => (
            <AreaItem area={area} key={index} />
          ))}
        </div>

        <AreaForm onSubmit={handleAddArea} />
      </div>
    </dialog>
  );
};

export default AreaModal;
