import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Action from '../action/Action';
import Track from './Track';
import TrackActionsList from './TrackActionsList';
import { IconButton, TooltipSmall } from '../UI';
import Area from '../area/Area';

import { useFlowContext, useActionContext, useTrackContext } from '../../contexts';

import { FaPlus } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const BreakoutSession = ({ action, index, parent }) => {
  const { flow, setFlow } = useFlowContext();
  const { selectedActions, activeActionId } = useActionContext();
  const { selectedTrack, isTrackExpanded, setIsTrackExpanded } = useTrackContext();

  const isBreakoutSessionSelected = selectedActions.includes(action.id);

  const [maxAllowedDuration, setMaxAllowedDuration] = useState(
    action.duration -
      selectedTrack?.children?.map((action) => Number(action.duration)).reduce((a, b) => a + b, 0),
  );

  const handleAddTrack = () => {
    const tempId = uuidv4();
    const newTrack = {
      id: tempId,
      name: `New Track`,
      children: [
        {
          id: uuidv4(),
          name: 'New Action',
          duration: 0,
          type: 'action',
        },
      ],
      type: 'track',
      duration: 0,
    };

    action.children.push(newTrack);

    setFlow({
      ...flow,
      byId: {
        ...flow.byId,
        [action.id]: action,
      },
    });
  };

  useEffect(() => {
    //TODO: only first trac action duration update works

    setMaxAllowedDuration(
      action.duration -
        selectedTrack?.children
          ?.map((action, index) => {
            //console.log(index, 'DUR', action.duration);
            return Number(action.duration);
          })
          .reduce((a, b) => a + b, 0),
    );
  }, [
    selectedTrack,
    selectedTrack?.children,
    selectedTrack?.children?.length,
    action.duration,
    action,
    flow,
  ]);

  return (
    <>
      <Action key={action.id} action={action} parent={parent} index={index} />

      <div
        className={`grid grid-cols-[100px_minmax(200px,_1fr)] gap-4 px-2 pb-4 ${
          isBreakoutSessionSelected && 'bg-[#f5e8ec]'
        }`}
      >
        <div className='flex overflow-auto gap-2 col-start-2'>
          {action.children?.map((track) => (
            <div key={track.id} className='flex flex-col gap-1'>
              <Track
                key={track.id}
                isBreakoutSessionSelected={isBreakoutSessionSelected}
                breakoutSession={action}
                track={track}
              />

              {activeActionId === action.id && (
                <Area element={track} type={'track'} parent={action} />
              )}
            </div>
          ))}

          {isBreakoutSessionSelected && action.children.length < 6 && (
            <div>
              <IconButton icon={FaPlus} tooltipText='Add' onClick={handleAddTrack} color={'plum'} />
            </div>
          )}

          {isBreakoutSessionSelected && selectedTrack && (
            <div>
              <IconButton
                icon={isTrackExpanded ? IoIosArrowUp : IoIosArrowDown}
                tooltipText={isTrackExpanded ? 'Collapse' : 'Expand'}
                color={'plum'}
                onClick={() => {
                  if (selectedTrack) {
                    setIsTrackExpanded(!isTrackExpanded);
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>

      {isBreakoutSessionSelected && selectedTrack && isTrackExpanded && (
        <div
          className={`grid grid-cols-[100px_minmax(200px,_1fr)] gap-4 pb-4 ${
            isBreakoutSessionSelected && 'bg-[#f5e8ec]'
          }`}
        >
          <div className='col-start-2'>
            <TrackActionsList
              breakoutSession={action}
              track={selectedTrack}
              trackActions={selectedTrack.children}
              maxAllowedDuration={maxAllowedDuration}
              setMaxAllowedDuration={setMaxAllowedDuration}
            />

            {/* Comment out when more than one TRACK ACTION is allowed to be added */}
            {/* <TrackActionForm
              breakoutSession={action}
              track={selectedTrack}
              maxAllowedDuration={maxAllowedDuration}
              setMaxAllowedDuration={setMaxAllowedDuration}
            /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default BreakoutSession;
