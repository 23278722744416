import React from 'react';

const Spinner = () => {
  return (
    <div className={`flex items-center justify-center h-screen bg-bgColor`}>
      <div className='w-32 h-32 border-t-2 border-b-4 rounded-full border-combo bg-bg animate-spin'></div>
    </div>
  );
};

export default Spinner;
