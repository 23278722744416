import React, { useState } from 'react';

// components
import { Modal } from '../../Modal';
import DeleteIcon from './DeleteIcon';
import DeleteConfirmationModal from './DeleteConfirmationModal';

// icons
import { MdDragHandle } from 'react-icons/md';

const TrackActionCustomField = ({
  field,
  deleteCustomField,
  handleChange,
  element,
  provided,
  isDragging,
}) => {
  const [deleteCheck, setDeleteCheck] = useState(false);
  const [inputOption, setInputOption] = useState('');

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      className={`flex flex-col p-1 transition-colors border-2 rounded-lg bg-slate-200 border-slate-200 hover:bg-white hover:text-slate-800 hover:border-slate-200 hover:shadow-lg ${
        field.size === 1 ? 'w-1/3' : field.size === 2 ? 'w-2/3' : 'w-full'
      } ${isDragging ? 'bg-red-200' : ''}`}
    >
      <div className='flex items-center justify-between group'>
        <div className='flex items-center'>
          <div {...provided.dragHandleProps}>
            <MdDragHandle size={23} className='mr-1' />
          </div>

          <label htmlFor={field.name} className='font-bold text-gray-800'>
            {field.name}
          </label>
        </div>

        <DeleteIcon setDeleteCheck={setDeleteCheck} />
      </div>

      {deleteCheck && (
        <DeleteConfirmationModal
          field={field}
          deleteCustomField={() => deleteCustomField(field.name, element)}
          setDeleteCheck={setDeleteCheck}
        />
      )}

      {field.type === 'select' && (
        <>
          <input
            type='text'
            className='ml-2 border focus:border-bgColor'
            value={inputOption}
            onChange={(e) => setInputOption(e.target.value)}
          />
          <button
            onClick={(e) => {
              handleChange(field.name, inputOption, element);
              setInputOption('');
            }}
            className='px-4 ml-2 text-white rounded-full bg-slate-500'
          >
            Add Option
          </button>
        </>
      )}

      {field.type === 'select' ? (
        <select
          name={field.name}
          value={field?.selectedOption || ''}
          onChange={(e) => handleChange(field.name, e.target.value, element)}
        >
          {field.options &&
            field.options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
        </select>
      ) : (
        <input
          type={field.type}
          id={field.name}
          name={field.name}
          onChange={
            field.type === 'checkbox'
              ? (e) => handleChange(field.name, field.checked ? false : true, element)
              : (e) => handleChange(field.name, e.target.value, element)
          }
          checked={field.checked}
          className={`p-2 my-2 border-2 border-gray-200 outline-none hover:shadow-lg focus:border-bgColor ${
            field.type === 'checkbox' ? 'items-center' : ''
          } `}
          value={field.value || ''}
        />
      )}
    </div>
  );
};
// };

export default TrackActionCustomField;
