import { Modal } from '../../Modal';

const DeleteConfirmationModal = ({ field, deleteCustomField, setDeleteCheck }) => (
  <Modal>
    <dialog className='fixed inset-0 top-0 z-20 flex flex-col items-center justify-center w-full h-screen bg-black bg-opacity-40'>
      <div className='flex flex-col p-4 text-center bg-white rounded-lg w-96'>
        <p>
          Do you want to delete "<b>{field.name}</b>" for the entire flow?
        </p>
        <div className='flex mx-auto mt-4'>
          <button
            className='p-2 mr-4 font-extrabold border-2 rounded-lg w-14 hover:bg-bgColor hover:text-white text-bgColor border-bgColor'
            onClick={(e) => deleteCustomField(field.name)}
          >
            Yes
          </button>
          <button
            className='p-2 font-extrabold border-2 border-white rounded-lg w-14 hover:border-2 hover:border-black'
            onClick={(e) => setDeleteCheck(false)}
          >
            No
          </button>
        </div>
      </div>
    </dialog>
  </Modal>
);

export default DeleteConfirmationModal;
