import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useStore from '../store/UserStore';

export default function PrivateRoute(props) {
  const user = useStore((state) => state.user);
  const { component: Component, ...rest } = props;

  if (user.token === null) {
    return <Redirect to='/login' />;
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}
