import ErrorMessage from './ErrorMessage';

const InputField = ({ register, errors, label, id, type, placeholder, validation }) => {
  return (
    <div className='mb-4'>
      <label className='block mb-2 text-sm font-bold text-grey-darker' htmlFor={id}>
        {label}
      </label>
      <input
        className='w-full px-3 py-2 border rounded shadow appearance-none text-grey-darker'
        {...register(id, validation)}
        type={type}
        placeholder={placeholder}
      />
      {errors[id] && <ErrorMessage message={errors[id]?.message} />}
    </div>
  );
};

export default InputField;
