import React, { useEffect, useState } from 'react';

import { useFlowContext } from '../../contexts';

const Area = ({ element, type, parent }) => {
  const { flow, setFlow } = useFlowContext();

  const [newArea, setNewArea] = useState('');
  const [areas, setAreas] = useState(flow.areas || []);

  const handleChange = (e) => {
    setNewArea(e.target.value);

    if (areas.includes(e.target.value)) {
      updateArea(e.target.value);
    }
  };

  const handleAddArea = (e) => {
    e.preventDefault();

    if (!areas.includes(newArea)) {
      setAreas([...areas, newArea]);
      setFlow((prevFlow) => ({ ...prevFlow, areas: [...areas, newArea] }));
    }

    updateArea(newArea);

    setNewArea('');
  };

  const updateArea = (selectedArea) => {
    setFlow((prevFlow) => {
      const updatedFlow = { ...prevFlow };
      const updatedById = { ...updatedFlow.byId };

      if (type === 'action') {
        updatedById[element.id] = {
          ...updatedById[element.id],
          area: selectedArea,
        };
      } else if (type === 'track') {
        const action = updatedById[parent.id];
        const trackIndex = action.children.findIndex((track) => track.id === element.id);

        if (trackIndex !== -1) {
          action.children[trackIndex] = {
            ...action.children[trackIndex],
            area: selectedArea,
          };
        }
      }

      if (!updatedFlow?.areas?.includes(selectedArea)) {
        updatedFlow?.areas?.push(selectedArea);
      }

      updatedFlow.byId = updatedById;

      return updatedFlow;
    });
  };

  useEffect(() => {
    setAreas(flow.areas || []);
  }, [flow]);

  useEffect(() => {
    setNewArea(element.area || '');
  }, [element.area]);

  return (
    <form
      className='flex justify-center border border-[#c4c4c4] rounded-2xl bg-white text-center'
      onSubmit={handleAddArea}
    >
      <input
        className='w-full text-center text-[12px] rounded-2xl px-2 focus:outline-plum'
        type='text'
        name='areas'
        id='areas'
        value={newArea}
        onChange={handleChange}
        placeholder='Enter Area'
        list='area-options'
        autoComplete='off'
      />
      <datalist onChange={() => console.log('SELECT')} id='area-options'>
        {flow?.areas?.map((area, index) => (
          <option key={index} value={area} />
        ))}
      </datalist>
    </form>
  );
};

export default Area;

// import React, { useEffect, useState } from 'react';

// import { findTrackById } from '../../helper/helperFunctions';
// import { useFlowContext } from '../../contexts';

// const Area = ({ element, type, parent }) => {
//   const { flow, setFlow } = useFlowContext();

//   const [areas, setAreas] = useState(flow.areas || []);

//   const handleAreaChange = (event) => {
//     const selectedArea = event.target.value;

//     if (type === 'action') {
//       setFlow((prevFlow) => {
//         return {
//           ...prevFlow,
//           byId: {
//             ...prevFlow.byId,
//             [element.id]: {
//               ...prevFlow.byId[element.id],
//               area: selectedArea,
//             },
//           },
//         };
//       });
//     } else if (type === 'track') {
//       setFlow((prevFlow) => {
//         const copyFlow = { ...prevFlow };
//         const copyAction = { ...copyFlow.byId[parent.id] };

//         const { copyTrack, copyTrackIndex } = findTrackById(copyAction.children, element.id);

//         copyTrack.area = selectedArea;

//         copyAction.children[copyTrackIndex] = copyTrack;
//         copyFlow.byId[parent.id] = copyAction;

//         return copyFlow;
//       });
//     }
//   };

//   useEffect(() => {
//     setAreas(flow.areas || []);
//   }, [flow]);

//   return (
//     <select
//       className='flex justify-center border border-[#c4c4c4] rounded-2xl bg-white text-center text-sm'
//       name='areas'
//       id='areas'
//       value={element.area || ''}
//       onChange={handleAreaChange}
//     >
//       <option value='' disabled defaultValue>
//         -- Select --
//       </option>
//       {areas.map((item, index) => (
//         <option key={index} value={item}>
//           {item}
//         </option>
//       ))}
//     </select>
//   );
// };

// export default Area;
