import React from 'react';

const ProgressBar = ({ backgroundImage, progress }) => {
  const progressStyle = {
    width: `${progress}%`,
    transition: 'width 1000ms linear',
  };

  return (
    <div
      className={`z-10 absolute inset-0 transition bg-bgColorDark ${
        backgroundImage ? 'opacity-80' : 'opacity-100'
      }`}
      style={progressStyle}
    ></div>
  );
};

export default ProgressBar;
