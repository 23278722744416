import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom/';

import fireDb from '../firebase';
import moment from 'moment';
import { QRCodeSVG } from 'qrcode.react';
import Moment from 'react-moment';
import { isEmpty } from 'lodash';
import 'moment-timezone';
import { useHistory } from 'react-router-dom';
import Spinner from '../components/Spinner';
import paperplane from '../assets/icons/paperplane.png';
import PdfViewer from '../components/start/PdfViewer';
import setMiroLink from '../helper/setMiroLink';
import ContentImage from '../components/start/ContentImage';
import ProgressBar from '../components/start/ProgressBar';
import { flattenFlowWithIds, flattenFlowWithObjects } from '../helper/flowFlatteners';

import { getFlowEndTime, getFlowEndTimeFromNow } from '../helper/helperFunctions';

const ShareView = (props) => {
  const [flow, setFlow] = useState({});
  const [progress, setProgress] = useState(0);
  const [qrCodeSize, setQrCodeSize] = useState();
  const [countDownMode, setCountDownMode] = useState(true);
  const history = useHistory();
  const workspace = props.match.params.workspace;
  const flowId = props.match.params.id;
  const liveItem = flow?.live?.activeAction;
  const activeAction = flow.live?.activeAction;
  const startTimeOfCurrentAction = flow.live?.start;
  const [contentMedia, setContentMedia] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(activeAction?.backgroundMedia);

  const { area } = useParams();
  const [location, setLocation] = useState(
    area && area === liveItem?.area ? 'SAME ROOM' : area ? 'OTHER ROOM' : 'GLOBAL',
  );

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const LOCATIONS = {
    GLOBAL: 'GLOBAL',
    SAME_ROOM: 'SAME ROOM',
    OTHER_ROOM: 'OTHER ROOM',
  };

  // Update Document title to <Area Name>
  useEffect(() => {
    document.title = 'Global';
  }, [area]);

  useEffect(() => {
    setLocation(
      area && area === liveItem?.area
        ? LOCATIONS.SAME_ROOM
        : area
        ? LOCATIONS.OTHER_ROOM
        : LOCATIONS.GLOBAL,
    );
  }, [liveItem, area]);

  useEffect(() => {
    // Only set the background image if the flow is on pause, because otthersiwe the many rerenders cause the image to flicker
    if (flow?.live?.status === 'playing') {
      return setBackgroundImage(null);
    } else if (flow?.live?.status === 'paused') {
      return setBackgroundImage(activeAction?.backgroundMedia);
    } else {
      return setBackgroundImage(null);
    }
  }, [activeAction, flow?.live?.status]);

  const calculateEndTime = () => {
    let totalDuration = Object.values(flow.byId).reduce((acc, obj) => acc + obj.duration, 0);

    return moment(flow?.live?.start).add(totalDuration, 's').format('HH:mm');
  };

  const calculateProgress = useCallback(() => {
    let progress = 0;
    let timeElapsed = liveItem?.duration - flow?.live?.durationLeft;

    if (flow?.live?.status === 'playing') {
      const dateOfNextJob = moment(flow.live?.dateOfNextJob);
      const diff = moment().diff(dateOfNextJob.clone().subtract(flow.live.durationLeft, 'seconds'), 'seconds');
      const currentDuration = moment.duration(diff);
      let timePlaying = currentDuration._milliseconds;
      timeElapsed += timePlaying;

      progress = (timeElapsed / liveItem.duration) * 100;
    } else {
      progress = (timeElapsed / liveItem?.duration) * 100;
    }

    if (isNaN(progress) || progress > 100) progress = 0;

    return progress;
  }, [flow.live, flow.byId]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setProgress(calculateProgress());
    }, 1000);
    return () => clearInterval(id);
  }, [calculateProgress]);

  useEffect(() => {
    fireDb.child(`${workspace}/flows/${flowId}`).on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        const firebaseData = snapshot.val();
        setFlow({ ...firebaseData });
        console.log('FLOW', firebaseData);
      } else {
        history.push('/');
        setFlow({});
      }
    });
    return () => {
      setFlow({});
    };
  }, [flowId, workspace, history]);

  useEffect(() => {
    if (window.innerWidth < 1200 || contentMedia?.fileType) {
      setQrCodeSize(100);
    } else if (window.innerWidth < 2000) {
      setQrCodeSize(250);
    } else {
      setQrCodeSize(600);
    }
  });

  if (isEmpty(flow)) return <Spinner />;

  const NormalView = ({ small }) => {
    if (location === LOCATIONS.GLOBAL || location === LOCATIONS.SAME_ROOM)
      return (
        <>
          <div className='flex flex-col items-center justify-center h-full text-white bg-bgColor bg-op'>
            {backgroundImage?.url && (
              <img
                className='absolute inset-0 object-cover w-full h-full opacity-80'
                src={backgroundImage?.url}
                alt='Background'
              />
            )}
            <div className='hidden md:pr-10 md:h-1/4 md:w-1/4 md:justify-center md:items-center md:flex-col md:flex md:right-0 bg-op md:absolute h-1/4'>
              <div className='z-50'>
                <QRCodeSVG
                  size={qrCodeSize}
                  bgColor='transparent'
                  value={window.location.href}
                  fgColor='#ffffff'
                />
              </div>
            </div>
            {flow?.live?.status === 'playing' && liveItem ? (
              <div className='z-10'>
                <div className='absolute z-10 text-xl top-3 right-3'>
                  End: {getFlowEndTimeFromNow(flow)}
                </div>
                <div className='mx-auto cursor-pointer'>
                  <Moment
                    className={`font-bold text-center text-7xl embeded:text-xl ${
                      !small && 'sm:text-9xl'
                    }`}
                    interval={1000}
                    duration={
                      countDownMode ? moment(flow.live.dateOfNextJob) : moment(flow.live?.start)
                    }
                    format='hh:mm:ss'
                    durationFromNow
                  ></Moment>
                  <div
                    className={`flex justify-between p-2 text-lg embeded:mx-auto embeded:hidden ${
                      !small && 'sm:text-2xl'
                    }`}
                  >
                    <span>Hours</span>
                    <span>Minutes</span>
                    <span>Seconds</span>
                  </div>
                </div>
                <div className='h-10'></div>
                <div className='absolute left-0 right-0 mx-auto'>
                  <h1
                    className={`'mx-auto text-3xl text-center embeded:text-sm ${
                      !small && 'sm:text-5xl'
                    }`}
                  >
                    {liveItem.name || 'end'}
                  </h1>
                  <h2
                    className={`mx-auto mt-8 text-lg text-center embeded:hidden ${
                      !small && 'sm:text-2xl'
                    }`}
                  >
                    Up Next: {flow?.live?.nextItem?.name || 'end'}
                  </h2>
                </div>
              </div>
            ) : (
              <div className='z-10'>
                {flow?.live?.status === 'paused' ? (
                  <div className='z-10'>
                    <div className='mx-auto cursor-pointer'>
                      <h1
                        className={`font-bold text-center text-7xl embeded:text-xl ${
                          !small && 'sm:text-9xl'
                        }`}
                      >
                        -
                        {moment.utc(Math.floor(flow?.live?.durationLeft) * 1000).format('HH:mm:ss')}
                      </h1>

                      <div
                        className={`flex justify-between p-2 text-lg embeded:mx-auto sm:text-2xl embeded:hidden ${
                          !small && 'sm:text-2xl'
                        }`}
                      >
                        <span>Hours</span>
                        <span>Minutes</span>
                        <span>Seconds</span>
                      </div>
                    </div>
                    <div className='h-10'></div>
                    <div className='absolute left-0 right-0 mx-auto'>
                      <h1
                        className={`mx-auto text-3xl text-center embeded:text-sm ${
                          !small && 'sm:text-5xl'
                        }`}
                      >
                        {liveItem.name || 'end'}
                      </h1>
                      <h2
                        className={`mx-auto mt-8 text-lg text-center sm:text-2xl embeded:hidden ${
                          !small && 'sm:text-2xl'
                        }`}
                      >
                        Up Next: {flow?.live?.nextItem?.name || 'end'}
                      </h2>
                    </div>
                  </div>
                ) : (
                  <div>
                    <img
                      src={paperplane}
                      alt='paperplane'
                      className='w-40 mx-auto mb-5 embeded:hidden'
                    />
                    <h1 className='text-6xl font-bold text-center text-white'>{flow.name}</h1>
                    <h1 className='text-2xl font-bold text-center text-white'>
                      starts at: {moment(flow?.startingDate).format('DD.MM.YYYY – HH:mm')}
                    </h1>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='flex flex-row mx-auto'>
            {/* Render tracks if a breakout session is live */}
            {liveItem?.type === 'breakoutSession' &&
              flow.live.tracks &&
              liveItem.children?.map((track) => {
                if (!(track.id in flow.live?.tracks)) return <></>;

                let liveTrack = flow.live?.tracks[track?.id];

                if (liveTrack === undefined || liveTrack.status === 'ended') return <></>;

                let liveTrackItem = liveTrack?.activeAction;

                // console.log('LIVE TRACK', liveTrack)

                return flow?.live?.status === 'playing' && liveTrackItem ? (
                  <div className='z-10 text-white mr-10'>
                    <div className='mx-auto text-center cursor-pointer'>
                      <h1>Area: {track.area}</h1>
                      <Moment
                        className={`font-bold text-center text-2xl embeded:text-xl ${
                          !small && 'sm:text-xl'
                        }`}
                        interval={1000}
                        duration={
                          moment(liveTrack.dateOfNextJob)
                        }
                        format='hh:mm:ss'
                        durationFromNow
                      ></Moment>
                      <h1
                        className={`'text-3xl text-center embeded:text-sm ${
                          !small && 'sm:text-2xl'
                        }`}
                      >
                        {liveTrackItem.name || 'end'}
                      </h1>
                    </div>
                  </div>
                ) : (
                  windowSize.height > 500 && (
                    <div className='z-10 text-white mr-10'>
                      {liveTrack?.status === 'paused' ? (
                        <div className='z-10'>
                          <div className='mx-auto cursor-pointer'>
                            <h1 className='text-center'>Area: {track.area}</h1>
                            <h1
                              className={`font-bold text-center embeded:text-xl ${
                                !small && 'sm:text-2xl'
                              }`}
                            >
                              -
                              {moment
                                .utc(Math.floor(liveTrack.durationLeft) * 1000)
                                .format('HH:mm:ss')}
                            </h1>
                            <h1
                              className={`mx-auto text-center text-2xl embeded:text-sm ${
                                !small && 'sm:text-2xl'
                              }`}
                            >
                              {liveTrackItem.name || 'end'}
                            </h1>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <img
                            src={paperplane}
                            alt='paperplane'
                            className='w-40 mx-auto mb-5 embeded:hidden'
                          />
                          <h1 className='text-2xl font-bold text-center text-white'>{flow.name}</h1>
                          <h1 className='text-2xl font-bold text-center text-white'>
                            starts at: {moment(flow?.startingDate).format('DD.MM.YYYY – HH:mm')}
                          </h1>
                        </div>
                      )}
                    </div>
                  )
                );
              })}
          </div>
        </>
      );
    else {
      return <></>;
    }
  };

  return (
    <>
      {(location === LOCATIONS.GLOBAL || location === LOCATIONS.SAME_ROOM) && (
        <ProgressBar progress={progress} backgroundImage={backgroundImage} />
      )}

      <div className='flex flex-col w-screen h-screen bg-bgColor bg-op'>
        {/* AREA NAME IF PRESENT */}
        <div className='absolute top-10 left-1/2 transform -translate-x-1/2 text-5xl font-bold text-center text-white z-10'>
          {area ? area : 'Overview'}
        </div>

        {windowSize.height > 500 && (
          <div className='absolute top-28 left-1/2 transform -translate-x-1/2 text-lg text-center z-10 w-full flex flex-wrap justify-center'>
            {flow?.areas?.map((area, index) => (
              <Link
                key={index}
                className='m-1 font-bold text-plum bg-white p-2 whitespace-nowrap overflow-wrap break-word'
                to={`/${workspace}/${flowId}/${flow.name}/share/${area}`}
              >
                {area}
              </Link>
            ))}
          </div>
        )}

        {contentMedia?.url ? (
          <>
            <div
              className={`z-50 embeded:hidden ${
                contentMedia && contentMedia.contentScreenSize === 'full'
                  ? 'h-full w-full'
                  : 'flex h-2/3 w-full justify-center'
              } `}
            >
              <div
                className={`z-50 flex flex-col justify-center h-full m-auto p-4 ${
                  contentMedia && contentMedia.contentScreenSize === 'full' ? 'w-full' : 'w-5/6'
                } `}
              >
                {contentMedia?.fileType === 'miro' && (
                  <iframe
                    title='Miro Link'
                    src={setMiroLink(contentMedia.url)}
                    bgColor='transparent'
                    width='100%'
                    height='100%'
                  />
                )}
                {contentMedia?.fileType === 'pdf' && (
                  <div className='w-full h-full'>
                    <PdfViewer data={contentMedia} handlePdfPageTurn={false} />
                  </div>
                )}
                {contentMedia?.fileType === 'image' && <ContentImage url={contentMedia.url} />}
              </div>
            </div>
            <div
              className={` embeded:h-full ${
                contentMedia && contentMedia.contentScreenSize === 'full' ? 'hidden w-0' : 'h-1/3'
              } `}
            >
              <NormalView small={true} />
            </div>
          </>
        ) : (
          <NormalView small={false} />
        )}
      </div>
    </>
  );
};

export default ShareView;
