import firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfigStaging = {
  apiKey: 'AIzaSyBv5KWXo9jQ_TTFo5zo_u_X-m7wk4pIYNY',
  authDomain: 'flowtomator-staging.firebaseapp.com',
  projectId: 'flowtomator-staging',
  storageBucket: 'flowtomator-staging.appspot.com',
  messagingSenderId: '1010178619890',
  appId: '1:1010178619890:web:b97cf62a8310828447136b',
  databaseURL: 'https://flowtomator-staging-default-rtdb.europe-west1.firebasedatabase.app',
};

const firebaseConfigLive = {
  apiKey: 'AIzaSyA16X73cd0OJuwl0Z8g_72ZWOELDt_zNBI',
  authDomain: 'flowtomator-139b8.firebaseapp.com',
  projectId: 'flowtomator-139b8',
  storageBucket: 'flowtomator-139b8.appspot.com',
  messagingSenderId: '836641738686',
  appId: '1:836641738686:web:10997af030acac10bf33ef',
};

let fireDb;
if (process.env.REACT_APP_STAGING) {
  fireDb = firebase.initializeApp(firebaseConfigStaging);
} else {
  fireDb = firebase.initializeApp(firebaseConfigLive);
}

if (window.location.hostname === 'localhost') {
  fireDb.database().useEmulator('localhost', 9000); // do not forget to default to 9000
}

export default fireDb.database().ref();
