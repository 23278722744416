import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { BiDuplicate, BiTrash } from 'react-icons/bi';
import { IoLibrary } from 'react-icons/io5';

import fireDb from '../../firebase';
import DeleteCheckOverlay from '../DeleteCheckOverlay';
import { Modal } from '../Modal';
import ToolButton from '../UI/ToolButton';
import Notification from '../start/Notification';

// context
import { useFlowContext, useActionContext } from '../../contexts';

const TOOL_DATA = {
  library: {
    tooltipText: 'Add to Library',
    toolInfo: 'library',
    icon: IoLibrary,
  },
  duplicate: {
    tooltipText: 'Duplicate Combo',
    toolInfo: 'duplicate',
    icon: BiDuplicate,
  },

  delete: {
    tooltipText: 'Delete Combo',
    toolInfo: 'delete',
    icon: BiTrash,
  },
};

const Menu = ({ action, setIsActionMenuOpen }) => {
  const { userWorkspace, flowId, flow, setFlow } = useFlowContext();
  const { selectedActions, setSelectedActions } = useActionContext();

  const [notification, setNotification] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [deleteCheck, setDeleteCheck] = useState(false);

  const handleDuplicateCombo = () => {
    const updatedFlow = { ...flow };
    const newId = uuidv4();

    if (action?.type === 'combo') {
      const ChildrenDuplicates = action.children.map((id) => {
        const newId = uuidv4();
        updatedFlow.byId[newId] = {
          ...flow.byId[id],
          id: newId
        }
        return newId;
      });
      updatedFlow.byId[newId] = { ...action, id: newId, children: [...ChildrenDuplicates] };
      updatedFlow.byOrder = [
        ...flow.byOrder.slice(0, flow.byOrder.findIndex((id) => id === action.id) + 1),
        newId,
        ...flow.byOrder.slice(flow.byOrder.findIndex((id) => id === action.id) + 1),
      ];
    }

    setFlow(updatedFlow);

    if (flowId) {
      const updates = {};
      updates[userWorkspace + '/flows/' + flowId] = updatedFlow;
      fireDb.update(updates);
    }
    setIsActionMenuOpen(false);
  };

  const handleDeleteCombo = () => {
    console.log('handle delete combo')

    if (selectedActions.includes(action.id)) {
      const updatedSelectedActions = [...selectedActions].filter((id) => id !== action.id);
      setSelectedActions(updatedSelectedActions);
    }

    const updatedFlow = { ...flow };

    if (action?.type === 'combo') {
      action.children.forEach((childId) => {
        delete updatedFlow.byId[childId];
      });

      delete updatedFlow.byId[action.id];
      updatedFlow.byOrder = updatedFlow.byOrder.filter((id) => id !== action.id);
    }

    setFlow(updatedFlow);

    if (flowId) {
      const updates = {};
      updates[userWorkspace + '/flows/' + flowId] = updatedFlow;
      fireDb.update(updates);
    }
    setIsActionMenuOpen(false);
  };

  const handleAddComboToLibrary = () => {
    const children = flow.byId[action.id]?.children.map((actionId) => {
      flow.byId[actionId].parentId = action.id;
      return flow.byId[actionId];
    });
    setSubmitting(true);
    axios
      .post(process.env.REACT_APP_API_ENDPOINT + '/api/actions', {
        name: action.name,
        workspace: userWorkspace,
        type: 'combo',
        children: [...children],
      })
      .then((res) => {
        if (res.data.err) {
          setNotification({ type: 'error', message: res.data.err.message });
        } else {
          setNotification({ type: 'success', message: 'Saved to library' });
        }
        setSubmitting(false);
        setTimeout(() => {
          setNotification('');
        }, 3000);
      });
  };

  return (
    <div className='absolute right-0 flex flex-col rounded-lg bg-white p-3 border'>
      <div className='flex gap-1'>
        {/* ADD TO LIBRARY */}
        <ToolButton
          icon={TOOL_DATA.library.icon}
          iconStyle={'gray'}
          iconSize={25}
          onClickAction={handleAddComboToLibrary}
          tooltipText={TOOL_DATA.library.tooltipText}
          toolInfo={TOOL_DATA.library.toolInfo}
        />

        {/* DUPLICATE COMBO */}
        <ToolButton
          icon={TOOL_DATA.duplicate.icon}
          iconStyle={'gray'}
          iconSize={25}
          onClickAction={handleDuplicateCombo}
          tooltipText={TOOL_DATA.duplicate.tooltipText}
          toolInfo={TOOL_DATA.duplicate.toolInfo}
        />

        {/* DELETE COMBO */}
        <ToolButton
          icon={TOOL_DATA.delete.icon}
          iconStyle={'plum'}
          iconSize={25}
          onClickAction={() => {
            setDeleteCheck(true);
          }}
          tooltipText={TOOL_DATA.delete.tooltipText}
          toolInfo={TOOL_DATA.delete.toolInfo}
        />

        {notification && <Notification notification={notification} />}

        {deleteCheck && (
          <Modal>
            <DeleteCheckOverlay
              action={action}
              deleteAction={handleDeleteCombo}
              setDeleteCheck={setDeleteCheck}
              setIsActionMenuOpen={setIsActionMenuOpen}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export { Menu };
