import fireDb from '../firebase';
import { v4 as uuidv4 } from 'uuid';

const addToFlowFromLibrary = (
  flow,
  flowId,
  setFlow,
  activeActionId,
  resultItem,
  userWorkspace,
  setCursor,
  setInputFieldText,
) => {
  let updatedFlow = {
    ...flow,
    byOrder: [...(flow.byOrder || [])],
    byId: {
      ...flow.byId,
    },
  };

  // replace action with action/combo from library
  if (!['up', 'down'].includes(activeActionId)) {
    const updatedAction = {
      name: resultItem.name,
      duration: resultItem.time > 0 ? resultItem.time : '',
      type: resultItem.type,
    };
    const flowCopy = { ...flow };
    if (resultItem.type === 'action') {
      flowCopy.byId[activeActionId] = updatedAction;
    }
    if (resultItem.type === 'combo') {
      let updatedChildren = [];
      resultItem.children.forEach((action) => {
        let newId = uuidv4();
        updatedChildren.push(newId);
        flowCopy.byId[newId] = {
          name: action.name,
          duration: action.time,
          type: action.type,
        };
      });
      const updatedCombo = {
        name: resultItem.name,
        type: resultItem.type,
        children: updatedChildren,
      };
      flowCopy.byId[activeActionId] = updatedCombo;
    }
    if (flowId) {
      let updates = {};
      updates[userWorkspace + '/flows/' + flowId] = { ...flow };
      fireDb.update(updates);
    }

    setCursor(-1);
    return;
  }

  let updatedChildren = [];
  if (resultItem.type === 'combo') {
    resultItem.children.forEach((children) => {
      let newId = uuidv4();
      updatedChildren.push(newId);
      updatedFlow.byId[newId] = {
        name: children.name,
        type: children.type,
        duration: children.time ? children.time : children.duration ? children.duration : 0,
      };
    });
  }

  const newId = uuidv4();
  updatedFlow.byId[newId] = {
    name: resultItem.name,
    duration: resultItem.time > 0 ? resultItem.time : '',
    type: resultItem.type,
    children: updatedChildren,
  };
  updatedFlow.byOrder = [...(flow.byOrder || []), newId];

  if (flowId) {
    let updates = {};
    updates[userWorkspace + '/flows/' + flowId] = updatedFlow;
    fireDb.update(updates);
  } else {
    setFlow(updatedFlow);
  }
  setInputFieldText('');
  setCursor(-1);
};

export default addToFlowFromLibrary;
