const flattenFlowWithIds = (flow) => {
  const flatFlow = flow.byOrder.map((id) => {
    const item = flow.byId[id];

    // if (item.type === 'breakoutSession') {
    //   return item.children[0]?.children?.map(child => child.id);
    // } 

    if (item.type === 'combo') {
      return item.children?.map(id => id);
    }

    return item.id;
  }).flat();

  return flatFlow;
}

const flattenFlowWithObjects = (flow) => {
  const flatFlow = flow.byOrder.map((id) => {
    const item = flow.byId[id];

    if (item.type === 'breakoutSession') {
      return item.children[0]?.children?.map(child => child);
    } 

    if (item.type === 'combo') {
      return item.children?.map(id => flow.byId[id]);
    }

    return item;
  }).flat();

  return flatFlow;
}

export { flattenFlowWithIds, flattenFlowWithObjects };