import React from 'react';

const DeleteCheckOverlay = ({ action, deleteAction, setIsActionMenuOpen, setDeleteCheck }) => {
  return (
    <dialog
      open
      className='fixed inset-0 z-40 flex items-center justify-center w-full h-screen bg-black bg-opacity-50'
    >
      <div className='flex flex-col h-auto bg-white rounded-lg shadow-md w-96'>
        <p className='w-64 mx-auto mt-6 text-center'>
          Are you sure you want to delete <b>"{action.name}"</b>?
        </p>
        <div className='my-5 text-center'>
          <button
            className='p-2 mr-4 font-extrabold border-2 rounded-lg w-14 hover:bg-bgColor hover:text-white text-bgColor border-bgColor'
            onClick={(e) => {
              deleteAction(e);
              if (action.type === 'combo' || action.type === 'action') {
                setIsActionMenuOpen(false);
              }
            }}
          >
            Yes
          </button>
          <button
            className='p-2 font-extrabold border-2 border-white rounded-lg w-14 hover:border-2 hover:border-black'
            onClick={(e) => {
              setDeleteCheck(false);
              if (action.type === 'combo' || action.type === 'action') {
                setIsActionMenuOpen(false);
              }
            }}
          >
            No
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default DeleteCheckOverlay;
