import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import { BiDuplicate, BiTrash, BiLayer } from 'react-icons/bi';
import { IoLibrary } from 'react-icons/io5';
import { FaPlay } from 'react-icons/fa';

import fireDb from '../../firebase';
import DeleteCheckOverlay from '../DeleteCheckOverlay';
import { Modal } from '../Modal';
import ToolButton from '../UI/ToolButton';

import { flattenFlowWithIds } from '../../helper/flowFlatteners';

// context
import { useAppContext, useFlowContext, useActionContext } from '../../contexts';

const TOOL_DATA = {
  library: {
    tooltipText: 'Add to Library',
    toolInfo: 'library',
    icon: IoLibrary,
  },
  duplicate: {
    tooltipText: 'Duplicate Action',
    toolInfo: 'duplicate',
    icon: BiDuplicate,
  },
  create: {
    tooltipText: 'Create Breakout Sessions',
    toolInfo: 'create',
    icon: BiLayer,
  },
  delete: {
    tooltipText: 'Delete Action',
    toolInfo: 'delete',
    icon: BiTrash,
  },
  play: {
    tooltipText: 'Play Action',
    toolInfo: 'play',
    icon: FaPlay,
  },
};

const Menu = ({ action, parent, setIsActionMenuOpen }) => {
  const { socket, setPlayerVisible, setNotesVisible } = useAppContext();
  const {
    userWorkspace,
    flowId,
    flow,
    setFlow,
    timeDate,
    userWorkspace: workspace,
  } = useFlowContext();
  const { setActiveActionId, selectedActions, setSelectedActions } = useActionContext();

  const [isBreakoutSessionCreated, setIsBreakoutSessionCreated] = useState(
    (flow.byId[action.id]?.type === 'breakoutSession' &&
      flow.byId[action.id]?.children?.length > 0) ||
      false,
  );

  const [deleteCheck, setDeleteCheck] = useState(false);

  const handleDuplicateAction = () => {
    const updatedFlow = { ...flow };
    const newId = uuidv4();

    if (action.type === 'combo') {
      const ChildrenDuplicates = action.children.map((id) => {
        const newId = uuidv4();
        updatedFlow.byId[newId] = { ...flow.byId[id] };
        return newId;
      });
      updatedFlow.byId[newId] = { ...action, id: newId, children: [...ChildrenDuplicates] };
      updatedFlow.byOrder = [
        ...flow.byOrder.slice(0, flow.byOrder.findIndex((id) => id === action.id) + 1),
        newId,
        ...flow.byOrder.slice(flow.byOrder.findIndex((id) => id === action.id) + 1),
      ];
    } else if (action.type === 'breakoutSession') {
      const ChildrenDuplicates = action.children.map((child) => {
        const newChild = { ...child, id: uuidv4() };
        return newChild;
      });

      updatedFlow.byId[newId] = { ...action, id: newId, children: [...ChildrenDuplicates] };
      updatedFlow.byOrder = [
        ...flow.byOrder.slice(0, flow.byOrder.findIndex((id) => id === action.id) + 1),
        newId,
        ...flow.byOrder.slice(flow.byOrder.findIndex((id) => id === action.id) + 1),
      ];
    } else {
      updatedFlow.byId[newId] = { ...action, id: newId };
      updatedFlow.byOrder = [
        ...flow.byOrder.slice(0, flow.byOrder.findIndex((id) => id === action.id) + 1),
        newId,
        ...flow.byOrder.slice(flow.byOrder.findIndex((id) => id === action.id) + 1),
      ];
    }

    setFlow(updatedFlow);

    if (flowId) {
      const updates = {};
      updates[userWorkspace + '/flows/' + flowId] = updatedFlow;
      fireDb.update(updates);
    }
    setIsActionMenuOpen(false);
  };

  const handlePlayAction = () => {
    const flatFlowWithIds = flattenFlowWithIds(flow);

    socket.emit('api/live/goto', {
        workspace,
        flowId,
        index: flatFlowWithIds.indexOf(action.id),
    })

    setPlayerVisible(true);

    setNotesVisible(true);
    setActiveActionId(action.id);
    setSelectedActions([action.id]);
  };

  const handleDeleteAction = () => {
    console.log('handle delete action');

    if (selectedActions.includes(action.id)) {
      const updatedSelectedActions = [...selectedActions].filter((id) => id !== action.id);
      setSelectedActions(updatedSelectedActions);
    }

    const updatedFlow = { ...flow };

    if (action?.type !== 'combo') {
      console.log('ACTION TYPE', action.type);
      delete updatedFlow.byId[action.id];
      updatedFlow.byOrder = updatedFlow.byOrder.filter((id) => id !== action.id);

      if (parent) {
        console.log('HERE', updatedFlow.byId[parent].children);
        updatedFlow.byId[parent].children = updatedFlow.byId[parent].children.filter(
          (id) => id !== action.id,
        );
      }
    }

    setFlow(updatedFlow);

    if (flowId) {
      const updates = {};
      updates[userWorkspace + '/flows/' + flowId] = updatedFlow;
      fireDb.update(updates);
    }
    setIsActionMenuOpen(false);
  };

  const handleCreateBreakoutSession = () => {
    if (!isBreakoutSessionCreated) {
      const updatedAction = flow.byId[action.id];
      updatedAction.type = 'breakoutSession';
      updatedAction.children = [];

      for (let i = 0; i < 2; i++) {
        const tempId = uuidv4();
        const newTrack = {
          id: tempId,
          name: `New Track`,
          children: [
            {
              id: uuidv4(),
              name: 'New Action',
              type: 'action',
              duration: 0,
            },
          ],
          type: 'track',
          duration: 0,
        };

        updatedAction.children.push(newTrack);
      }

      setFlow({
        ...flow,
        byId: {
          ...flow.byId,
          [action.id]: updatedAction,
        },
      });

      setIsBreakoutSessionCreated(true);
    }
  };

  // needs to be fixed
  // const handlePlayAction = () => {
  //   if (flowId && flow.byOrder.length > 0)
  //     axios.post(process.env.REACT_APP_API_ENDPOINT + `/api/live/goto`, {
  //       workspace: userWorkspace,
  //       flowId,
  //       activeActionId: action.id,
  //     });
  //   setPlayerVisible(true);

  //   setNotesVisible(true);
  //   setActiveActionId(action.id);
  // };

  return (
    <div className='absolute right-0 flex flex-col rounded-lg bg-white p-3 border'>
      <div className='flex gap-1'>
        {/* ADD TO LIBRARY */}
        {flowId && action.type === 'combo' && (
          <ToolButton
            icon={TOOL_DATA.library.icon}
            iconStyle={'gray'}
            iconSize={25}
            onClickAction={() => console.log('ADD TO LIBRARY')}
            tooltipText={TOOL_DATA.library.tooltipText}
          />
        )}

        {/* DUPLICATE ACTION */}
        <ToolButton
          icon={TOOL_DATA.duplicate.icon}
          iconStyle={'gray'}
          iconSize={25}
          onClickAction={handleDuplicateAction}
          tooltipText={TOOL_DATA.duplicate.tooltipText}
        />

        {!isBreakoutSessionCreated && action.type !== 'combo' && (
          <>
            {/* CREATE BREAKOUT SESSIONS */}
            <ToolButton
              icon={TOOL_DATA.create.icon}
              iconStyle={'gray'}
              iconSize={25}
              onClickAction={handleCreateBreakoutSession}
              tooltipText={TOOL_DATA.create.tooltipText}
            />
          </>
        )}

        {/* DELETE ACTION */}
        <ToolButton
          icon={TOOL_DATA.delete.icon}
          iconStyle={'plum'}
          iconSize={25}
          onClickAction={() => {
            setDeleteCheck(true);
          }}
          tooltipText={TOOL_DATA.delete.tooltipText}
        />

        {flowId && action.type !== 'combo' && (
          <>
            {/* PLAY ACTION */}
            <ToolButton
              icon={TOOL_DATA.play.icon}
              iconStyle={'gray'}
              iconSize={25}
              onClickAction={handlePlayAction}
              tooltipText={TOOL_DATA.play.tooltipText}
            />
          </>
        )}

        {deleteCheck && (
          <Modal>
            <DeleteCheckOverlay
              action={action}
              deleteAction={handleDeleteAction}
              setDeleteCheck={setDeleteCheck}
              setIsActionMenuOpen={setIsActionMenuOpen}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Menu;
