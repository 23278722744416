import { useState, useEffect, useRef } from 'react';

import { BiDotsVerticalRounded } from 'react-icons/bi';

import TrackActionMenu from './TrackActionMenu';
import DurationInputField from './DurationInputField';
import NameInputField from './NameInputField';
import StartTime from '../shared/StartTime';

import { useFlowContext } from '../../contexts';

const TrackAction = ({
  breakoutSession,
  track,
  trackAction,
  maxAllowedDuration,
  setMaxAllowedDuration,
  isSelected,
  onSelect,
}) => {
  const { flow, setFlow, timeDate } = useFlowContext();

  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

  const nameInputRef = useRef();
  const durationInputRef = useRef();

  const calculateStartTime = () => {
    let startTime = Math.floor(timeDate.getTime() / 1000);

    for (let i = 0; i < flow.byOrder.length; i++) {
      const currAction = flow.byId[flow.byOrder[i]];
      if (currAction.id === breakoutSession.id) {
        for (let j = 0; j < currAction.children.length; j++) {
          const currTrack = currAction.children[j];
          if (currTrack.id === track.id) {
            for (let y = 0; y < currTrack.children.length; y++) {
              const currTrackAction = currTrack.children[y];
              if (currTrackAction.id !== trackAction.id) {
                startTime += currTrackAction.duration;
              } else break;
            }
          }
        }
      }
    }

    const date = new Date(Math.floor(startTime * 1000));
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    if (!isSelected) {
      nameInputRef.current?.blur();
      durationInputRef.current?.blur();
    }
  }, [isSelected]);

  return (
    <div
      className={`grid grid-cols-[100px_minmax(150px,_1fr)_100px_20px] gap-4 px-2 py-1 ${
        isSelected ? 'bg-red-300' : ''
      }`}
      onClick={onSelect}
    >
      <div className='p-2 rounded-xl text-sm text-center bg-white border border-[#c4c4c4]'>
        {calculateStartTime()}
      </div>

      <NameInputField
        breakoutSession={breakoutSession}
        track={track}
        trackAction={trackAction}
        nameInputRef={nameInputRef}
      />

      <DurationInputField
        breakoutSession={breakoutSession}
        track={track}
        trackAction={trackAction}
        maxAllowedDuration={maxAllowedDuration}
        setMaxAllowedDuration={setMaxAllowedDuration}
        durationInputRef={durationInputRef}
      />

      {/* Comment out when more than one TRACK ACTION is allowed to be added */}
      {/* <div
        className='flex items-center relative'
        onMouseEnter={() => setIsActionMenuOpen(true)}
        onMouseLeave={() => setIsActionMenuOpen(false)}
      >
        {isActionMenuOpen ? (
          <TrackActionMenu
            breakoutSession={breakoutSession}
            track={track}
            trackAction={trackAction}
            setIsActionMenuOpen={setIsActionMenuOpen}
            maxAllowedDuration={maxAllowedDuration}
            setMaxAllowedDuration={setMaxAllowedDuration}
          />
        ) : (
          <BiDotsVerticalRounded className='cursor-pointer' size={25} />
        )}
      </div> */}
    </div>
  );
};

export default TrackAction;
