import React, { useState } from 'react';
import fireDb from '../../../firebase';
import { Draggable } from 'react-beautiful-dnd';

// icons
import { MdDragHandle } from 'react-icons/md';

// components
import DeleteIcon from './DeleteIcon';
import DeleteConfirmationModal from './DeleteConfirmationModal';

// context
import { useFlowContext, useActionContext } from '../../../contexts';

// FieldBase Component (for shared structure)
const FieldBase = ({ provided, field, children, setDeleteCheck }) => (
  <div
    {...provided.draggableProps}
    ref={provided.innerRef}
    className={`flex flex-col p-1 transition-colors border-2 rounded-lg bg-slate-200 border-slate-200 hover:bg-white hover:text-slate-800 hover:border-slate-200 hover:shadow-lg ${
      field.size === 1 ? 'w-1/3' : field.size === 2 ? 'w-2/3' : 'w-full'
    }`}
  >
    <div className='flex items-center justify-between group'>
      <div className='flex items-center'>
        <div>
          <div {...provided.dragHandleProps}>
            <MdDragHandle size={23} className='mr-1' />
          </div>
        </div>
        <label htmlFor={field.name} className='font-bold text-gray-800'>
          {field.name}
          {provided.placeholder}
        </label>
      </div>
      <DeleteIcon setDeleteCheck={setDeleteCheck} />
    </div>
    {children}
  </div>
);

const CustomField = ({
  field,
  deleteCustomField,
  handleChange,
  handleChangeDropdown,
  byIdDbPath,
  actionFields,
}) => {
  const { userWorkspace, flow, setFlow, flowId } = useFlowContext();
  const { activeActionId, inputCustomFields, setInputCustomFields } = useActionContext();

  const [deleteCheck, setDeleteCheck] = useState(false);
  const [inputOption, setInputOption] = useState('');

  const indexInRenderOrder = flow?.custom_fields?.findIndex(
    (fieldInState) => fieldInState.name === field.name,
  );

  const handleKeyUp = (e) => {
    if (!(activeActionId in flow.byId)) return;

    const copyFlow = { ...flow };
    copyFlow.byId[activeActionId].fields = {
      ...copyFlow.byId[activeActionId].fields,
      [e.target.name]: e.target.value,
    };
    setFlow(copyFlow);

    if (flowId && activeActionId !== '') {
      let updates = {};
      updates[byIdDbPath + '/fields/' + e.target.name] = e.target.value;
      fireDb.update(updates);
    }
  };

  const handleAddOption = (field) => {
    if (inputOption === '') return;

    const copyFlow = { ...flow };

    const indexOfUpdatedField = copyFlow.custom_fields.findIndex(
      (fieldInState) => fieldInState.name === field.name,
    );

    copyFlow.custom_fields[indexOfUpdatedField].options = [
      ...(copyFlow.custom_fields[indexOfUpdatedField]?.options || []),
      inputOption,
    ];

    if (flowId) {
      let updates = {};
      updates[userWorkspace + '/flows/' + flowId + '/custom_fields'] = copyFlow.custom_fields;

      fireDb.update(updates);
    }

    setFlow(copyFlow);
    setInputOption('');
  };

  const handleChangeCheckbox = (e) => {
    if (!(activeActionId in flow.byId)) return;

    const copyFlow = { ...flow };

    copyFlow.byId[activeActionId].fields = {
      ...copyFlow.byId[activeActionId].fields,
      [e.target.name]: e.target.checked,
    };

    setFlow(copyFlow);

    if (flowId && activeActionId !== '') {
      let updates = {};
      updates[byIdDbPath + '/fields/' + e.target.name] = e.target.checked;
      fireDb.update(updates);
    }
  };

  const renderFieldContent = () => {
    switch (field.type) {
      case 'text':
        return field.size === 3 ? (
          <textarea
            id={field.name}
            name={field.name}
            value={actionFields[field.name]}
            className='p-2 my-2 border-2 border-gray-200 outline-none resize-none hover:shadow-lg focus:border-bgColor'
            rows={3}
            onKeyUp={handleKeyUp}
            onChange={handleChange}
          />
        ) : (
          <input
            id={field.name}
            name={field.name}
            value={actionFields[field.name]}
            className='p-2 my-2 border-2 border-gray-200 outline-none hover:shadow-lg focus:border-bgColor'
            onKeyUp={handleKeyUp}
            onChange={handleChange}
          />
        );

      case 'checkbox':
        return (
          <input
            id={field.name}
            name={field.name}
            type='checkbox'
            onChange={handleChangeCheckbox}
            checked={flow.byId[activeActionId]?.fields?.[field.name] || false}
            className='p-2 my-2 border-2 border-gray-200 outline-none hover:shadow-lg focus:border-bgColor items-center'
          />
        );

      case 'select':
        return (
          <div>
            <input
              type='text'
              className='border focus:border-bgColor'
              value={inputOption}
              onChange={(e) => setInputOption(e.target.value)}
            />
            <button
              onClick={(e) => handleAddOption(field)}
              className='px-4 ml-2 text-white rounded-full bg-slate-500'
            >
              Add Option
            </button>
            <select
              className='mt-2 w-full'
              onChange={handleChangeDropdown}
              name={field.name}
              value={flow.byId[activeActionId]?.fields?.[field.name] || 'none'}
            >
              <option value='none' disabled>
                – Select –
              </option>
              {field.options?.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Draggable draggableId={field.name} index={indexInRenderOrder}>
      {(provided) => (
        <FieldBase provided={provided} field={field} setDeleteCheck={setDeleteCheck}>
          {renderFieldContent()}

          {deleteCheck && (
            <DeleteConfirmationModal
              field={field}
              deleteCustomField={deleteCustomField}
              setDeleteCheck={setDeleteCheck}
            />
          )}
        </FieldBase>
      )}
    </Draggable>
  );
};

export default CustomField;
