import React, { useState } from 'react';
import { useFlowContext } from '../../contexts';

const NameInputField = ({ breakoutSession, track, trackAction, nameInputRef }) => {
  const { flow, setFlow } = useFlowContext();
  const [name, setName] = useState(trackAction.name);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleBlur = (e) => {
    setFlow((prevFlow) => {
      const updatedFlow = { ...prevFlow };
      const updatedBreakoutSession = { ...updatedFlow.byId[breakoutSession.id] };
      const updatedTrackIndex = updatedBreakoutSession.children.findIndex(
        (child) => child.id === track.id,
      );
      const updatedTrack = { ...updatedBreakoutSession.children[updatedTrackIndex] };
      const updatedTrackActionIndex = updatedTrack.children.findIndex(
        (child) => child.id === trackAction.id,
      );
      const updatedTrackAction = {
        ...updatedTrack.children[updatedTrackActionIndex],
        name: name,
      };

      // Update the trackAction in the track
      updatedTrack.children[updatedTrackActionIndex] = updatedTrackAction;

      // Update the track in the breakoutSession
      updatedBreakoutSession.children[updatedTrackIndex] = updatedTrack;

      // Update the breakoutSession in the flow
      updatedFlow.byId[breakoutSession.id] = updatedBreakoutSession;

      return updatedFlow;
    });
  };

  return (
    <input
      className='p-2 rounded-xl text-sm bg-white border border-[#c4c4c4] outline-plum'
      type='text'
      name='duration'
      value={name}
      onChange={handleChange}
      onBlur={handleBlur}
      ref={nameInputRef}
    />
  );
};

export default NameInputField;
