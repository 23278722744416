import React from 'react';
import useStore from '../../store/UserStore';
import { useHistory } from 'react-router-dom';

const LogoutButton = () => {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const setUserDetails = useStore((state) => state.setUserDetails);
  let history = useHistory();

  const handleLogout = () => {
    fetch(process.env.REACT_APP_API_ENDPOINT + '/api/users/logout', {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    }).then(async (response) => {
      setUser(null);
      setUserDetails(null);
      window.localStorage.setItem('logout', Date.now());

      localStorage.removeItem('refreshToken');

      history.push('/');
    });
  };

  return (
    <button className='btn-dropdown' onClick={handleLogout}>
      Logout
    </button>
  );
};

export default LogoutButton;
