import React, { useContext, useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

const AppContext = React.createContext();

const ActionContextProvider = ({ children }) => {
  const [newAction, setNewAction] = useState({ 
    id: uuidv4(), 
    name: '', 
    duration: '', 
    inputCustomFields: {},
    type: 'action',
  });
  const [activeActionId, setActiveActionId] = useState(null);
  const [selectedActions, setSelectedActions] = useState([]);
  const [inputCustomFields, setInputCustomFields] = useState({});

  const actionFormInputRefs = useRef({});

  return (
    <AppContext.Provider
      value={{
        newAction,
        setNewAction,

        activeActionId,
        setActiveActionId,

        selectedActions,
        setSelectedActions,

        inputCustomFields,
        setInputCustomFields,

        actionFormInputRefs,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useActionContext = () => {
  return useContext(AppContext);
};

export { ActionContextProvider };
